import React, { Component } from "react"
import _ from 'lodash'
import moment from "moment";
import classnames from "classnames"
import { Link } from "react-router-dom";
import Icon from "./../SvgIcons"
import "./discount.css"
import convertDateTimeToDeadlineDaysHours from "./../../utils/convertDateTimeToDeadlineDaysHours";
import { scrollToElement } from './../../utils/animate';
import { round, getSizeText } from './../../utils/MeasureConvertor';
import { sendMessage } from './../../api';
import { get } from 'lodash';
class Discount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      discountStep: '1',
      discountErrorType: '',
      discountEdited: false,
      promoCode: '',
      isEmpty: false,
    };
    this.handleShowPromoField = this.handleShowPromoField.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      order
    } = this.props;

    const discountErrorType = _.get(order, 'error.discount_error_type');
    const prevDiscountErrorType = _.get(prevProps.order, 'discount_error_type');

    if ((!prevProps.orderError && this.props.orderError) || (
      discountErrorType &&
      prevDiscountErrorType !== discountErrorType)
    ) {
      const typeError = this.getTypeError();
      this.sendSystemMessage(typeError.reason);
    }
  }

  handleShowPromoField() {
    this.setState({ discountStep: '2' });
    scrollToElement('get-done-btn');
  }

  onPromoChange(e) {
    var inputValue = e.target.value.replace(/\s/g, '').toUpperCase();
    this.setState({ promoCode: inputValue, discountEdited: true });
  }

  onApply(promoCode) {
    if (promoCode.length) {
      this.setState({ isEmpty: false });

      this.props.changeDiscount({
        action: 'discount_add',
        code: promoCode
      });
    }
    else {
      this.setState({ isEmpty: true });
    }
  }

  onRemoveDiscount(promoCode) {
    const {
      order
    } = this.props;

    const {
      isDiscountInfo
    } = order.data;

    this.setState({
      discountStep: '1',
      isEmpty: false,
      promoCode: '',
    });

    this.props.clearDiscountError();

    if (!isDiscountInfo) {
      return false;
    }

    this.props.changeDiscount({
      action: 'discount_del',
      code: promoCode
    });
  }

  sendSystemMessage(reason) {
    const { user } = this.props;
    const { promoCode } = this.state;
    const { deadline, service_type, size, price, number } = this.props.order.data;
    const dd = convertDateTimeToDeadlineDaysHours(deadline);
    const message = `User failed to apply a coupon code.
      Type of task: ${service_type}.
      Length: ${getSizeText(size)}.
      Due: ${dd.days}d ${dd.hours}h / ${moment(deadline).format("ll")}.
      Coupon: ${promoCode}.
      Error reason: ${reason}
      Price: ${price}.
      Order number: ${number}.`;

    const data = get(user, 'data.credentials');

    sendMessage(data, message, false, true);
  }

  getTypeError() {
    const { orderError, order } = this.props;
    const error_type = orderError ? orderError.error_type : '';

    const {
      discountErrorValue,
      discountErrorType
    } = order.data

    const minOrderPrice = (orderError && orderError.error_value) || discountErrorValue;

    let serviceName = '';
    switch (orderError && orderError.error_value) {
      case 'prioritize_my_task':
        serviceName = '“Prioritize My Task”';
        break;
      case 'top_writer':
        serviceName = '“TOP Nerd”';
        break;
      default:
        serviceName = orderError && orderError.error_value;
        break;
    }

    let reason = '';
    let showLink = false;

    switch (error_type || discountErrorType) {
      case 'paid_orders': // number of orders
      case 'paid_orders_max': // number of orders
      case 'order_type': // plagreport for Presentation, Problems, Quiz
        reason = 'This code can\'t be applied for this order.';
        showLink = true;
        break;

      case 'min_price':
        reason = `This code can be only applied for orders \n over $${minOrderPrice}`;
        showLink = true;
        break;

      case 'expiration':
        reason = 'This code has expired.';
        showLink = true;
        break;

      case 'used':
        reason = `This code can't be applied for this order. \n You've already use it with another order.`;
        showLink = false;
        break;

      // trying add "top_writer" when "client_recomended_writer" is on
      case 'service_top_writer':
        reason = `Can't activate "Top writer". Codes or add-ons conflict`;
        showLink = false;
        break;

      // trying add draft when deadline < 6 hours, two types of error:
      case 'draft_deadline':
        reason = `Can't activate "Draft". Check deadline`;
        showLink = false;
        break;

      case 'service_draft':
        reason = `Can't activate "Draft". Check deadline`;
        showLink = false;
        break;

      case 'service_allready_sub':
        reason = `${serviceName} service is already included as a part of subscription benefits`;
        showLink = false;
        break;

      default:
        reason = `This code is not valid.`;
        showLink = true;
        break;
    }

    return { reason, showLink }
  }

  renderError() {
    const { orderError } = this.props;
    const { isEmpty } = this.state;
    const { order } = this.props;

    const {
      discountErrorType,
    } = order.data;

    const typeError = this.getTypeError();

    return {
      ...(isEmpty && {
        emptyError: (
          <p className="discount-error-msg">Please, enter a coupon code.</p>
        ),
        isEmptyError: true
      }),
      ...((orderError || discountErrorType) && {
        discountError: (
          <p className="discount-error-msg">{typeError.reason} <br />
            {typeError.showLink && <span><Link className="discount-error-link" to={`/contact-us`}>Contact us</Link> if you have any questions.</span>}
          </p>
        ),
        isDiscountError: true
      })
    }
  }

  render() {
    const { order } = this.props;

    const {
      discountValid,
      discountCode,
      discountInfo,
      discountOrderQuote,
    } = order.data;

    const promoCode = this.state.discountEdited ? this.state.promoCode : discountCode || this.state.promoCode;
    const discountStep = discountCode ? '2' : this.state.discountStep;

    const errors = this.renderError();
    const isError = errors.isEmptyError || errors.isDiscountError;
    const errorText = errors.emptyError || errors.discountError;

    return (
      <div className="discount-block">

        {!discountValid && discountStep === '1' &&
          <button className="coupon-btn" type="button" onClick={() => this.handleShowPromoField()}>
            <Icon className="svg-icon" iconName="coupon-icon" />
            <span className="coupon-btn-msg">I have a coupon</span>
          </button>
        }

        {!discountValid && discountStep === '2' &&
          <div>
            <div className="promo-enter-block">
              <div className="promo-input-wrapper">
                <input className={classnames("promo-input", { 'error': isError })} type="text" value={promoCode}
                  placeholder="Enter promo code" onChange={(e) => this.onPromoChange(e)} disabled={errors.isDiscountError} />
                <button className="close-btn" type="button" onClick={() => this.onRemoveDiscount(promoCode)}>
                  <Icon className="svg-icon" iconName="close-round-icon2" />
                </button>
              </div>
              <button className="apply-btn" disabled={errors.isDiscountError} type="button" onClick={() => {
                return errors.isDiscountError ? () => { } : this.onApply(promoCode)
              }}>Apply
              </button>
            </div>

            {errorText}

          </div>
        }

        {discountValid &&
          <div className="applied-code-block">
            <div className="applied-code-table">
              <div className="cell-label">Basic order price:
                <span className="cell-price">${round(order.data.quote_price)}</span>
              </div>

              {discountInfo.map((item, key) => {
                const discountQuote = item.quote_amount || 0;
                const discountCashback = item.cashback_amount || 0;
                const discountPlagreport = item.value === 'plagreport' || '';
                const discountVIP = item.value === 'vip_support' || '';

                const discountPrioritize = item.value === 'prioritize_my_task' || '';
                const discountTopWriter = item.value === 'top_writer' || '';
                const discountDraft = item.value === 'draft_needed' || '';

                return (
                  <div key={`cell-label-${key}`}>
                    {!!discountQuote &&
                      <div className="cell-label">
                        Coupon discount:
                        <span className="cell-price highlighted">-${round(discountQuote)}</span>
                      </div>
                    }

                    {!!discountCashback &&
                      <div className="cell-label">
                        Back to your balance:
                        <span className="cell-price highlighted strong">+${round(discountCashback)}</span>
                      </div>
                    }

                    {!!discountPlagreport &&
                      <div className="cell-label">
                        Plag Report:
                       <span className="cell-price highlighted strong">FREE</span>
                      </div>
                    }

                    {discountVIP &&
                      <div className="cell-label">VIP support:
                       <span className="cell-price highlighted strong">FREE</span>
                      </div>
                    }

                    {discountPrioritize &&
                      <div className="cell-label">Prioritize task:
                      <span className="cell-price highlighted strong">FREE</span>
                      </div>
                    }
                    {discountTopWriter &&
                      <div className="cell-label">Top writer:
                      <span className="cell-price highlighted strong">FREE</span>
                      </div>
                    }
                    {discountDraft &&
                      <div className="cell-label">Draft:
                      <span className="cell-price highlighted strong">FREE</span>
                      </div>
                    }

                  </div>
                )
              })}

              {!!discountOrderQuote &&
                <div className="cell-label">You’ll pay:
                  <span className="cell-price final">${round(order.data.price - order.data.service_price)}</span>
                </div>
              }
            </div>

            {discountInfo.map((item, key) => {
              const itemDiscountCode = item.code || '';

              return (
                <div className="applied-code-field" key={`"applied-code-field-${key}`}>
                  <div className="applied-code-line">Applied code: <span
                    className="applied-code">{itemDiscountCode}</span></div>
                  <div className="divider" />
                  <button className="remove-btn" onClick={() => {
                    this.onRemoveDiscount(itemDiscountCode)
                  }}>
                    <Icon className="svg-icon" iconName="trash-icon" />
                    <span className="remove-btn-msg">Remove</span>
                  </button>
                </div>
              )
            })}

          </div>
        }
      </div>
    )
  }
}

export default Discount
