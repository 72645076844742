import convertDateTimeToDeadlineHours from './convertDateTimeToDeadlineHours'

export default function convertDateTimeToDeadlineDaysHours(datetime) {
	const time = datetime;
	const deadlineHours = convertDateTimeToDeadlineHours(time);
    const days = Math.floor(deadlineHours / 24);
    const hours =  Math.floor(deadlineHours % 24);
    const daysStr = (days > 1) ? `${days} days` : `${days} day`;
    const hoursStr = (hours > 1) ? `${hours} hours` : `${hours} hour`;
    return {days, hours, daysStr, hoursStr};
}
