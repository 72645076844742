import { createStore, combineReducers } from 'redux';

import order from './reducers/order';
import orders, { orderFiles } from './reducers/orders';
import pricing from './reducers/pricing';
import settings from './reducers/settings';
import user from './reducers/user';
import subscriptions from './reducers/subscriptions';

import middlewares from './middlewares';

const reducer = combineReducers({
  order,
  orders,
  pricing,
  settings,
  user,
  subscriptions,
  orderFiles,
});

const loadStore = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveStore = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // pass
  }
};

const store = createStore(reducer, loadStore(), middlewares);

store.subscribe(() => {
  const { factor, factorValue, session } = store.getState().user;

  saveStore({
    user: {
      factor,
      factorValue,
      session,
    },
  });
});

export default store;
