import React, { Component } from 'react';
import { connect } from 'react-redux'
import Icon from './../../components/SvgIcons'
import PhoneInput from './../../components/PhoneInput'
import MessageWasSent from './../MessageWasSent';
import { userFetchIfNeeded, userCreate, userRestoreSession } from './../../reducers/user';
import Auth from './../../api/auth';
import regexps from "./../../utils/regexps";
import { shortPhoneNumber } from './../../config'

class ContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      country: '',
      phone: '',
      result: null,
    };
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;

    if (user.session) {
      this.props.userRestoreSession(user.session);
      this.props.userFetchIfNeeded()
    }
  }

  onChangeCountry(country) {
    this.setState({ country });
  }

  onChangePhone(phone, country) {
    this.setState({ phone, country });
    //alert (this.props.user.data.facebook);
    //alert (Order.user.facebook);
    //console.log(this.props.user);
  }

  onSubmit(e) {
    e.preventDefault();
    const phone = this.state.phone.replace(/\s/g, '');
    const country = this.state.country;
    const phone_ref = shortPhoneNumber[country];
    const email = '';
    const message = Auth.isAuthenticated
      ? 'Hey there! Thanks for checking out your Nerdy Account :) Is there anything I can help you with today?'
      : 'Hi there! I am ready to help you with any kind of tasks. From writing a book review to explaining and completing complex projects. It\'s free to text me, you only pay when you order something. Is there anything I can help you with today?';

    const query = JSON.parse(localStorage.getItem('query')) || {
      path: '',
      search: ''
    };

    const data = {
      country,
      phone,
      phone_ref,
      email,
      message,
      landing: query.path,
      landing_query: query.search,
    };

    this.props.userCreate(data);
    this.setState({ result: true });
  }

  render() {
    if (this.state.result === true) {
      return <MessageWasSent />;
    }

    //console.log('this.props.user.data: ',this.props.user);
    const facebook = this.props.user.data && this.props.user.data.facebook ? true : false;

    const error = regexps.phone(this.state.phone);
    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="frame-no-border">
            <h2 className="title">Contact Us</h2>

            <form className="form-login"> {/*  add .error */}
              {!facebook &&
                <div>
                  <div className="subtitle">
                    Tell us your phone number and we will<br /> text you within seconds
                </div>
                  <ul className="link-block">
                    <li className="input-item">
                      <PhoneInput onChange={this.onChangePhone} onChangeCountry={this.onChangeCountry} />
                    </li>
                    <li>
                      <button type="submit" className="list-link" disabled={!error} onClick={this.onSubmit}>
                        <span>Text Me</span>
                      </button>
                    </li>
                    <li className="or-separator">
                      or
                  </li>
                  </ul>
                </div>
              }
              <ul>
                <li>
                  <div className="fb-messenger-line">Message us via Facebook Messenger</div>
                  <a className="list-link fb-messenger" href="http://m.me/gonerdify">
                    <Icon className="svg-icon" iconName="fb-messenger-icon" />
                    <span>Message Us</span>
                  </a>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
  }),
  (dispatch, ownProps) => ({
    userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
    userRestoreSession: (session) => dispatch(userRestoreSession(session)),
    userCreate: (data) => dispatch(userCreate(data, {
      actionType: 'sendMessage',
      ...data,
    })),
  })
)(ContactUs)
