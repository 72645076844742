// const TOKEN = '041hwtagm63tp3vcib1qpednymc1gxoj'
// testrders1@qavls.com
// ochesnokov2353@qavls.com
// +5038772400
// rRq9E

const Auth = {
  isAuthenticated: false,
  token: null,
  // isAuthenticated: true,
  // token: 'p07n2pz9rk2el00ia55om0celusq6qh4',
  authenticate(token) {
    this.isAuthenticated = true
    this.token = token
  },
  signout() {
    this.isAuthenticated = false
    this.token = null
  },
  getToken() {
    return this.token
  }
};

export default Auth;
