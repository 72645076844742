import React from 'react';
import {Link} from 'react-router-dom'
import Icon from './../../components/SvgIcons'
import './MessageWasSent.css'

const MessageWasSent = () => (
    <div className="msg-page message-was-sent">
        <div className="wrapper">
            <div className="img-wrap">
                <Icon className="svg-icon" iconName="message-was-sent" />
            </div>
            <h2 className="title">Well done!</h2>
            <div className="subtitle">Your message was sent to<br/>
                our support team. We’ll get in touch with you very soon!</div>
                <Link className="link type1" to="/">Got it!</Link>
        </div>
    </div>
)

export default MessageWasSent
