import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { userRestoreSession } from "./../../reducers/user";
import Icon from './../../components/SvgIcons';

class Login extends Component {

  // constructor(props) {
  //     super(props);
  // }

  componentDidMount() {
    if (this.props.session) {
      this.props.userRestoreSession(this.props.session);
    }
  }

  render() {
    if (this.props.user.isAuthenticated) {
      const { from } = this.props?.location?.state || '';
      const next = from || "/orders";
      return <Redirect to={next} push={false} />;
    }
    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="frame-no-border">
            <h2 className="title">Login with your</h2>
            <ul className="link-block step1-login">
              <li>
                <Link className="list-link phone" to="/login/phone">
                  <Icon className="svg-icon" iconName="msg-icon" />
                  <span>Phone number</span>
                </Link>
              </li>
              {/* <li>
                            <Link className="list-link fb" to="#">
                                <Icon className="svg-icon" iconName="fb-icon" />
                                <span>Facebook</span>
                            </Link>
                        </li> */}
              <li>
                <Link className="list-link email" to="/login/email">
                  <Icon className="svg-icon" iconName="email-icon" />
                  <span>Email</span>
                </Link>
              </li>
            </ul>
            <div className="login-contact">
              Have never used Nerdify or have<br /> trouble signing in? <Link className="login_link" to="/contact-us">Contact us</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    session: state.user.session,
    next: queryString.parse(ownProps.location.search).next,
  }),
  (dispatch, ownProps) => ({
    userRestoreSession: (session) => dispatch(userRestoreSession(session)),
  })
)(Login);
