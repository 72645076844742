import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import _ from 'lodash';

import {
  cfpOrderSelector,
  cfpFilesSelector,
  cfpOrdersSelector,
  useCfpEqualSelector,
} from './selectors';

import { useCfpLocation } from './hooks';

export const CfpAuth = ({
  loginUser,
  history,
  match: {
    params: { referralCode, orderId, param },
  },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loginUser({
        factor: 'key',
        value: referralCode,
      })
    )
      .then((resp) => {
        if (resp.session) {
          history.push(`/orders/${orderId}/rating/${param || ''}`);
        }

        return resp;
      })
      .catch((error) => error);
  }, [dispatch, history, loginUser, orderId, param, referralCode]);

  return null;
};

const useCfpAuthRoutes = (authRoutesProps) => {
  const routes = {
    rating: {
      route: (props) => {
        return (
          <Route
            {...props}
            render={(renderProps) => (
              <CfpAuth {...renderProps} {...authRoutesProps} />
            )}
          />
        );
      },
      path: `/r/:referralCode/:orderId/rating/:param([1-5]|f)?`,
    },
  };

  routes.rating.route.displayName = 'CfpAuthRouteRating';

  return routes;
};

const useCfpRoutes = () => {
  const { url } = useCfpLocation();

  const routes = {
    rating: {
      path: `/${url}/:orderId/rating/:param([1-5]|f|change)?`,
      route: (props) => {
        return <Route {...props} />;
      },
    },
    ratingNegative: {
      path: `/${url}/:orderId/rating-negative/:mark`,
      route: (props) => {
        return <Route {...props} />;
      },
    },
    tips: {
      path: `/${url}/:orderId/tips`,
      route: (props) => {
        return <Route {...props} />;
      },
    },
    tipsSuccess: {
      path: `/${url}/:orderId/tips-success/:amount?`,
      route: (props) => {
        return <Route {...props} />;
      },
    },
  };

  routes.rating.route.displayName = 'CfpRouteRating';
  routes.ratingNegative.route.displayName = 'CfpRouteRatingNegative';
  routes.tips.route.displayName = 'CfpRouteTips';
  routes.tipsSuccess.route.displayName = 'CfpRouteTipsNegative';

  return routes;
};

const useCfpRatingNegative = () => {
  const {
    isOrders,
    url,
    params: { orderId, mark },
    history,
  } = useCfpLocation();

  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    history.push(isOrders ? `/${url}` : `/${url}/${orderId}`);
  };

  return {
    onClose,
    isOpen,
    orderId,
    mark,
  };
};

//-------------------------------------------------
const useCfpRating = ({
  getOrder,
  rateOrder,
  getOrderFiles,
  getOrders,
  ordersInvalidate,
}) => {
  const dispatch = useDispatch();

  const {
    url,
    isOrders,
    params: { orderId, param },
    history,
  } = useCfpLocation();

  const orderState = useCfpEqualSelector((state) =>
    cfpOrderSelector(state, orderId)
  );

  const filesState = useCfpEqualSelector((state) => cfpFilesSelector(state));

  const isParamMark = /[1-5]/.test(param);
  const isParamFile = /^f$/.test(param);
  const isParamChange = /^change$/.test(param);

  const [mark, setMark] = useState(isParamMark ? param : 0);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [tags, setTags] = useState([]);

  const { data } = orderState;
  const { title, subject, isCanShowRating, isCanDownloadFile, id } = data;

  const { filesByWriterLast } = filesState;

  const isFetching = isParamFile
    ? orderState.isFetching || filesState.isFetching || orderId !== id
    : orderState.isFetching || orderId !== id;

  useEffect(() => {
    if (isOrders) {
      dispatch(getOrder(orderId));

      if (isParamFile) {
        dispatch(getOrderFiles(orderId));
      }
    }
  }, [dispatch, orderId, getOrderFiles, getOrder, isOrders, isParamFile]);

  useEffect(() => {
    if (isFetching) {
      return;
    } else {
      if (!isCanShowRating && !isParamChange) {
        history.push(isOrders ? `/${url}` : `/${url}/${orderId}`);
      }

      if (
        isCanDownloadFile &&
        data.url_actual &&
        !isDownloaded
      ) {
        setIsDownloaded(true);
        window.location.assign(data.url_actual);
      }
      else if (
        isCanDownloadFile &&
        isParamFile &&
        filesByWriterLast.downloadLink &&
        !isDownloaded
      ) {
        setIsDownloaded(true);
        window.location.assign(filesByWriterLast.downloadLink);
      }
    }
  }, [
    filesByWriterLast.downloadLink,
    history,
    isCanDownloadFile,
    isCanShowRating,
    isDownloaded,
    isFetching,
    isOrders,
    isParamChange,
    isParamFile,
    orderId,
    url,
  ]);

  const rateOrderHelper = useCallback(() => {
    const data = {
      action: 'client_feedback',
      mark: mark,
      ...(feedback && {
        feedback,
      }),
      ...(tags.length > 0 && {
        tags: tags,
      }),
    };

    dispatch(rateOrder(orderId, data))
      .then((resp) => {
        if (isOrders) {
          dispatch(ordersInvalidate());
          dispatch(getOrders());
        }

        if (resp.mark > 3) {
          history.push(`/${url}/${orderId}/tips`);
        } else {
          history.push(`/${url}/${orderId}/rating-negative/${mark}`);
        }

        return resp;
      })
      .catch((error) => error);
  }, [
    dispatch,
    feedback,
    getOrders,
    history,
    isOrders,
    mark,
    orderId,
    ordersInvalidate,
    rateOrder,
    tags,
    url,
  ]);

  useEffect(() => {
    if (!isFetching && isCanShowRating && isParamMark) {
      rateOrderHelper();
    }
  }, [isCanShowRating, isFetching, isParamMark, rateOrderHelper]);

  const onClickClose = () => {
    history.push(isOrders ? `/${url}` : `/${url}/${orderId}`);
  };

  const onClickSubmit = () => {
    rateOrderHelper();
  };

  const onClickStar = useCallback((value) => {
    setMark(value);
  }, []);

  const onChangeFeeback = (e) => {
    setFeedback(e.target.value);
  };

  const onClickTag = useCallback(
    (value) => {
      const updatedTags = tags.includes(value)
        ? tags.filter((item) => item !== value)
        : [...tags, value];

      return setTags(updatedTags);
    },
    [tags]
  );

  return {
    onClickClose,
    onClickStar,
    onChangeFeeback,
    onClickSubmit,
    title,
    subject,
    mark,
    isFetching,
    isMark: mark > 0,
    isMarkPositive: mark !== 0 && mark > 3,
    isMarkNegative: mark !== 0 && mark <= 3,
    isParam: isParamMark,
    onClickTag,
    tags,
  };
};
//-------------------------------------------------

export const useCfpLastUnratedOrder = () => {
  const ordersState = useCfpEqualSelector((state) => cfpOrdersSelector(state));
  const { data, isFetching, isDidInvalidate } = ordersState;

  const notRatedOrders = _.filter(data, (item) => item.isWasNotRated1d);

  const sortedOrders = _.orderBy(
    notRatedOrders,
    ['clientAcceptedAt'],
    ['desc']
  );

  const isHaveLastUnratedOrder =
    sortedOrders.length > 0
      ? !sortedOrders[0].isReviewShownOnClient &&
        sortedOrders[0].isCanShowRating
      : false;

  return {
    isHaveLastUnratedOrder,
    lastUnratedOrder: sortedOrders[0] || {},
    isFetching,
    isDidInvalidate,
  };
};

const useCfpShowRating = ({ editOrder }) => {
  const {
    isHaveLastUnratedOrder,
    lastUnratedOrder,
    isFetching,
    isDidInvalidate,
  } = useCfpLastUnratedOrder();

  const {
    params: { param },
    history,
  } = useCfpLocation();

  const isParamMark = /[1-5]/.test(param);
  const isFileMark = /^f$/.test(param);
  const dispatch = useDispatch();

  useEffect(() => {
    const showRating = () => {
      if (!isHaveLastUnratedOrder || isParamMark || isFileMark) {
        return false;
      }

      const { additional, id } = lastUnratedOrder;

      dispatch(
        editOrder(id, {
          additional: [].concat(additional, ['review_shown_on_client']),
        })
      )
        .then((resp) => {
          history.push(`/orders/${resp._id}/rating`);
          return resp;
        })
        .catch((error) => error);
    };

    if (!isFetching && !isDidInvalidate) {
      showRating();
    }
  }, [
    isDidInvalidate,
    dispatch,
    editOrder,
    history,
    isFetching,
    isFileMark,
    isParamMark,
    lastUnratedOrder,
    isHaveLastUnratedOrder,
  ]);
};

export {
  useCfpAuthRoutes,
  useCfpRoutes,
  useCfpRatingNegative,
  useCfpRating,
  useCfpShowRating,
};
