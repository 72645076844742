import React from 'react'
import Icon from './../SvgIcons'
import './upload-button.css'

const UploadButton = props => {
    const onChange = (e) => {
        props.onChange(Array.from(e.target.files), props.filetype);
        e.target.value = "";
    }

    return (
        <label className="upload-button">
            <Icon className="svg-icon" iconName="camera" />
            <Icon className="svg-icon" iconName="clip-icon" />
            {props.children}
            <input type="file" multiple onChange={onChange} />
        </label>
    )
}

export default UploadButton
