import React, { Component } from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { userRequestToken, updateStateUser } from "./../../reducers/user";
import EmailNotSent from './../EmailNotSent';
import EmailSent from './../EmailSent';
import regexps from "./../../utils/regexps";

class LoginWithEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'email': '',
            'step': null,
            'isSent': false,
        };
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onRequestToken = this.onRequestToken.bind(this);
        this.nextStep = this.nextStep.bind(this);
    }

    nextStep(step) {
        this.setState({ step });
    }

    onChangeEmail(value) {
        this.setState({ email: value });
    }

    onRequestToken(e) {
        e.preventDefault();
        this.setState({ isSent: true });
        this.props.userRequestToken({
            factor: 'email',
            value: this.state.email,
        })
            .then(ok => {
                this.setState({ result: ok });
                this.props.updateStateUser({
                    factor: 'email',
                    factorValue: this.state.email,
                });
            });
    }

    render() {
        const isValid = regexps.email(this.state.email);
        if (this.state.result === false) {
            return <EmailNotSent />
        }
        if (this.state.result === true) {
            return <EmailSent />
        }
        return (
            <div className="login-page">
                <div className="wrapper">
                    <div className="frame-no-border">
                        <h2 className="title">Email login</h2>
                        <form className="form-login"> {/*  add .error */}
                            <ul className="link-block">
                                {/* <li>
                                    <Link className="list-link google" to="#">
                                        <Icon className="svg-icon" iconName="google-icon" />
                                        <span>Login with Google</span>
                                    </Link>
                                </li>
                                <li className="or-separator">
                                    or
                                </li> */}
                                <li className={classnames("input-item", { error: !isValid })}>
                                    <input
                                        type="email"
                                        placeholder="james@gmail.com"
                                        value={this.state.email}
                                        onChange={(e) => this.onChangeEmail(e.target.value)}
                                    />
                                </li>
                                <li>
                                    <button
                                        className="list-link"
                                        onClick={this.onRequestToken}
                                        disabled={!(isValid && !this.state.isSent)}
                                    >
                                        <span>Email me the link to login</span>
                                    </button>
                                </li>
                            </ul>
                            <div className="login-contact">Have trouble signing in? <Link className="login_link" to="/contact-us">Contact us</Link></div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
    }),
    (dispatch, ownProps) => ({
        userRequestToken: (payload) => dispatch(userRequestToken(payload)),
        updateStateUser: (payload) => dispatch(updateStateUser(payload)),
    })
)(LoginWithEmail);
