import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Icon from './../../components/SvgIcons';
import classnames from "classnames";


class CodeInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmitCode = this.onSubmitCode.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
  }

  onSubmitCode(e) {
    e.preventDefault()
    this.props.onSubmitCode(this.state.code)
  }

  onResendCode(e) {
    e.preventDefault()
    this.setState({ code: '' });
    this.props.onResendCode();
  }

  onChange(value) {
    this.setState({ code: value })
  }

  render() {
    const { codeError } = this.props;

    return (
      <div className="login-page step1">
        <div className="wrapper">
          <div className="frame-no-border">
            <h2 className="title">Phone login</h2>

            <form className={classnames("form-login", { error: codeError })}> {/*  add .error */}
              {!codeError
                ? (
                  <div className="subtitle">
                    Please enter the verification code we have
                                        just texted to <span className="phone-num">{this.props.phone}</span>
                  </div>
                )
                : (
                  <div className="subtitle error-msg">
                    Looks like you have mistyped the verification code. Please check and re-enter it.
                                    </div>
                )
              }
              <ul className="link-block">
                <li className={classnames("input-item", "digit-code-block", "clearfix", { sent: !codeError })}>
                  <input
                    value={this.state.code}
                    className="digit-input"
                    type="text"
                    placeholder="Enter 5-digit code"
                    pattern="[0-9]{5}"
                    minLength={5}
                    maxLength={5}
                    onChange={(e) => this.onChange(e.target.value)}
                  />
                  <button className="resend-btn" type="button" onClick={this.onResendCode}>
                    <Icon className="svg-icon" iconName="resend-icon" />
                    <span className="resend-btn-msg">Resend</span>
                  </button>
                  <div className="sent-msg-info">
                    <Icon className="svg-icon" iconName="check-icon2" />
                    <span className="sent-msg">Sent</span>
                  </div>
                </li>
                <li>
                  <button
                    type="submit"
                    className="list-link"
                    onClick={this.onSubmitCode}
                    disabled={this.state.code.length !== 5}
                  > {/* add :disabled */}
                    <span>Confirm and login</span>
                  </button>
                </li>
              </ul>
              {/* <div className="remember-block">
                                <input className="checkbox-item" type="checkbox" id="remember" />
                                <label htmlFor="remember">
                                    <span className="checkbox-icon"><Icon className="svg-icon" iconName="check-icon" /></span>
                                    <span className="remember-msg">Remember me on this device</span>
                                </label>
                            </div> */}
              <div className="login-contact">Have trouble signing in? <Link className="login_link" to="/contact-us">Contact us</Link></div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default CodeInput
