import { useState, useEffect } from 'react';

const useMobile = (width = 900) => {
  const userAgent =
    typeof window !== 'undefined' && navigator.userAgent.toLowerCase();

  const isAndroid = /android/.test(userAgent);
  const isIos = /ipad|iphone|ipod/.test(userAgent);

  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth <= width
  );

  useEffect(() => {
    const detectMobile = () => {
      setIsMobile(window.innerWidth <= width);
    };

    window.addEventListener('resize', detectMobile);

    return () => {
      window.removeEventListener('resize', detectMobile);
    };
  }, [width]);

  return {
    isMobile,
    isIos,
    isAndroid,
  };
};

export { useMobile };
