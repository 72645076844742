import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
//import * as moment from "moment";

import Button from "./../../components/Button";
import UploadButton from "./../../components/UploadButton";
import Icon from "./../../components/SvgIcons";

import { fetchPricing } from './../../reducers/pricing'
import {updateUser, saveUser, fetchUser} from "./../../reducers/user";
import { updateCurrentOrder } from "./../../reducers/order";
import { saveOrder, fetchOrder } from "./../../reducers/orders";
import Uploader from "./../../utils/Uploader";
import regexps from "./../../utils/regexps";
import prepareOrder from './../../utils/prepareOrder'
import debounce from 'debounce';
import { get } from 'lodash';
import getMessageDiscountInfo from "../../utils/getMessageDiscountInfo";
import convertDateTimeToDeadlineDaysHours from "../../utils/convertDateTimeToDeadlineDaysHours";
import {getSizeText} from "../../utils/MeasureConvertor";
import * as moment from "moment";
import {mapServices} from "../../config";
import {createReminder} from "../../api";

class Details extends Component {

  constructor(props) {
    super(props)

    this.state = {
      files: [],
      isFormSubmitted: false,
    }

    this.uploader = new Uploader({
      onAddedFiles: files => this.setState({ files }),
      onProgressFiles: files => this.setState({ files }),
      onLoadedFiles: files => this.onUpdateFileList(files),
      onRemovedFiles: files => this.onUpdateFileList(files)
    });

    this.onChangeUser = this.onChangeUser.bind(this);
    this.saveUser = (payload) => this.props.saveUser(payload);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.nextStep = debounce(this.nextStep.bind(this), 1200);
    this.updateState = this.updateState.bind(this);
    this.onPayNow = this.onPayNow.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchPricing();
    const {
      order,
      orderId
    } = this.props;

    const {
      data
    } = order;

    if (orderId) {
      this.props.fetchOrder()
        .then(resp => {
          this.setState({ files: data.attachments });
          this.uploader.setFiles(data.attachments);
          return resp;
        }).catch(error => error);
    } else {
      this.setState({ files: data.attachments });
      this.uploader.setFiles(data.attachments);
    }
  }

  updateState(state) {
    this.setState(state)
  }

  onUpdateFileList(files) {
    this.setState({ files });
    this.props.updateCurrentOrder({ attachments: files });
  }

  onSaveUser(payload) {
    const isValid = regexps.email(payload.email);
    this.props.updateUser(payload);
    if (isValid && payload.email) {
      this.saveUser(payload);
    }
  }

  onChangeUser(payload) {
    this.props.updateUser(payload);
  }

  onUploadFiles(files, filetype) {
    this.uploader.addFiles(files, filetype);
  }

  onRemoveFile(id) {
    this.uploader.removeFile(id);
  }

  nextStep(isPayment) {
    this.setState({ isFormSubmitted: true });

    const { order, user, error } = this.props;
    const {
      data
    } = order;

    const isValid = regexps.email(get(user, 'data.email', ''));

    const formValid = isValid &&
      (data.attachments.length || data.details.trim()) && !error;

    data.title = data.jobType;

    if (!formValid) {
      return false;
    }

    const payload = prepareOrder(data);

    this.props.saveOrder(payload, { payment_link_sent: true })
      .then((resp) => {
        if (isPayment) {
          this.onPayNow();
        } else {
        const {id, jobType} = data;
        const {history} = this.props;
        const link = !id ? `/neworder/${jobType}/extra-services` : `/order/${id}/extra-services`;
        window.scrollTo(0, 0);
        history.push(link);
        }
        return resp;
      }).catch(error => error)

  }

  onPayNow() {
    const { user, order } = this.props;

    const payload = prepareOrder(order.data);
    let discountMessage = '';

    this.props.saveOrder(payload, { payment_link_sent: true })
      .then((resp) => {
        if (resp && resp.discount_info) {
          discountMessage = getMessageDiscountInfo(resp);
        }

        const { price, number, payment_link, size, service_type, deadline } = resp;
        const dd = convertDateTimeToDeadlineDaysHours(deadline);
        const new_order_event = false;


        let message = `User has just added additional task details and clicked on 'pay now' button.
                    Type of task: ${service_type}.
                    Length: ${getSizeText(size)}.
                    Due: ${dd.days}d ${dd.hours}h / ${moment(deadline).format("ll")}.
                    Price: ${price}.`;

        if (discountMessage) {
          message = message + `
                    Coupon: ${resp.discount_info.code}.
                    Discount: ${discountMessage}.`;
        }

        message = message + `
                    Order number: ${number}.
                    Payment link: ${payment_link}.`;

        const servicesList = [];
        {
          Object.keys(mapServices).map(item => {
            order.data.services[item].added && servicesList.push(mapServices[item].title);
          })
        }


        if (servicesList.length) {
          message = message + `
                    Add-ons: ${servicesList.join(', ')}.`;
        }

        return {
          ...resp,
          new_order_event,
          message
        }

      }).then(resp => {
      const {
        message, number, new_order_event
      } = resp;

      createReminder(user, message, number, new_order_event)

      return {
        ...resp
      }

    }).then(resp => {
      const {
        _id,
        payment_link
      } = resp;


      window.location = `${payment_link}?success_url=${encodeURIComponent(`${window.location.origin}/order/${_id}/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`;

      return resp
    }).catch(error => error);
  }

  render() {
    const { isFormSubmitted } = this.state;

    const { user } = this.props;
    const { details, attachments } = this.props.order.data;
    const email = get(user, 'data.email', '');
    const isEmailError = isFormSubmitted && !regexps.email(email);
    const isDetailsError = isFormSubmitted && !(details || attachments.length);
    const { error } = this.props;
    const isError = isEmailError || isDetailsError || error;

    const hasPremiumAddonsSubscriptionLabel = user?.data?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';
    const isSubscriptionPaymentFailed = ((this.props?.user?.data?.tags.includes("subscription_inactive") && !this.props?.user?.data?.tags.includes("subscription_refunded") && !this.props?.user?.data?.tags.includes("subscription_canceled")) || this.props?.user?.data?.tags.includes("subscription_pastdue"));

    return (
      <div className="wrapper details with-fixed-header-mob">
        <div className="frame frame-order">
          <h3 className="h3 green">What do you want our Nerds to get done?</h3>
          <h4 className="h4">Once your Personal Nerd is done with your task it will be sent to your email.</h4>
          <div>
            <input
              className={classnames("input details__input-email", { error: isEmailError || error })}
              placeholder="What's your email?"
              value={email}
              onChange={(e) => this.onChangeUser({ email: e.target.value.trim() })}
              onBlur={(e) => this.onSaveUser({ email: e.target.value.trim() })}
            />
          </div>

          <div>
            <textarea
              className={classnames("textarea", { error: isDetailsError })}
              placeholder="Just tell us a bit more about your task or upload a picture of your task details"
              value={details}
              onChange={(e) => this.props.updateCurrentOrder({ details: e.target.value })}
            />
            <UploadButton onChange={this.onUploadFiles.bind(this)} filetype="order_form">
              <span className="mobile">Add a picture</span>
              <span className="desktop">Attach files</span>
            </UploadButton>
          </div>

          <ul className="upload-list">
            {this.state.files.map((file, index) => (
              <li className="upload-list-item" key={index}>
                {" "}
                {/*file.id*/}{" "}
                {/*file.filetype*/}{" "}
                <div className="upload-list-item-name">{file.filename}</div>
                {/*file.status*/}{" "}
                {file.status === "loaded" ?
                  (
                    <div className="upload-list-item-delete" onClick={() => this.onRemoveFile(file.id)}>
                      <Icon className="svg-icon" iconName="delete" />
                    </div>
                  ) : (
                    <div className={`upload-list-item-progress p${file.progress}`}>
                      <span>{`${file.progress}%`}</span>
                      <div className="slice">
                        <div className="bar" />
                        <div className="fill" />
                      </div>
                    </div>
                  )
                }
              </li>
            ))}
          </ul>

          {hasPremiumAddonsSubscriptionLabel && isSubscriptionPaymentFailed ?
            <Button
              onClick={isError ? () => { } : () => this.nextStep(hasPremiumAddonsSubscriptionLabel && isSubscriptionPaymentFailed)}
              disabled={isError}
            >Pay Now!</Button>
            :
          <Button
            onClick={isError ? () => { } : () => this.nextStep()}
            disabled={isError}
          >Continue</Button>
          }
        </div>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => {
    const { user, order, orders } = state;
    const { error } = state.user;

    return {
      order,
      user,
      error,
    }
  },
  (dispatch, ownProps) => ({
    updateCurrentOrder: (payload) => dispatch(updateCurrentOrder(payload)),
    updateUser: (payload) => dispatch(updateUser(payload)),
    saveOrder: (payload, opts) => dispatch(saveOrder(payload, opts)),
    fetchOrder: () => dispatch(fetchOrder(ownProps.match.params.orderId)),
    saveUser: (payload) => dispatch(saveUser(payload)),
    fetchPricing: () => dispatch(fetchPricing()),
    fetchUser: () => dispatch(fetchUser()),
  })
)(Details)
