import moment from 'moment';
import _ from 'lodash';

export function round(value, decimals = 2) {
  let numberValue = Number(value);
  let fixedValue = Number(numberValue.toFixed(3));
  return Number(Math.round(fixedValue + 'e' + decimals) + 'e-' + decimals);
}

const cfpMapOrder = (order) => {
  const id = order._id || '';
  const title = order.title || '';
  const subject = order.subject || '';
  const price = order.price || '';
  const mark = order.mark || 0;
  const tags = order.tags || [];
  const additional = order.additional || [];
  const systemClientStatus = order.system_client_status;
  const clientStatus = order.client_status;
  const clientAcceptedAt = order.client_accept_at || '';

  // payment
  const clientPaid = order.client_paid ? round(order.client_paid) : 0;
  const isFullPaid = (clientPaid > 0 && price <= clientPaid) || false;
  const isPartiallyPaid = (clientPaid > 0 && price > clientPaid) || false;

  //timing
  const revisionAcceptAt = order.revision_accept_at
    ? moment(order.revision_accept_at).format()
    : '';

  //statuses
  const isSentToClient = clientStatus === 'Sent' || false;

  const isRefundDeclined = systemClientStatus === 'Refund Declined' || false;

  const isCompleted =
    systemClientStatus === 'Completed' ||
    (isSentToClient && !revisionAcceptAt) ||
    (isSentToClient && isRefundDeclined) ||
    false;

  const isRevisionSentByClient = clientStatus === 'Revision sent' || false;

  const isRevisionRequested =
    ((systemClientStatus === 'Revision Requested' || isRefundDeclined) &&
      clientStatus === 'Revision requested') ||
    false;

  const isRevisionInProgress =
    systemClientStatus === 'Revision Accepted' ||
    clientStatus === 'On revision' ||
    false;

  const isRevisionCompleted =
    systemClientStatus === 'Revision Completed' ||
    (isSentToClient && !!revisionAcceptAt) ||
    (isRevisionSentByClient && isRefundDeclined) ||
    false;

  const isAcceptedByClient = isCompleted && tags.indexOf('client_accept') > -1;
  const isRefundRequested = systemClientStatus === 'Refund Requested' || false;
  const isRefunded = systemClientStatus === 'Refund Accepted' || false;

  //flags

  const isTipsPaid = order.tips_paid || false;

  const isDeleted =
    order.profile_arch === 1 ||
    systemClientStatus === 'Deleted' ||
    tags.indexOf('delete_order') > -1 ||
    false;

  const isRated = mark > 0;

  const isReviewShownOnClient =
    additional.indexOf('review_shown_on_client') > -1;

  const isWasNotRated1d = tags.indexOf('no_feedback_1d') > -1;

  const isCanShowRating =
    !isRated &&
    ((isCompleted && (isFullPaid || isPartiallyPaid)) ||
      isRefundRequested ||
      isRefunded ||
      isRevisionCompleted);

  const isCanShowStatusBar =
    isRated ||
    isRefunded ||
    (!isRated && !isRevisionCompleted && (!isCompleted || isPartiallyPaid));

  const isCanShowRatingBtn =
    !isRated &&
    isFullPaid &&
    !isRefunded &&
    (isSentToClient ||
      isAcceptedByClient ||
      isRefundRequested ||
      isRevisionCompleted);

  const isCanDownloadFile =
    (isCompleted && isFullPaid) ||
    isRefundRequested ||
    isRefunded ||
    isRevisionRequested ||
    isRevisionInProgress ||
    isRevisionCompleted;

  const isCanShowChangeRateBtn =
    isRated && !isRevisionInProgress && !isRevisionRequested && !isRefunded;

  const isCanShowTipsBtn =
    !isTipsPaid &&
    mark >= 4 &&
    !isRefunded &&
    (isRevisionCompleted || isCompleted);

  return {
    ...order,
    id,
    title,
    subject,
    isCanShowRating,
    isWasNotRated1d,
    isReviewShownOnClient,
    isCanShowStatusBar,
    isDeleted,
    isCanShowRatingBtn,
    isCanDownloadFile,
    isCanShowChangeRateBtn,
    isTipsPaid,
    isCanShowTipsBtn,
    isRated,
    clientAcceptedAt,
  };
};

const cfpMapFile = (file) => {
  const id = file._id;
  const size = `${Number((file.size / 1024).toFixed(2))} Kb`;
  const fileName = file.name ? file.name.toLowerCase() : '';

  return {
    ...file,
    id,
    downloadLink: file.location || '',
    size,
    ext: /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : 'unknown',
  };
};

const cfpMapUser = (user) => {
  const id = user._id || '';
  const balance = user.available_balance ? user.available_balance : 0;
  const paidOrdersCount = user.paid_orders_count || 0;
  const referralDetails = user.referral_details || {};
  const userPhone = user.phone || '';
  const userEmail = user.email || '';
  const userCountry = user.country || '';

  return {
    ...user,
    id,
    balance,
    isUnSubscribedTwillio: user.optout_sms === 'twilio',
    isUnSubscribedCabinet: user.optout_sms === 'cabinet',
    isSubscribed: !user.optout_sms,
    userEmail,
    userPhone,
    userCountry,
    isUser: id ? true : false,
    paidOrdersCount,
    referralCode: user.referral_code || '',
    isPaidOrders: paidOrdersCount > 0,
    createdAt: user.created_at || '',
    availableBalance: user.available_balance || 0,
    friendsInvited: referralDetails.count || 0,
    friendsUsing: referralDetails.count_with_orders,
    totalBonuses: referralDetails.earn || 0,
    prevTotalBonuses: _.get(user, 'userdef.prevTotalBonuses') || 0,
    isReferred: /Referred/.test(user.referral_info) || false,
    isPhone: userPhone.length > 0,
    timezoneStr: user.timezone_str,
    notificationEmail: user.notification_email,
  };
};

export { cfpMapFile, cfpMapOrder, cfpMapUser };
