import React from "react";
import classnames from "classnames";
import 	"./button.css";


export default props => {
	const {className, color, modificatorByFilter, modificatorByMeasure} = props;
	return (
	  <button onClick={props.onClick} className={classnames("button", {active: props.active}, className, color, modificatorByMeasure, modificatorByFilter)} disabled={props.disabled}>
	    {props.children}
	  </button>
	);
}
