import React, { useEffect, useState } from 'react';
import { useMobile } from '../Hooks';
import { useLocation } from 'react-router-dom';

import './index.css';

import icon from './nerdify-icon.svg';
import icon1 from './nerdify-icon-1.svg';
import close from './close.svg';
import add from './add.svg';
import download from './download.svg';
import close1 from './close-1.svg';

const useA2hs = () => {
  const { isMobile, isIos, isAndroid } = useMobile();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [deferredPrompt, setDefferedPromt] = useState(null);

  const [isClosed, setIsClosed] = useState(
    JSON.parse(localStorage.getItem('_is_a2hs_closed', 1))
  );

  useEffect(() => {
    setIsModal(document.body.classList.contains('scroll-fix'));

    if (!isAndroid) {
      return;
    }

    const beforeInstallPromt = (e) => {
      e.preventDefault();
      setDefferedPromt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromt);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromt);
    };
  }, [isAndroid, location]);

  const setClosed = () => {
    localStorage.setItem('_is_a2hs_closed', 1);
    setIsClosed(true);
  };

  const onClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setClosed();
  };

  const onClickShowPromt = () => {
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();

    return deferredPrompt.userChoice
      .then((resp) => {
        if (resp.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          setDefferedPromt(null);
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        return resp;
      })
      .catch((error) => error);
  };

  const isA2hsAndroid =
    isAndroid && isMobile && !isClosed && deferredPrompt !== null && !isModal;

  const isA2hsIos = isIos && isMobile && !isClosed && !isModal;

  const a2hs = {
    component: () => (
      <>
        {isA2hsAndroid && (
          <div className="a2hs-android" onClick={onClickShowPromt}>
            <img src={icon} alt="" className="a2hs-android__icon" />
            <div className="a2hs-android__text">
              Add Nerdify icon to the main screen for quick access
            </div>
            <div className="a2hs-android__close" onClick={onClose}>
              <img src={close} alt="" />
            </div>
          </div>
        )}

        {isA2hsIos && (
          <>
            <div className="a2hs-ios-overlay" />
            <div className="a2hs-ios">
              <div className="a2hs-ios__title">
                <img src={icon1} alt="" className="a2hs-ios__title-icon" />
                Add Nerdify icon to the main screen
              </div>

              <div className="a2hs-ios__info">
                <div className="a2hs-ios__info-1">
                  Click &apos;Share button&apos;
                  <img
                    src={download}
                    className="a2hs-ios__info-1-icon"
                    alt=""
                  />
                </div>
                <div className="a2hs-ios__info-2">
                  Click &apos;Add to Home Screen&apos; button
                  <img src={add} className="a2hs-ios__info-2-icon" alt="" />
                </div>
              </div>

              <div className="a2hs-ios__close" onClick={onClose}>
                <img src={close1} alt="" />
              </div>
            </div>
          </>
        )}
      </>
    ),
  };

  a2hs.component.displayName = 'a2hsComponent';

  return a2hs;
};

export { useA2hs };
