import React from 'react';
import {Link} from 'react-router-dom'
import Icon from './../../components/SvgIcons'
import './NotFound.css'

const NotFound = () => (
    <div className="msg-page not-found-page">
        <div className="wrapper with-fixed-header">
            <div className="frame-no-border">
                <div className="img-wrap">
                    <Icon className="svg-icon" iconName="not-found" />
                </div>
                <h2 className="title">Ooops..</h2>
                <div className="subtitle">We can't find the page you're looking for. </div>
                <ul className="link-block">
                    <li>
                        <Link className="link type2" to="/login">Log into your account</Link>
                    </li>
                    <li>
                        <Link className="link type2-inverted" to="/contact-us">Contact us</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
)

export default NotFound
