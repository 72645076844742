import { range } from './utils/range';
import {
  converter,
  minOrderInPages,
  maxOrderInPages,
  minPageSizeInWords,
} from './utils/MeasureConvertor';
import React from "react";

//console.log("REACT_APP_SMSTOOL_NAME:",process.env.REACT_APP_SMSTOOL_NAME);
//console.log("NODE_ENV:",process.env.NODE_ENV);

export const IS_PROD = process.env.REACT_APP_ENV === 'prod';

export const FLOW_GH = false;

export const jobTypes = [
  {
    id: 'writing',
    siteName: 'Writing',
    priceName: 'Essay (writing)',
    measures: ['Pages', 'Words'],
  },
  {
    id: 'editing',
    siteName: 'Editing',
    priceName: 'Essay (editing)',
    measures: ['Pages', 'Words'],
  },
  {
    id: 'presentation',
    siteName: 'Presentation',
    priceName: 'Presentations',
    measures: ['Slides'],
  },
  {
    id: 'problems',
    siteName: 'Problems',
    priceName: 'Problems',
    measures: ['Minutes'],
  },
  {
    id: 'quiz',
    siteName: 'Quiz',
    priceName: 'Quiz',
    measures: ['Minutes'],
  },
  {
    id: 'task',
    siteName: 'Other task',
    priceName: 'Other task',
    measures: ['Minutes'],
  },
];

export const POPULAR_SUBJECTS = [
  'English',
  'Business and Management',
  'Healthcare and Nursing',
];

export const SUBJECTS = [
  'Academic Writing',
  'Aerodynamics',
  'Aerospace Engineering',
  'Agriculture, Fisheries and Food',
  'Algebra',
  'Analytics',
  'Anthropology',
  'Applied Mathematics',
  'Archaeology',
  'Art',
  'Art History',
  'Artificial Intelligence',
  'Astronomy',
  'Atmospheric Science',
  'Atomic Physics',
  'Behavioral Psychology',
  'Biology',
  'Biology and Life Sciences',
  'Calculus',
  'Chemical Engineering',
  'Chemistry',
  'Civil Engineering',
  'Classical Music',
  'Cognitive Psychology',
  'Communication Studies',
  'Computer Science',
  'Creative Writing',
  'Criminology',
  'Culture',
  'Data Analytics',
  'Descriptive Analytics',
  'Descriptive Statistics',
  'Design and Architecture',
  'Ecology',
  'Economics',
  'Education',
  'Electrical Engineering',
  'Engineering',
  'Environmental Science',
  'Ethics',
  'Experimental Psychology',
  'Finance, Accounting and Banking',
  'Forestry',
  'Game Theory',
  'Genetics and Heredity',
  'Geography',
  'Geology',
  'Geometry and Topology',
  'Governmental Studies',
  'History',
  'History and Anthropology',
  'History of Literature',
  'HRM',
  'Immunology',
  'Inferential Statistics',
  'Information Theory',
  'International Business',
  'International Economics',
  'International Politics',
  'International Relations',
  'Introduction to Visual Arts',
  'IT',
  'IT Security',
  'Journalism',
  'Labor and Employee Relations',
  'Law and International Law',
  'Life Sciences',
  'Linguistics',
  'Literature',
  'Logic',
  'Managerial Analytics',
  'Macroeconomics',
  'Marketing and PR',
  'Maths',
  'Mechanical Engineering',
  'Microbiology',
  'Microeconomics',
  'Military Studies',
  'Modern Physics',
  'Molecular Biology',
  'Morphology',
  'Music',
  'Music History',
  'Network Engineering',
  'Neuroscience',
  'Nuclear Physics',
  'Number Theory',
  'Nursing Theory',
  'Nutrition',
  'Organization Development',
  'Performance Arts',
  'Philosophy',
  'Phonology',
  'Physics',
  'Physiology',
  'Plant Sciences',
  'Political Behavior',
  'Political Economy',
  'Political Methodology',
  'Political Science',
  'Political Sociology',
  'Probability and Statistics',
  'Programming',
  'Project Management',
  'Psycholinguistics',
  'Psychology',
  'Public Health',
  'Religion',
  'Semantics and Pragmatics',
  'Sociology',
  'Sociology of Economy',
  'Software Engineering',
  'Statistics',
  'Syntax',
  'Theology',
  'Theories of Modern Art',
  'Thermodynamics',
  'Visual Arts',
  'Web Development',
];

export const ALL_SUBJECTS = POPULAR_SUBJECTS.concat(SUBJECTS);

//export const deadlinesInHours = [6, 12, 18, 24, 48, 72, 96, 120];
//export const deadlinesLabels = ["6 hours", "12 hours", "18 hours", "1 day", "2 days", "3 days", "4 days", "4+ days"];
export const deadlinesInHours = [3, 6, 12, 18, 24, 48, 72, 96, 120];
export const deadlinesLabels = [
  '3 hours',
  '6 hours',
  '12 hours',
  '18 hours',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '4+ days',
];
export const deadlinesInHoursPremium = [2, 3, 6, 12, 18, 24, 48, 72, 96, 120];
export const deadlinesLabelsPremium = [
  '2 hours',
  '3 hours',
  '6 hours',
  '12 hours',
  '18 hours',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '4+ days',
];
export const defaultDeadlineInHours = deadlinesInHours[3];

export const pagesRange = range(minOrderInPages, maxOrderInPages);
export const slidesRange = range(
  converter.convertPagesToSlides(minOrderInPages),
  converter.convertPagesToSlides(maxOrderInPages)
);
export const wordsRange = range(
  minPageSizeInWords,
  converter.convertPagesToWords(maxOrderInPages),
  minPageSizeInWords
);
export const hoursRange = range(0, 24);
export const minutesRange = [0, 15, 30, 45];

/*
export const extraServices = {
  TopNerdPercentage: 20, //20% of order price
  DraftPercentage: 15, //15% of order price
  PlagiarismReportCost: 12, //$12
  PrioritizeMyTaskCost: 8, //$8
}
*/

export const shortPhoneNumber = {
  usa: '77467', // 'United States'
  australia: '+61429215513', // 'Australia'
  canada: '+17784002244', // 'Canada'
};

// var defaults = {
// 	"valid_deadline": {
// 		"task": [[24, 12], [32, 24], [64, 48], [96, 72]],
// 		"problems": [[24, 12], [32, 24], [64, 48], [96, 72]],
// 		"quiz": [[24, 12], [32, 24], [64, 48], [96, 72]],
// 		"writing": [[6, 12], [8, 24], [16, 48], [24, 72]],
// 		"editing": [[6, 12], [8, 24], [16, 48], [24, 72]],
// 		"presentation": [[12, 12], [16, 24], [32, 48], [48, 72]]
// 	},
// };

export const mapServices = {
  prioritize_my_task: {
    title: 'Prioritize my Task',
    desc: 'Feature your task as prioritized to get a Nerd asap.',
    desc2: <>Our Nerds will consider your requests<br/> as a TOP priority</>,
  },
  top_writer: {
    title: 'TOP Nerd',
    desc: 'Get one of our TOP performers working on your task.',
    desc2: 'One of our TOP performers will work on your task',
  },
  adv_plagreport: {
    title: 'Plagiarism Report',
    desc: 'Get certified proof that your task is plagiarism-free.',
    desc2: 'You\'ll get certified proof that your task is plagiarism-free'
  },
/*
  draft_needed: {
    title: 'Draft',
    desc: 'Receive a draft on a halfway point to the due date.',
    desc2: '',
  },
*/
};

export const mapServices_premiumAddonsItems = [
  {
    title: <><strong>Prioritization</strong> of all your tasks</>,
    desc: 'Our Nerds will consider your requests as a TOP priority'
  },
  {
    title: <><strong>TOP Nerds</strong> assigned to all your tasks</>,
    desc: 'Only Nerds with 4.9+ ratings will work on your tasks'
  },
  {
    title: <><strong>Preferred Nerds</strong> can be chosen</>,
    desc: 'You can get one of your favorite Nerds for your task'
  },
  {
    title: <><strong>Plagiarism Report</strong> for relevant tasks</>,
    desc: 'Get certified proof that your task is plagiarism-free'
  },
  {
    title: <><strong>VIP customer support</strong></>,
    desc: 'Dedicated VIP phone number & personal support team'
  },
];
export const premiumAddonsSubscriptionCost = 7; //$7

export const PGTW_DETAILS = {
  url: process.env.REACT_APP_PGTW_EXTERNAL,
  company: process.env.REACT_APP_PGTW_EXTERNAL_COMPANY,
  currency: 'USD',
  vendor: 'stripe',
};
