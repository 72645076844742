import {
  getOrder,
  getOrders,
  mapOrder,
  patchOrder,
  postOrder,
  submitReview,
  newPostOrder,
  orderFilesUrl,
  get,
} from '../api';

import _ from 'lodash';

import prepareOrder from '../utils/prepareOrder';
import { convertWithSize } from '../utils/MeasureConvertor';

export const FETCHED_ORDERS_REQUEST = 'FETCHED_ORDERS_REQUEST';
export const FETCHED_ORDERS_RECEIVE = 'FETCHED_ORDERS_RECEIVE';
export const FETCHED_ORDERS_ERROR = 'FETCHED_ORDERS_ERROR';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_RECEIVE = 'FETCH_ORDER_RECEIVE';
export const POSTED_ORDER = 'POSTED_ORDER';
export const UPDATED_ORDER = 'UPDATED_ORDER';

export const RATE_ORDER_REQUEST = 'RATE_ORDER_REQUEST';
export const RATE_ORDER_RECEIVE = 'RATE_ORDER_RECEIVE';
export const RATE_ORDER_ERROR = 'RATE_ORDER_ERROR';

export const CHANGE_DISCOUNT_REQUEST = 'CHANGE_DISCOUNT_REQUEST';
export const CHANGE_DISCOUNT_RECEIVE = 'CHANGE_DISCOUNT_RECEIVE';
export const CHANGE_DISCOUNT_ERROR = 'CHANGE_DISCOUNT_ERROR';

export const CHANGE_JOBTYPE_RECEIVE = 'CHANGE_JOBTYPE_RECEIVE';
export const CHANGE_MEASURE_RECEIVE = 'CHANGE_MEASURE_RECEIVE';
export const CHANGE_SIZE_RECEIVE = 'CHANGE_SIZE_RECEIVE';
export const CHANGE_DEADLINE_REQUEST = 'CHANGE_DEADLINE_REQUEST';
export const CHANGE_DEADLINE_RECEIVE = 'CHANGE_DEADLINE_RECEIVE';
export const CHANGE_DEADLINE_ERROR = 'CHANGE_DEADLINE_ERROR';
export const CHANGE_ADDON = 'CHANGE_ADDON';

export const CLEAR_DISCOUNT_ERROR = 'CLEAR_DISCOUNT_ERROR';

export const GET_ORDERS_INVALIDATE = 'GET_ORDERS_INVALIDATE';

export const fetchOrders = () => (dispatch) => {
  dispatch({ type: FETCHED_ORDERS_REQUEST });

  return getOrders()
    .then((orders) => {
      dispatch({
        type: FETCHED_ORDERS_RECEIVE,
        payload: orders.reduce((result, order) => {
          result[order.id] = {
            ...order,
            state: {
              loading: false,
              fetched: false,
            },
          };
          return result;
        }, {}),
        total: orders.length,
      });

      return orders;
    })
    .catch((error) => {
      dispatch({ type: FETCHED_ORDERS_ERROR, error });
      return error;
    });
};

const ordersShouldFetch = (state) => {
  const ordersState = state.orders;

  if (!Object.keys(ordersState.data).length) {
    return true;
  }

  return ordersState.isDidInvalidate;
};

export const ordersFetchIfNeeded = () => (dispatch, getState) => {
  if (!ordersShouldFetch(getState())) {
    return Promise.resolve();
  }
  return dispatch(fetchOrders());
};

export const ordersInvalidate = () => (dispatch) =>
  dispatch({ type: GET_ORDERS_INVALIDATE });

export const fetchOrder = (orderId) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ORDER_REQUEST,
    payload: { id: orderId },
  });

  return getOrder(orderId).then((resp) => {
    dispatch({ type: FETCH_ORDER_RECEIVE, payload: resp });
    return resp;
  });
};

const orderShouldFetch = (orderId, state) => {
  const orderState = state.order;
  const { data } = orderState;

  return !_.some(data, { _id: orderId });
};

export const orderFetchIfNeeded = (orderId) => (dispatch, getState) => {
  if (!orderShouldFetch(orderId, getState())) {
    return Promise.resolve();
  }
  return dispatch(fetchOrder(orderId));
};

export const createOrder = (payload, callback) => (dispatch) => {
  return postOrder(payload).then((order) => {
    dispatch({ type: POSTED_ORDER, payload: order });
    return order;
  });
};

export const updateOrder = (orderId, payload, callback) => (dispatch) => {
  return patchOrder(orderId, payload)
    .then((order) => {
      dispatch({ type: UPDATED_ORDER, payload: mapOrder(order) });

      if (callback) {
        callback();
      }

      return order;
    })
    .catch((error) => error);
};

export const saveOrder = (payload, opts, callback) => (dispatch) => {
  const payload_ = { ...payload, ...opts };
  if (!payload.id) {
    return dispatch(createOrder(payload_, callback));
  } else {
    return dispatch(updateOrder(payload.id, payload_, callback));
  }
};

export const reviewOrder = (orderId, payload) => (dispatch) => {
  dispatch({
    type: RATE_ORDER_REQUEST,
    payload: {
      id: orderId,
    },
  });

  return submitReview(orderId, payload)
    .then((resp) => {
      dispatch({ type: RATE_ORDER_RECEIVE, payload: mapOrder(resp) });
      return resp;
    })
    .catch((error) => {
      dispatch({
        type: RATE_ORDER_ERROR,
        payload: {
          id: orderId,
          error,
        },
      });

      return error;
    });
};

export const changeDiscount = (orderId, payload) => (dispatch) => {
  // dispatch({ type: 'CHANGE_DISCOUNT_REQUEST', payload: { id: orderId } });

  return newPostOrder(orderId, payload)
    .then((order) => {
      dispatch({ type: CHANGE_DISCOUNT_RECEIVE, payload: mapOrder(order) });
      return order;
    })
    .catch((error) => {
      dispatch({
        type: CHANGE_DISCOUNT_ERROR,
        payload: {
          id: orderId,
          error,
        },
      });
    });
};

export const clearDiscountError = (orderId) => (dispatch) => {
  dispatch({ type: CLEAR_DISCOUNT_ERROR, payload: { id: orderId } });
};

export const changeJobType = (orderId, newJobType) => (dispatch, getState) => {
  const { order, settings } = getState();

  const { measures, priceName } = settings.jobTypes.find(
    (j) => j.id === newJobType
  );
  const fittedMeasure =
    measures.length > 1 ? order.data.measurePagesOrWords : measures[0];

  const data = Object.assign(order.data, {
    priceName,
    jobType: newJobType,
    measure: fittedMeasure,
    title: newJobType,
  });

  return patchOrder(orderId, prepareOrder(data))
    .then((order) => {
      dispatch({ type: CHANGE_JOBTYPE_RECEIVE, payload: mapOrder(order) });
      return order;
    })
    .catch((error) => error);
};

export const changeMeasure = (orderId, newMeasure) => (dispatch, getState) => {
  const { order } = getState();

  const measureData =
    ['Pages', 'Words'].indexOf(newMeasure) > -1
      ? { measure: newMeasure, measurePagesOrWords: newMeasure }
      : { measure: newMeasure };

  const data = Object.assign(order.data, measureData);

  return patchOrder(orderId, prepareOrder(data))
    .then((order) => {
      dispatch({ type: CHANGE_MEASURE_RECEIVE, payload: mapOrder(order) });
      return order;
    })
    .catch((error) => {});
};

export const changeSize = (orderId, newSize) => (dispatch, getState) => {
  const { order } = getState();

  const data = Object.assign(order.data, convertWithSize(newSize, order.data));

  return patchOrder(orderId, prepareOrder(data))
    .then((order) => {
      dispatch({ type: CHANGE_SIZE_RECEIVE, payload: mapOrder(order) });
      return order;
    })
    .catch((error) => error);
};

export const changeAddon = (orderId, data) => (dispatch, getState) => {
  return newPostOrder(orderId, data)
    .then((order) => {
      dispatch({ type: CHANGE_ADDON, payload: order }); //add new action type
      return order;
    })
    .catch((error) => error);
};

export const changeDeadline = (orderId, newDeadline) => (
  dispatch,
  getState
) => {
  const { order } = getState();
  const data = Object.assign(order.data, { deadlineInHours: newDeadline });

  dispatch({
    type: CHANGE_DEADLINE_REQUEST,
    payload: {
      id: orderId,
    },
  });

  return patchOrder(orderId, prepareOrder(data))
    .then((order) => {
      dispatch({ type: CHANGE_DEADLINE_RECEIVE, payload: mapOrder(order) });
      return order;
    })
    .catch((error) => error);
};

export const changeDeadlineIfNeeded = (orderId, newDeadline) => (
  dispatch,
  getState
) => {
  const deadlineState = getState().orders;

  if (
    deadlineState.data[orderId] &&
    deadlineState.data[orderId].state.loading
  ) {
    return Promise.resolve();
  }

  return dispatch(changeDeadline(orderId, newDeadline));
};

export const GET_ORDER_FILES = 'GET_ORDER_FILES';
export const GET_ORDER_FILES_SUCCESS = 'GET_ORDER_FILES_SUCCESS';
export const GET_ORDER_FILES_ERROR = 'GET_ORDER_FILES_ERROR';

export const orderFiles = (
  state = {
    data: [],
    isFetching: true,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ORDER_FILES:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ORDER_FILES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    case GET_ORDER_FILES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const orderFilesShouldFetch = (state) => {
  const orderFilesState = state.orderFiles;

  return !orderFilesState.data.length;
};

export const orderFilesFetchIfNeeded = (orderId) => (dispatch, getState) => {
  if (!orderFilesShouldFetch(getState())) {
    return Promise.resolve();
  }

  return dispatch(getOrderFiles(orderId));
};

export const getOrderFiles = (orderId) => (dispatch) => {
  dispatch({ type: GET_ORDER_FILES });

  return get(orderFilesUrl(orderId))
    .then((resp) => {
      dispatch({
        type: GET_ORDER_FILES_SUCCESS,
        payload: resp.results,
      });

      return resp;
    })
    .catch((error) => {
      dispatch({
        type: GET_ORDER_FILES_ERROR,
        error,
      });

      return error;
    });
};

const initState = {
  data: {},
  loading: false,
  fetched: false,
  stateItems: [],
  total: null,
  isFetching: true,
  error: null,
  isDidInvalidate: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case FETCHED_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCHED_ORDERS_RECEIVE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        fetched: true,
        total: action.total,
        isFetching: false,
        isDidInvalidate: false,
      };
    case FETCHED_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        fetched: false,
        isFetching: false,
        error: action.error,
        isDidInvalidate: false,
      };

    case RATE_ORDER_REQUEST:
    case CHANGE_DEADLINE_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            state: {
              loading: true,
              fetched: false,
            },
          },
        },
      };
    case RATE_ORDER_RECEIVE:
    case POSTED_ORDER:
    case UPDATED_ORDER:
    case CHANGE_DISCOUNT_RECEIVE:
    case CHANGE_DEADLINE_RECEIVE:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
            state: {
              loading: false,
              fetched: true,
            },
          },
        },
      };
    case RATE_ORDER_ERROR:
    case CHANGE_DISCOUNT_ERROR:
    case CHANGE_DEADLINE_ERROR:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            state: {
              loading: false,
              fetched: true,
              error: action.payload.error,
            },
          },
        },
      };

    case CLEAR_DISCOUNT_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            state: {
              loading: false,
              fetched: true,
              error: null,
            },
          },
        },
      };
    case GET_ORDERS_INVALIDATE:
      return {
        ...state,
        isDidInvalidate: true,
      };
    default:
      return state;
  }
};
