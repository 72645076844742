import React from 'react'
import Icon from './../../components/SvgIcons'

import './ProgressTrackerPage.css'

const CabinetBasicScreenEmpty = props => {
    return (
        <div className="msg-page progress-tracker-page">
            <div className="img-wrap">
                <Icon className="svg-icon" iconName="progress-tracker-page"/>
            </div>
            <h2 className="title">Hi there!</h2>
            <div className="subtitle">{props.children}</div>
        </div>
    )
}




export default CabinetBasicScreenEmpty
