import React, { Component } from "react"
import { connect } from 'react-redux'
import classnames from "classnames"
import { Link } from "react-router-dom"
import ReactGA from 'react-ga';
import { ordersFetchIfNeeded } from "./../../reducers/orders";
import { userFetchIfNeeded } from "./../../reducers/user";
import Icon from "./../SvgIcons"
import "./main-menu.css"

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      //hasReferral: false,
    };

    this.closeMenu = this.closeMenu.bind(this)
    this.triggerMenu = this.triggerMenu.bind(this);
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.userFetchIfNeeded();
      this.props.ordersFetchIfNeeded();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated
    } = this.props.user;

    if (isAuthenticated !== prevProps.user.isAuthenticated && isAuthenticated) {
      this.props.userFetchIfNeeded();
      this.props.ordersFetchIfNeeded();
    }
    window.onpopstate = e => {
      this.closeMenu()
    }
  }

  triggerMenu() {
    let menuStatus = this.state.isOpen;
    this.setState({ isOpen: !this.state.isOpen });
    var bodyItem = document.getElementsByTagName("body")[0];
    if (!menuStatus) {
      window.scrollTo(0, 0);
      bodyItem.classList.add("fixed");
    }
    else {
      bodyItem.classList.remove("fixed");
    }
  }

  closeMenu() {
    window.scrollTo(0, 0);
    this.setState({ isOpen: false });
    var bodyItem = document.getElementsByTagName("body")[0];
    bodyItem.classList.remove("fixed");
  }

  render() {
    const { isAuthenticated } = this.props.user;

    // const expData = this.props.user?.data?.exp_data || {};
    const userData = this.props.user?.data || {};
    const hasReferral = !userData?.tags?.includes("NOreferral");

    const isSubscription2020 = userData?.subscriptions_active?.includes("nerdify_top_50");
    //const isBasicPremiumSubscription = (expData.subscription === 'crm3549;basic_premium_subscription' || expData.subscription === 'cfp3448;basic_premium_subscription');
    const isBasicPremiumSubscription = true;
    //const isPrimeDualSubscription = expData.subscription === 'crm3549;prime_dual_subscription';
    const isPrimeDualSubscription = false;
    const isSubscriptionsActive = userData?.subscriptions_active?.length || 0;

    const { orders } = this.props;
    const { isOpen } = this.state;
    const cntOrders = orders.data && Object.values(orders.data).filter(order => !order.declined).length || 0;

    return (
      <div className={classnames("main-menu", { isOpen })}>
        <button className="burger-btn" type="button" onClick={this.triggerMenu}>
          {/*<Icon className="svg-icon" iconName="burger-icon" />*/}
          <span className="burger-btn-msg">Menu</span>
          <Icon className="svg-icon" iconName="close-icon" />
        </button>
        <div className="main-menu-list-wrapper">

          <nav className="main-menu-list">
              {isAuthenticated && <>
                <Link className="menu-link no-sep" to="/orders" onClick={this.closeMenu}>
                  My tasks&nbsp;<span className="task-num">({cntOrders})</span>
                </Link>
                <Link className="menu-link" to="/my-info" onClick={this.closeMenu}>My info</Link>
                {!isSubscription2020 && (isBasicPremiumSubscription || isPrimeDualSubscription) &&
                  <Link className="menu-link menu-link-tag" to="/nerdify-subscription" onClick={this.closeMenu}>
                    My subscription {!isSubscriptionsActive && <span className="menu-tag">Special</span>}
                  </Link>
                }

                {hasReferral &&
                <Link className="menu-link menu-link-tag referral-link" to="/ref-cabinet" onClick={() => {
                  window.scrollTo(0, 0);
                  ReactGA.event({
                    category: 'Ref_cabinet_events',
                    action: 'Ref_button_click',
                    label: 'ref_button_click_sidemenu_page'
                  });
                  this.closeMenu()
                }}>
                  Get $20 {!!isSubscriptionsActive && <span className="menu-tag">Special</span>}
                </Link>
                }

              </>}
              <Link className="menu-link hire-menu-link" to="/neworder" onClick={this.closeMenu}>Hire a Nerd</Link>
              {isAuthenticated
                ? <Link className="login menu-link" to="/logout" onClick={this.closeMenu}>Sign out</Link>
                : <Link className="login menu-link" to="/login" onClick={this.closeMenu}>Login</Link>
              }
            <Link className="hire-menu-btn" to="/neworder" onClick={this.closeMenu}>Hire a Nerd</Link>
            <div className="contact-menu-block">
              <Icon className="svg-icon" iconName="open-menu-img" />
              <div className="contact-menu-msg">
                Something not clear?
                <Link className="contact-us-link" to="/contact-us" onClick={this.closeMenu}>Contact us</Link>
              </div>
            </div>
          </nav>

        </div>
      </div>
    )
  }
}


export default connect(
  (state, ownProps) => ({
    user: state.user,
    orders: state.orders,
  }),
  (dispatch, ownProps) => ({
    ordersFetchIfNeeded: () => dispatch(ordersFetchIfNeeded()),
    userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
  })
)(MainMenu)
