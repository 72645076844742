import React from 'react'
import {Link} from 'react-router-dom'
import classnames from "classnames"
import Icon from './../SvgIcons'
import  './footer.css'

const FooterBody = () => {
  return (
    <div className="footer-body">
      <div className="wrapper">
        <nav className="footer-nav">
          <Link className="footer-nav-link" to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
          <Link className="footer-nav-link" to="/terms" onClick={() => window.scrollTo(0, 0)}>Terms of Use</Link>
        </nav>
        {/*<div className="with-love">Made with <Icon className="svg-icon" iconName="heart-icon" /> in San Francisco and London</div>*/}
        <div className="footer-img-block">
          {/*<div className="stripe-img"></div>*/}
          <ul className="footer__payment-list">
            <li className="footer__payment-item">
              <img className="footer__payment-img visa" src={require('./img/payment/visa.png')}
                   srcSet={`${require("./img/payment/visa.png")}, ${require("./img/payment/visa@2x.png")} 2x`} alt=""/>
            </li>
            <li className="footer__payment-item">
              <img className="footer__payment-img mc" src={require('./img/payment/mc.png')}
                   srcSet={`${require("./img/payment/mc.png")}, ${require("./img/payment/mc@2x.png")} 2x`} alt=""/>
            </li>
            <li className="footer__payment-item">
              <img className="footer__payment-img ae" src={require('./img/payment/ae.png')}
                   srcSet={`${require("./img/payment/ae.png")}, ${require("./img/payment/ae@2x.png")} 2x`} alt=""/>
            </li>
            <li className="footer__payment-item">
              <img className="footer__payment-img discover" src={require('./img/payment/discover.png')}
                   srcSet={`${require("./img/payment/discover.png")}, ${require("./img/payment/discover@2x.png")} 2x`}
                   alt=""/>
            </li>
            {/*
                <li className="payment-item">
                  <img className="payment-img" src={require('./img/payment/pp.png')}
                       srcSet={`${require("./img/payment/pp.png")}, ${require("./img/payment/pp@2x.png")} 2x`} alt=""/>
                </li>
            */}
            <li className="footer__payment-item">
              <img className="footer__payment-img google-pay" src={require('./img/payment/google-pay.png')}
                   srcSet={`${require("./img/payment/google-pay.png")}, ${require("./img/payment/google-pay@2x.png")} 2x`}
                   alt=""/>
            </li>
            <li className="footer__payment-item">
              <img className="footer__payment-img applepay" src={require('./img/payment/applepay.png')}
                   srcSet={`${require("./img/payment/applepay.png")}, ${require("./img/payment/applepay@2x.png")} 2x`}
                   alt=""/>
            </li>
          </ul>
          <img className="footer__badges-img" src={require('./img/badges2-max.png')}
               srcSet={`${require("./img/badges2-max.png")}, ${require("./img/badges2-max@2x.png")} 2x`} alt=""/>
        </div>
      </div>
    </div>
  )
};

const FooterMain = (props) => {
  return (
    <footer className={classnames('footer', {[props.type]: props.type})}>
      <div className="contact-menu-block">
        <div className="wrapper">
          <div className="frame-no-border">
            <Icon className="svg-icon" iconName="open-menu-img" />
            <div className="contact-menu-msg">
              Something not clear? <Link className="contact-us-link" to="/contact-us">Contact us</Link>
            </div>
          </div>
        </div>
      </div>
      <FooterBody />
    </footer>
  )
};

const FooterLogin = (props) => {
  return (
    <footer className={classnames('footer', {[props.type]: props.type})}>
      <FooterBody />
    </footer>
  )
};

export { FooterMain, FooterLogin }
