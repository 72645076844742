import {
    pageSizeInPages,
    pageSizeInWords,
    pageSizeInSlides,
    pageSizeInMinutes
} from './MeasureConvertor'

const fullSize = {
    pageSizeInPages,
    pageSizeInWords,
    pageSizeInSlides,
    pageSizeInMinutes
};

function restrictions({ pages, words, slides, minutes, deadlineInHours, measurePagesOrWords, jobType }) {

  if (['writing', 'editing'].indexOf(jobType) > -1 && measurePagesOrWords === 'Pages') {
    if (pages > 1 && deadlineInHours <= 3) return 'Please, give your Nerd more time to complete your task';
    if (pages > 4 && deadlineInHours <= 6) return 'Please, give your Nerd more time to complete your task';
    if (pages > 8 && deadlineInHours <= 12) return 'Please, give your Nerd more time to complete your task';
    if (pages > 10 && deadlineInHours <= 18) return 'Please, give your Nerd more time to complete your task';
    if (pages > 12 && deadlineInHours <= 24) return 'Please, give your Nerd more time to complete your task';
    if (pages > 18 && deadlineInHours <= 48) return 'Please, give your Nerd more time to complete your task';
  }
  if (['writing', 'editing'].indexOf(jobType) > -1 && measurePagesOrWords === 'Words') {
    if (words > 300 && deadlineInHours <= 3) return 'Please, give your Nerd more time to complete your task';
    if (words > 1200 && deadlineInHours <= 6) return 'Please, give your Nerd more time to complete your task';
    if (words > 2400 && deadlineInHours <= 12) return 'Please, give your Nerd more time to complete your task';
    if (words > 3000 && deadlineInHours <= 18) return 'Please, give your Nerd more time to complete your task';
    if (words > 3600 && deadlineInHours <= 24) return 'Please, give your Nerd more time to complete your task';
    if (words > 5400 && deadlineInHours <= 48) return 'Please, give your Nerd more time to complete your task';
  }
  if (jobType === 'presentation') {
    if (slides > 2 && deadlineInHours <= 3) return 'Please, give your Nerd more time to complete your task';
    if (slides > 8 && deadlineInHours <= 6) return 'Please, give your Nerd more time to complete your task';
    if (slides > 16 && deadlineInHours <= 12) return 'Please, give your Nerd more time to complete your task';
    if (slides > 20 && deadlineInHours <= 18) return 'Please, give your Nerd more time to complete your task';
    if (slides > 24 && deadlineInHours <= 24) return 'Please, give your Nerd more time to complete your task';
    if (slides > 36 && deadlineInHours <= 48) return 'Please, give your Nerd more time to complete your task';
  }
  if (['problems', 'quiz', 'task'].indexOf(jobType) > -1) {
    if (minutes > 1 * 60 && deadlineInHours <= 3) return 'Please, give your Nerd more time to complete your task';
    if (minutes > 4 * 60 && deadlineInHours <= 6) return 'Please, give your Nerd more time to complete your task';
    if (minutes > 8 * 60 && deadlineInHours <= 12) return 'Please, give your Nerd more time to complete your task';
    if (minutes > 10 * 60 && deadlineInHours <= 18) return 'Please, give your Nerd more time to complete your task';
    if (minutes > 12 * 60 && deadlineInHours <= 24) return 'Please, give your Nerd more time to complete your task';
    if (minutes > 18 * 60 && deadlineInHours <= 48) return 'Please, give your Nerd more time to complete your task';
  }
/*
  if (['writing', 'editing'].indexOf(jobType) > -1 && measurePagesOrWords === 'Pages') {
    if (pages >= 6 && deadlineInHours < 12) return 'Please, give your Nerd more time to complete your task';
    if (pages >= 8 && deadlineInHours < 24) return 'Please, give your Nerd more time to complete your task';
    if (pages >= 16 && deadlineInHours < 48) return 'Please, give your Nerd more time to complete your task';
    if (pages >= 24 && deadlineInHours < 72) return 'Please, give your Nerd more time to complete your task';
  }
  if (['writing', 'editing'].indexOf(jobType) > -1 && measurePagesOrWords === 'Words') {
      if (words >= 1800 && deadlineInHours < 12) return 'Please, give your Nerd more time to complete your task';
      if (words >= 2400 && deadlineInHours < 24) return 'Please, give your Nerd more time to complete your task';
      if (words >= 4800 && deadlineInHours < 48) return 'Please, give your Nerd more time to complete your task';
      if (words >= 7200 && deadlineInHours < 72) return 'Please, give your Nerd more time to complete your task';
  }
  if (jobType === 'presentation') {
      if (slides >= 12 && deadlineInHours < 12) return 'Please, give your Nerd more time to complete your task';
      if (slides >= 16 && deadlineInHours < 24) return 'Please, give your Nerd more time to complete your task';
      if (slides >= 32 && deadlineInHours < 48) return 'Please, give your Nerd more time to complete your task';
      if (slides >= 48 && deadlineInHours < 72) return 'Please, give your Nerd more time to complete your task';
  }
  if (['problems', 'quiz', 'task'].indexOf(jobType) > -1) {
      if (minutes >= 6 * 60 && deadlineInHours < 12) return 'Please, give your Nerd more time to complete your task';
      if (minutes >= 8 * 60 && deadlineInHours < 24) return 'Please, give your Nerd more time to complete your task';
      if (minutes >= 16 * 60 && deadlineInHours < 48) return 'Please, give your Nerd more time to complete your task';
      if (minutes >= 24 * 60 && deadlineInHours < 72) return 'Please, give your Nerd more time to complete your task';
  }
*/
}


export default (order, pricing) => {

    if (!pricing || !order.jobType) {
        return null
    }

    let ddIndex = 0
    while (pricing._DD[ddIndex] < order.deadlineInHours && ddIndex < pricing._DD.length) ddIndex++;

    const measure_ = order.measure === 'Pages' && order.measurePagesOrWords === 'Words'
        ? 'Words'
        : order.measure;

    const pricePerPage = order.measure === 'Minutes' ? pricing[order.priceName][ddIndex - 1] * 4 : pricing[order.priceName][ddIndex - 1];
    const pages_ = order[measure_.toLowerCase()] / fullSize[`pageSizeIn${measure_}`];
    const price = (pricePerPage * pages_).toFixed(2);
    return { price, restrictions: restrictions(order), calculatedServerPrice: order.price }
}
