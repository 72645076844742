import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import classnames from "classnames";

import Button from "../../components/Button";
import Icon from "../../components/SvgIcons";
import _ from 'lodash'

import { createReminder } from '../../api';
import convertDateTimeToDeadlineDaysHours from "../../utils/convertDateTimeToDeadlineDaysHours";
import getMessageDiscountInfo from "../../utils/getMessageDiscountInfo";
import { updateCurrentOrder } from "../../reducers/order";
import { saveOrder, orderFetchIfNeeded, changeAddon } from "../../reducers/orders";
import prepareOrder from '../../utils/prepareOrder'
import { orderAvailableServices } from "../../utils/orderAvailableServices";
import { getSizeText } from "../../utils/MeasureConvertor";
import {fetchUser, savePostUser} from '../../reducers/user';

import { mapServices, mapServices_premiumAddonsItems, premiumAddonsSubscriptionCost } from '../../config';


class ExtraServices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      top_writer: false,
      draft_needed: false,
      adv_plagreport: false,
      prioritize_my_task: false,
      premium_addons_subscription: false,
    };

    this.onPayNow = this.onPayNow.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleAddSubscriptionPlan = this.handleAddSubscriptionPlan.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();
    this.props.orderFetchIfNeeded();

    const {
      order,
      user,
    } = this.props;

    const {
      data,
    } = order;

    const { services } = data;

    this.setServicesState(services);

    if (user?.data?.tags.includes("subscription_agreed")) {
      this.setState({
        premium_addons_subscription: user.data.tags.includes("subscription_agreed"),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      order,
      user,
    } = this.props;

    const {
      data,
      isFetching
    } = order;

    if (isFetching !== prevProps.order.isFetching && !isFetching) {
      const { services } = data;

      this.setServicesState(services);
    }

    if (user !== prevProps.user) {
      this.setState({
        premium_addons_subscription: user?.data?.tags.includes("subscription_agreed"),
      });
    }
  }

  setServicesState(services) {
    this.setState({
      top_writer: _.get(services, 'top_writer.added', false),
      draft_needed: _.get(services, 'draft_needed.added', false),
      adv_plagreport: _.get(services, 'adv_plagreport.added', false),
      prioritize_my_task: _.get(services, 'prioritize_my_task.added', false),
    });
  }

  handleCheckboxChange(event, tag) {
    const target = event.target;
    this.setState({
      [target.name]: target.checked
    });

    let data = {};

    if (target.checked) {
      data = {
        action: 'service_add',
        tag: tag
      }
    } else {
      data = {
        action: 'service_delete',
        tag: tag
      }
    }
    this.props.changeAddon(data);
  }

  handleAddSubscriptionPlan(event) {
    const target = event.target;

    if (target.checked) {
      this.props.savePostUser({ action : 'subscription_agreed' });
    } else {
      this.props.savePostUser({ action : 'subscription_disagreed' });
    }

    this.setState({
      premium_addons_subscription: target.checked
    });
  }

  onPayNow() {
    const { user, order } = this.props;

    const payload = prepareOrder(order.data);
    let discountMessage = '';

    this.props.saveOrder(payload, { payment_link_sent: true })
      .then((resp) => {
        if (resp && resp.discount_info) {
          discountMessage = getMessageDiscountInfo(resp);
        }

        const { price, number, payment_link, size, service_type, deadline } = resp;
        const dd = convertDateTimeToDeadlineDaysHours(deadline);
        const new_order_event = false;


        let message = `User has just added additional task details and clicked on 'pay now' button.
                    Type of task: ${service_type}.
                    Length: ${getSizeText(size)}.
                    Due: ${dd.days}d ${dd.hours}h / ${moment(deadline).format("ll")}.
                    Price: ${price}.`;

        if (discountMessage) {
          message = message + `
                    Coupon: ${resp.discount_info.code}.
                    Discount: ${discountMessage}.`;
        }

        message = message + `
                    Order number: ${number}.
                    Payment link: ${payment_link}.`;

        const servicesList = [];
        {
          Object.keys(mapServices).map(item => {
            order.data.services[item].added && servicesList.push(mapServices[item].title);
          })
        }


        if (servicesList.length) {
          message = message + `
                    Add-ons: ${servicesList.join(', ')}.`;
        }

        return {
          ...resp,
          new_order_event,
          message
        }

      }).then(resp => {
        const {
          message, number, new_order_event
        } = resp;

        createReminder(user, message, number, new_order_event)

        return {
          ...resp
        }

      }).then(resp => {
        const {
          _id,
          payment_link
        } = resp;

        if (this.state.premium_addons_subscription) {
          window.location = `${payment_link}?subscription_id=premium_addons&success_url=${encodeURIComponent(`${window.location.origin}/order/${_id}/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`;
        } else {
        window.location = `${payment_link}?success_url=${encodeURIComponent(`${window.location.origin}/order/${_id}/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`;
        }

        return resp
      }).catch(error => error);
  }

  render() {
    const {
      user,
      order,
      history
    } = this.props;

    const {
      data,
      isFetching
    } = order;

    if (isFetching) {
      return null;
    }

    const { services, price } = data;


    const userData = (user.isAuthenticated && user.fetched && user?.data) ?? {};

    const hasPremiumAddonsSubscriptionLabel = userData?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';
    //const hasPremiumAddonsSubscriptionLabel = true;
    const hasPremiumAddonsSubscription = userData?.subscriptions_active?.includes("premium_addons");
    //const hasPremiumAddonsSubscription = user?.data?.tags?.includes("subscription_premium_addons");
    //const hasPremiumAddonsSubscription = false;
    const isSubscriptionPaymentFailed = ((this.props?.user?.data?.tags.includes("subscription_inactive") && !this.props?.user?.data?.tags.includes("subscription_refunded") && !this.props?.user?.data?.tags.includes("subscription_canceled")) || this.props?.user?.data?.tags.includes("subscription_pastdue"));

    if (hasPremiumAddonsSubscriptionLabel && isSubscriptionPaymentFailed) {
      history.push('/orders');
    }


    return (
      <>
        <div className="wrapper with-fixed-header-mob">
          <div className="frame frame-order">

            { //if (hasPremiumAddonsSubscription)
              hasPremiumAddonsSubscription
              ? <div className="extra-services">
                <h1 className="extra-services-title">Premium services added<br/> to your task for free</h1>

                <div className="subs-list3">

                  {Object.keys(orderAvailableServices(data)).map(item => {

                    const service = services[item];
                    const isFree = !service.price;
                    const isAdded = service.added;

                    if (isAdded) return (
                      <div className={classnames("subs-list-item", {"free": isFree})}
                           key={`subs-list-item-${item}`}>
                        <img className="check-icon" src={require(`./img/ic-check2.svg`)} alt=""/>
                        <div className="subs-item-msg">
                          <div className="subs-item-line">
                            <div className="subs-item-title">{mapServices[item].title}</div>
                            {isFree && <div className="subs-item-free-label">Free</div>}
                          </div>
                          <div className="subs-item-desc">{mapServices[item].desc2}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="order-price-block">
                  <div className="order-price-block-inner">
                    <div className="order-price-string">
                      <div className="order-price-label">Task price:</div>
                      <div className="order-price-amount">${price}</div>
                    </div>
                    <Button onClick={() => this.onPayNow()}>Pay Now!</Button>
                  </div>
                </div>

              </div>

              //else if (hasPremiumAddonsSubscriptionLabel)
              : hasPremiumAddonsSubscriptionLabel
                ? <div className="order-subscription">
                  <h1 className="title">
                    Get exclusive features with <strong>Nerdify Premium</strong>
                  </h1>
                  <div className="subs-list">
                    <div className="label">$7.00/mo</div>
                    {mapServices_premiumAddonsItems.map((item, index) => {
                      return (
                        <div className="subs-list-item" key={`premium-added-no-subs-${index}`}>
                          <img className="check-icon" src={require(`./img/ic-check1.svg`)} alt=""/>
                          <div className="subs-item-msg">
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.desc}</div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="agree-line agree-line4">Your subscription auto-renews at the end of each monthly term and will
                    continue until you cancel. The bonuses and perks added to your account remain valid till the end of each
                    monthly term after auto-renewal.</div>

                  <div className="order-price-block">
                    <div className="order-price-block-inner">

                      <div className="checkobox-string">
                        <input className="checkbox-item" type="checkbox"
                               id="premium_addons_subscription"
                               checked={this.state.premium_addons_subscription}
                               onChange={(e) => {
                                 this.handleAddSubscriptionPlan(e)
                               }}
                        />
                        <label className="checkbox-label" htmlFor="premium_addons_subscription">
                    <span className="checkbox-icon">
                      <Icon className="svg-icon" iconName="check-icon" />
                    </span>
                          <span className="checkobox-msg">Add subscription plan:</span>
                        </label>
                        <div className="order-price-amount">$7.00</div>
                      </div>
                      <div className="order-price-string">
                        <div className="order-price-label">Task price:</div>
                        <div className="order-price-amount">${price}</div>
                      </div>
                      <Button onClick={() => this.onPayNow()}>
                        Pay ${price + (this.state.premium_addons_subscription ? premiumAddonsSubscriptionCost : 0)}
                      </Button>
                    </div>
                  </div>

                </div>

                // Addons
                : <div className="extra-services">
                  <h1 className="extra-services-title">You can add extra services!</h1>
                  <ul className="extra-services-list">
                    {Object.keys(orderAvailableServices(data)).map(item => {

                      const service = services[item];
                      const isFree = !service.price;
                      const isAdded = service.added;

                      return (
                        <li className={classnames("extra-services-item", {"free": isFree}, {"added": isAdded})}
                            key={`extra-services-item-${item}`}>
                          <input name={item} className="service-checkbox" id={item} type="checkbox"
                                 checked={this.state[item]}
                                 onChange={(e) => {
                                   !isFree && this.handleCheckboxChange(e, item)
                                 }}
                          />
                          <label className="service-checkbox-label" htmlFor={item}>
                            <Icon className="svg-icon" iconName="free-label"/>
                            <div className="service-checkbox-icon">
                              <Icon className="svg-icon" iconName="check-empty"/>
                              <Icon className="svg-icon" iconName="radio-check"/>
                            </div>
                            <div className="service-title">{mapServices[item].title}</div>
                            <div className="service-price">${services[item].price}</div>
                            <div className="service-desc">{mapServices[item].desc}</div>
                          </label>
                        </li>
                      )
                    })
                    }
                  </ul>

                  <div className="order-price-block">
                    <div className="order-price-block-inner">
                      <div className="order-price-string">
                        <div className="order-price-label">Task price:</div>
                        <div className="order-price-amount">${price}</div>
                      </div>
                      <Button onClick={() => this.onPayNow()}>Pay Now!</Button>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => {
    const { order, user } = state;

    return {
      order,
      user,
    }
  },
  (dispatch, ownProps) => ({
    fetchUser: () => dispatch(fetchUser()),
    updateCurrentOrder: (payload) => dispatch(updateCurrentOrder(payload)),
    saveOrder: (payload, opts) => dispatch(saveOrder(payload, opts)),
    orderFetchIfNeeded: () => dispatch(orderFetchIfNeeded(ownProps.match.params.orderId)),
    changeAddon: (data) => dispatch(changeAddon(ownProps.match.params.orderId, data)),
    savePostUser: (payload) => dispatch(savePostUser(payload)),
  })
)(ExtraServices)
