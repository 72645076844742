import { useParams, useHistory, useLocation } from 'react-router-dom';

export const useCfpLocation = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const isOrders = /^\/orders\/?/.test(location.pathname);
  const isOrder = /^\/order\/[\w]+$/.test(location.pathname);
  const isNewOrder = /^\/neworder\/[\w]+$/.test(location.pathname);
  const url = isOrders ? 'orders' : 'order';

  return { url, isOrders, isOrder, isNewOrder, history, params };
};
