import { converter, convertWithSize } from '../utils/MeasureConvertor';
import { defaultDeadlineInHours } from '../config';
import {
  CHANGE_DISCOUNT_RECEIVE,
  CHANGE_JOBTYPE_RECEIVE,
  CHANGE_MEASURE_RECEIVE,
  CHANGE_SIZE_RECEIVE,
  CHANGE_DEADLINE_RECEIVE,
  FETCH_ORDER_RECEIVE,
  POSTED_ORDER,
  UPDATED_ORDER,
  CHANGE_ADDON,
  CLEAR_DISCOUNT_ERROR,
  FETCH_ORDER_REQUEST,
  RATE_ORDER_REQUEST,
  RATE_ORDER_RECEIVE,
  RATE_ORDER_ERROR,
} from './orders';

export const UPDATE_CURRENT_ORDER = 'UPDATE_CURRENT_ORDER';
export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER';

const initState = {
  data: {
    _id: '',
    priceName: 'Essay (writing)',
    price: null,
    title: '',
    subject: '',
    details: '',
    deadlineInHours: defaultDeadlineInHours,
    measure: 'Pages',
    measurePagesOrWords: 'Pages',
    pages: 2, //pagesRange[0],
    jobType: 'writing',
    words: converter.convertPagesToWords(2), //(pagesRange[0])
    slides: converter.convertPagesToSlides(2), //(pagesRange[0])
    minutes: converter.convertPagesToMinutes(2), //(pagesRange[0])
    attachments: [],
    discount: '',
  },
  error: null,
  isFetching: true,
};

export const updateCurrentOrderJobType = (newJobType) => (
  dispatch,
  getState
) => {
  const orderState = getState().order;
  const settingsState = getState().settings;

  const { data } = orderState;
  const { measures, priceName } = settingsState.jobTypes.find(
    (j) => j.id === newJobType
  );
  // console.log(measures, priceName, newJobType)
  const fittedMeasure =
    measures.length > 1 ? data.measurePagesOrWords : measures[0];
  if (data.jobType !== newJobType || data.priceName !== priceName) {
    dispatch(
      updateCurrentOrder({
        priceName,
        jobType: newJobType,
        measure: fittedMeasure,
        title: newJobType,
      })
    );
  }
};

export const updateCurrentOrderMeasure = (newMeasure) => (dispatch) => {
  if (['Pages', 'Words'].indexOf(newMeasure) > -1)
    dispatch(
      updateCurrentOrder({
        measure: newMeasure,
        measurePagesOrWords: newMeasure,
      })
    );
  else dispatch(updateCurrentOrder({ measure: newMeasure }));
};

export const updateCurrentOrderSize = (newSize) => (dispatch, getState) => {
  dispatch(updateCurrentOrder(convertWithSize(newSize, getState().order.data)));
};

export const updateCurrentOrderDeadline = (newDeadline) => (dispatch) => {
  dispatch(updateCurrentOrder({ deadlineInHours: newDeadline }));
};

export const updateCurrentOrder = (payload) => ({
  type: UPDATE_CURRENT_ORDER,
  payload,
});

export const clearCurrentOrder = () => ({
  type: CLEAR_CURRENT_ORDER,
});

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CURRENT_ORDER:
      return {
        ...initState,
      };
    case POSTED_ORDER:
    case UPDATED_ORDER:
    case CLEAR_DISCOUNT_ERROR:
    case UPDATE_CURRENT_ORDER: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case FETCH_ORDER_REQUEST:
    case RATE_ORDER_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isFetching: true,
      };
    case CHANGE_DISCOUNT_RECEIVE:
    case CHANGE_MEASURE_RECEIVE:
    case CHANGE_JOBTYPE_RECEIVE:
    case CHANGE_SIZE_RECEIVE:
    case CHANGE_DEADLINE_RECEIVE:
    case CHANGE_ADDON:
    case FETCH_ORDER_RECEIVE:
    case RATE_ORDER_RECEIVE:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    case RATE_ORDER_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
