import React, { useEffect, Component } from "react";
import { connect, useSelector } from "react-redux";
import classnames from "classnames";

import Button from "./../../components/Button";
import Icon from "./../../components/SvgIcons";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';

//import { createReminder } from './../../api';
//import convertDateTimeToDeadlineDaysHours from "./../../utils/convertDateTimeToDeadlineDaysHours";
//import getMessageDiscountInfo from "./../../utils/getMessageDiscountInfo";
import { updateCurrentOrder } from "./../../reducers/order";
import { saveOrder, fetchOrder, changeAddon } from "./../../reducers/orders";
import { round } from './../../utils/MeasureConvertor';

//import prepareOrder from './../../utils/prepareOrder'
import { orderAvailableServices, isNotActiveServicesAvailable } from "./../../utils/orderAvailableServices";
import { get } from "lodash";

import { mapServices } from './../../config';
import { userFetchIfNeeded } from "../../reducers/user";
import { setCid, trackClicks, useTrackValues } from '../../lrtracker';

import { useMobile } from '../../components/Hooks';

const AppPopUp = ({orderId}) => {
  const { isMobile } = useMobile();
  const {data} = useSelector(state => state.user);

  const userId = data?.id;

  setCid(userId);
  useTrackValues('button-show', 'downloadApp', {
    context: { orderId, type: isMobile ? 'mobile' : 'desktop' },
  });

  const onAppBtnClick = () => {
    trackClicks('target', 'downloadApp', {
      context: { orderId, type: isMobile ? 'mobile' : 'desktop' },
    })();
  };

  return (
    <div className="thank-you-alternate">
      <h1 className="thank-you-title">Your payment was<br className="mobile"/> successful!</h1>
      <div className="frame">
        <div className="frame-title">Upgrade your experience<br/> with Nerdify App for iOS:</div>
        <ul className="app-list">
          <li className="app-list-item">Track task progress</li>
          <li className="app-list-item">Access all tasks & files</li>
          <li className="app-list-item">Chat with us</li>
          <li className="app-list-item">Send new requests</li>
          <li className="app-list-item">And much more!</li>
        </ul>

        <div className="app-btn-block">
          {!isMobile &&
            <img
              className="qr-btn-img"
              src={require("./img/qr.png")}
              srcSet={`${require("./img/qr@2x.png")} 2x`}
              alt=""
            />
          }
          {isMobile ?
            <a className="app-btn" onClick={ onAppBtnClick } href="https://nerdify.page.link/wtQ4" target="_blank" rel="noreferrer">
              <img
                className="app-btn-img"
                src={require("./img/app.png")}
                srcSet={`${require("./img/app@2x.png")} 2x, ${require("./img/app@3x.png")} 3x`}
                alt=""
              />
            </a>
            :
            <a className="app-btn" onClick={ onAppBtnClick } href="https://nerdify.page.link/2L6u" target="_blank" rel="noreferrer">
              <img
                className="app-btn-img"
                src={require("./img/app.png")}
                srcSet={`${require("./img/app@2x.png")} 2x, ${require("./img/app@3x.png")} 3x`}
                alt=""
              />
            </a>
          }
        </div>

        {isMobile ?
          <img
            className="cell-img"
            src={require("./img/cell-mob.png")}
            srcSet={`${require("./img/cell-mob@2x.png")} 2x, ${require("./img/cell-mob@3x.png")} 3x`}
            alt=""
          />
          :
          <img
            className="cell-img"
            src={require("./img/cell.png")}
            srcSet={`${require("./img/cell@2x.png")} 2x, ${require("./img/cell@3x.png")} 3x`}
            alt=""
          />
        }
      </div>
      {isMobile &&
      <div className="go-to-cabinet-link-wrapper">
        <Link className="go-to-cabinet-link" to='/orders'>
          <span className="link-msg">Go to cabinet</span>
        </Link>
      </div>
      }
    </div>
  )
};


class ThankYouPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orderLoaded: false,
      isServicesAvailable: true,

      top_writer: false,
      draft_needed: false,
      adv_plagreport: false,
      prioritize_my_task: false,
    };
    this.onPayNow = this.onPayNow.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

  }

  componentDidMount() {
    this.props.userFetchIfNeeded();

    if (this.props.orderId) {
      this.props.fetchOrder().then(
        (resp) => {
          const {
            order
          } = this.props;

          const {
            data
          } = order;

          const { isPaid, system_client_status, services } = data;
          const isRefunded = system_client_status.toLowerCase().indexOf('refund') + 1;

          if (!isPaid || isRefunded) {  // if not paid = redirect /orders or refunded
            const { history } = this.props;
            history.push("/");
          }

          this.setState({
            isServicesAvailable: isNotActiveServicesAvailable(data),
            top_writer: services.top_writer.added,
            draft_needed: services.draft_needed.added,
            adv_plagreport: services.adv_plagreport.added,
            prioritize_my_task: services.prioritize_my_task.added,
            orderLoaded: true,
          });
          //this.draftCheck();

          return resp;
        }
      ).catch(error => error);
    } else {
      //this.createOrder();
    }
  }

  handleCheckboxChange(event, tag) {
    const target = event.target;
    this.setState({
      [target.name]: target.checked
    });

    let data = {};

    if (target.checked) {
      data = {
        action: 'service_add',
        tag: tag
      }
    } else {
      data = {
        action: 'service_delete',
        tag: tag
      }
    }
    this.props.changeAddon(data);
  }


  onPayNow() {
    const { order } = this.props;
    const {
      data
    } = order;

    window.location = `${data.payment_link}?success_url=${encodeURIComponent(`${window.location.origin}/order/${data._id}/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`;
  }

  render() {
    const {
      order
    } = this.props;

    const {
      data
    } = order

    const { services, price, client_paid, refunded } = data;


    if (this.props.user.fetched && this.state.orderLoaded) {

      const userAgent = typeof window !== 'undefined' && navigator.userAgent.toLowerCase();
      const isAndroid = /android/.test(userAgent);

      const userData = this.props?.user?.data;

      const userLabels = userData?.exp_data;
      const isPremiumAddonsLabel = userLabels?.subscription_nerdy === 'cfp4221;addons_subscription';
      const userTags = userData?.tags;
      const orderId = this.props?.order?.data?._id;

      return (
        <div className="wrapper with-fixed-header">
          {
            //userLabels?.app2_scale === 'cfp3864;app_offer' &&
            !(userLabels?.app2_scale === 'cfp3864;no_app_offer') &&
            !userTags.includes('app_do_not_offer') &&
            !userTags.includes('no_account') &&
            !(userData.country_final === 'United Kingdom' || userData.country_final === 'Australia' || userData.country_final === 'Canada') &&
            !isAndroid ?
            <AppPopUp orderId={orderId}/>

          :
          this.state.isServicesAvailable ?
            <div className="thank-you">
              <div className="frame">
                <h1 className="thank-you-title">Your payment was successful!</h1>
                {!isPremiumAddonsLabel && <>
                <h2 className="extra-services-title">You can add extra services<br />
                  to your order</h2>

                <ul className="extra-services-list">
                  {Object.keys(orderAvailableServices(data)).map(item => {
                    const service = services[item];
                    const isFree = !service.price;
                    const isActive = service.active;
                    const isAdded = service.added;
                    return (
                      <li
                        className={classnames("extra-services-item", {"free": isFree}, {"active": isActive}, {"added": isAdded})}
                        key={`extra-services-item-${item}`}>
                        <input name={item} className="service-checkbox" id={item} type="checkbox"
                               checked={this.state[item]}
                               onChange={(e) => {
                                 !isActive && this.handleCheckboxChange(e, item)
                               }}
                        />
                        <label className="service-checkbox-label" htmlFor={item}>
                          {/*<Icon className="svg-icon" iconName="free-label"/>*/}
                          <div className="service-checkbox-icon">
                            <Icon className="svg-icon" iconName="check-empty"/>
                            <Icon className="svg-icon" iconName="radio-check"/>
                          </div>
                          <div className="service-title">{mapServices[item].title}</div>
                          <div className="service-price">${services[item].price}</div>
                          <div className="service-desc">{mapServices[item].desc}</div>
                        </label>
                      </li>
                    )
                  })
                  }
                </ul>
                </>}

                <div className="service-price-block">
                  <div className="service-price-block-inner">
                    {(price - (client_paid - refunded)) > 0 && !isPremiumAddonsLabel ?
                      <Button className="service-pay-btn" onClick={() => this.onPayNow()}>Pay
                          ${round(price - (client_paid - refunded))}</Button>
                      :
                      <div className={`go-to-cabinet-link-wrapper ${isPremiumAddonsLabel ? 'center':''}`}>
                        <Link className="go-to-cabinet-link" to='/orders'>
                          <span className="link-msg">Go to cabinet</span>
                          <Icon className="svg-icon" iconName="arrow-right-icon" />
                        </Link>
                      </div>
                    }
                  </div>
                </div>
              </div>

            </div>
            :
            <Redirect to={'/orders'} />
          }
          {/*
                <div className="frame unavailable-screen">
                  <div className="unavailable-title">Oops!<br/> We are really sorry.</div>
                  <Icon className="svg-icon" iconName="sad-face-icon"/>
                  <div className="unavailable-msg">… but this extra service is unavailable, as we have already found a Nerd for your task.</div>
                  <Link className="go-to-cabinet-link" to='/orders'>
                    <span className="link-msg">Go to cabinet</span>
                  </Link>
                </div>
*/}
        </div>
      )
    } else {
      return null;
    }
  }
}

export default connect(
  (state, ownProps) => {
    const { orderId } = ownProps.match.params;
    const { orders } = state;

    return {
      isLoading: get(orders.data, `${[orderId]}.state.loading`, false),
      orderError: get(orders.data, `${[orderId]}.state.error`, false),
      session: state.user.session,
      order: state.order,
      settings: state.settings,
      user: state.user,
      orderId: ownProps.match.params.orderId,
    }
  },
  (dispatch, ownProps) => {
    const { orderId } = ownProps.match.params;

    return {
      updateCurrentOrder: (payload) => dispatch(updateCurrentOrder(payload)),
      fetchOrder: () => dispatch(fetchOrder(orderId)),
      saveOrder: (payload, opts) => dispatch(saveOrder(payload, opts)),
      userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
      changeAddon: (data) => dispatch(changeAddon(orderId, data)),
    }
  }
)(ThankYouPage)
