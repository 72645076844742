import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import registerServiceWorker, { unregister } from './registerServiceWorker';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

unregister();

caches.keys().then(function (names) {
  for (let name of names) caches.delete(name);
});
