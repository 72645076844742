import * as moment from 'moment';

export default (data) => {
  const {
    measure,
    title,
    subject,
    details,
    priceName,
    boolAddonPlagReport,
  } = data;

  const deadline = moment(new Date()).add(data.deadlineInHours - 0.25, 'hours'); // minus 15 min

  const size = `${data[measure.toLowerCase()]} ${measure.toLowerCase()}`;
  const id = data.id;

  const files = data.attachments.map((file) => ({
    location: file.location,
    size: file.size,
    type: file.filetype,
    file: file.filename,
  }));

  return {
    title,
    subject,
    details,
    deadline,
    size,
    id,
    files,
    type: priceName,
    plagiarism_check: boolAddonPlagReport,
  };
};
