import React, { Component } from 'react';
import classnames from "classnames";
import Highlighter from "react-highlight-words";

import {
  POPULAR_SUBJECTS,
  ALL_SUBJECTS
} from "../../config";

import './index.css';

import searchImg from './img/search.svg';
//import prepareOrder from "../../utils/prepareOrder";

class SubjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      isFocused: false,
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickInput = this.onClickInput.bind(this);
    this.onClickItem = this.onClickItem.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);

    this.subjectRef = null;
    this.listRef = null;
  }

  componentDidMount() {
    const {
      subject
    } = this.props;

    window.addEventListener('click', this.clickOutside);

    this.updateState({
      subject
    });
  }

  componentDidUpdate(prevProps) {
    const {
      subject,
    } = this.props;

    if (subject !== prevProps.subject) {
      this.updateState({
        subject
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickOutside);
  }

  updateState(state) {
    this.setState(state);
  }

  onChangeInput(e) {
    this.setState({
      subject: e.target.value
    });

    this.props.onChangeSubject(e.target.value);

    if (e.target.value && ALL_SUBJECTS.includes(e.target.value)) {
      this.props.updateCurrentOrder({
        subject: e.target.value
      });
    }
  }

  onClickInput() {
    this.setState({
      isFocused: true,
    });
  }

  onBlurInput() {
    setTimeout(() => {
      this.setState({
        isFocused: false,
      });
    }, 300);
  }

  onClickItem(subject) {
    this.setState({
      subject,
      isFocused: false,
    });

    this.props.updateCurrentOrder({
      subject
    });

    this.props.onChangeSubject(subject);
  }

  clickOutside(e) {
    if (
      this.subjectRef && this.subjectRef.contains(e.target)
    ) {
      return false;
    }

    this.setState({
      isFocused: false,
    });
  }

  render() {
    const {
      subject,
      isFocused
    } = this.state;

    const {
      isSubjectError,
      isSearchError,
      searchSubjects,
      ordersSubjects,
      allSubjects
    } = this.props;

    const isFound = searchSubjects.length > 0;
    const isOrdersSubjects = ordersSubjects.length > 0;

    const filterSubjects = isOrdersSubjects
      ? allSubjects.filter(item => !ordersSubjects.includes(item))
      : allSubjects.filter(item => !POPULAR_SUBJECTS.includes(item));

    const topSubjects = isSearchError
      ? []
      : isFound
        ? []
        : isOrdersSubjects
          ? ordersSubjects
          : POPULAR_SUBJECTS;

    const listSubjects = isSearchError
      ? []
      : isFound
        ? searchSubjects
        : filterSubjects.sort()

    const isOpen = isFocused && !isSearchError;

    return (
      <div className="subjects" ref={(el) => this.subjectRef = el}>
        {(isSearchError || isSubjectError) && (
          <div className="subjects__error">
            Please choose subject from the list
          </div>
        )}

        <div className="subjects__input-holder">
          <img src={searchImg} alt="" />

          <input
            type="text"
            onChange={this.onChangeInput}
            onClick={this.onClickInput}
            onBlur={this.onBlurInput}
            value={subject}
            className={classnames('subjects__input', {
              'subjects__input_error': isSearchError || isSubjectError,
              'subjects__input_placeholder': !this.state.subject,
            })}
            ref={(el) => this.listRef = el}
            placeholder="Please select subject"
          />
        </div>

        {isOpen && (
          <div className="subjects__list">
            {topSubjects.length > 0 && (
              <div className="subjects__list-popular">
                <div className="subjects__list-popular-title">
                  {isOrdersSubjects
                    ? <span>recent requests</span>
                    : <span>popular requests</span>}
                </div>
                {topSubjects.map(item => {
                  return (
                    <div
                      key={`subjects__list-popular-item-${item}`}
                      className="subjects__list-popular-item"
                      onClick={() => this.onClickItem(item)}
                    >
                      {item}
                    </div>
                  )
                })}
              </div>
            )}

            {listSubjects.length > 0 && (
              <div className="subjects__list-other">
                {!isFound && (
                  <div className="subjects__list-other-title">100+ more courses</div>
                )}

                {listSubjects.map(item => {
                  return (
                    <div
                      key={`subjects__list-other-item-${item}`}
                      className="subjects__list-other-item"
                      onClick={() => this.onClickItem(item)}
                    >
                      <Highlighter
                        highlightClassName="subjects__list-item-highlight"
                        searchWords={subject.split(' ')}
                        autoEscape={true}
                        textToHighlight={item}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default SubjectsList;
