import React from "react";
import classnames from "classnames";
import {Link} from 'react-router-dom'
import 	"./linkButton.css";

export default props => {
	const {modificatorByType, path} = props;
	return (
    <div className="link-button-wrapper">
      <div className="wrapper">
        <Link className={classnames("link-button", modificatorByType)} to={path ? path : '/neworder'}>
          {props.children ?
            props.children
            :
            "Hire a Nerd"
          }
        </Link>
      </div>
    </div>
	);
}
