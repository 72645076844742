import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from "classnames";
import { userFetchIfNeeded, saveUser, updateUser } from './../../reducers/user';
//import ReactGA from 'react-ga';
import regexps from "./../../utils/regexps";
import LinkButton from './../../components/LinkButton';
import { useA2hs } from '../../components/Pwa';

import debounce from 'debounce';
import "./my-info.css";

const withHooks = (WrappedComponent) => {
  const WithHooks = (props) => {
    const a2hs = useA2hs();
    const mergeProps = { ...props, a2hs }
    return <WrappedComponent {...mergeProps} />
  }

  WithHooks.displayName = `withHooks(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return WithHooks;
}

class MyInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSubmitBtnVisible: false,
      isEmailValid: true,
      isEmailSaved: false,
    };
    this.saveUser = debounce((payload) => this.props.saveUser(payload), 3000);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onSaveUser = this.onSaveUser.bind(this);
  }

  componentDidMount() {
    this.props.userFetchIfNeeded();
  }

  onInputFocus() {
    if (this.state.isEmailSaved) {
      this.setState({
        email: "",
        isEmailSaved: false,
      });
    }
  }

  onChangeUser(email) {
    this.setState({
      email,
      isEmailSaved: false,
    });
    if (email) {
      this.setState({
        isSubmitBtnVisible: true,
        isEmailValid: regexps.email(email),
      });
    } else {
      this.setState({
        isSubmitBtnVisible: false,
        isEmailValid: true,
      });
    }
  }

  onSaveUser(event) {
    if (this.state.email && this.state.isEmailValid) {
      this.props.updateUser({email : this.state.email});
      this.saveUser({email : this.state.email});
      this.setState({
        isSubmitBtnVisible: false,
        isEmailSaved: true,
      });
    }
    event.preventDefault();
  }

  render() {
    if (!this.props.user.fetched) {
      return null
    }
    //const email = this.props.user.data.email;
    //const isValid = regexps.email(email);
    //const balance = this.props.user.data.balance.toFixed(2);
    const balance = this.props.user.data.available_balance.toFixed(2);

    const { error } = this.props;

    const isErrorText = error && error.text ? error.text : 'Invalid email';

    const {
      a2hs
    } = this.props;

    return (
      <>
        <div className="my-info-page">
          <div className="wrapper with-fixed-header">
            <div className="frame">
              {/*
                        <div onClick={() => {ReactGA.event({category: 'Ref_cabinet_events',action: 'Ref_button_click',label: 'Ref_button_click_myinfo_page'})}}>
                            <LinkButton path="/ref-cabinet">New: Get $20</LinkButton>
                        </div>
              */}
              <form onSubmit={(e) => this.onSaveUser(e)}>
                <ul className="my-info-list">
                  <li className="balance-item">
                    <div className="balance-label">Your balance</div>
                    <div className="balance-amount">${balance}</div>
                  </li>
                  {/* add .error for display error message & show red border on input */}
                  {/* <li className="input-item error">
                          <div className="info-line clearfix">
                              <label className="item-label" htmlFor="name">Name</label>
                              <div className="error-msg">Error</div>
                          </div>
                          <input id="name" className="my-info-input" type="text"/>
                      </li> */}
                  <li className={classnames("input-item", { error: !this.state.isEmailValid || error || (this.state.email==='' && this.props.user.data.email==='') }, { saved: this.state.isEmailSaved })}>
                    <div className="info-line">
                      <label className="item-label" htmlFor="email">Email</label>
                      <div className="email-status">
                        {!this.state.isEmailValid && isErrorText}
                        {this.state.isEmailSaved && "Email Saved"}
                      </div>
                    </div>
                    <input
                      id="email"
                      className="my-info-input"
                      type="email"
                      placeholder={this.props.user.data.email ? this.props.user.data.email : "Enter email"}
                      value={this.state.email}
                      onFocus={() => this.onInputFocus()}
                      onChange={(e) => this.onChangeUser(e.target.value)}
                    />
                  </li>
                  {/*{error && error.text}*/}
                  {/* <li className="input-item">
                        <div className="info-line clearfix">
                            <label className="item-label" htmlFor="local-time">Local Time</label>
                            <div className="error-msg">Invalid email</div>
                        </div>
                        <input id="local-time" className="my-info-input" type="time" value={moment(this.props.user.data.local_time).format('LT')}/>
                        <input id="local-time" className="my-info-input" value={moment(this.props.user.data.local_time).format('LT')}/>
                    </li> */}
                  {this.state.isSubmitBtnVisible &&
                  <li>
                    <button type="submit" className="list-link" disabled={!this.state.isEmailValid}>Submit</button>
                  </li>
                  }
                </ul>
              </form>
            </div>
            <LinkButton path="/neworder">Hire a Nerd</LinkButton>
          </div >
        </div>
        <a2hs.component />
      </>
    )
  }
}


export default withHooks(connect(
  (state, ownProps) => ({
    user: state.user,
    error: state.user.error,
  }),
  (dispatch, ownProps) => ({
    userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
    saveUser: (payload) => dispatch(saveUser(payload)),
    updateUser: (payload) => dispatch(updateUser(payload)),
  })
)(MyInfo))
