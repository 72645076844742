import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Button from './../../components/Button';
import Icon from './../../components/SvgIcons';
import { updateCurrentOrderJobType, clearCurrentOrder } from './../../reducers/order';
import { changeJobType } from "../../reducers/orders";
import './order-select-job-type.css';

const JobTypeItem = ({ value, text, onClick, isSelected }) => {
  return (
    <li
      className={classnames("order-select-job-type-list-item", { selected: isSelected })}
      onClick={() => onClick(value)}
    >
      {text}
      <div className="check-empty">
        <Icon className="svg-icon" iconName="check-empty" />
      </div>
      <div className="radio-check">
        <Icon className="svg-icon" iconName="radio-check" />
      </div>
    </li>
  )
}

class SelectJobType extends Component {

  constructor(props) {
    super(props)
    this.updateCurrentJobType = this.updateCurrentJobType.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount() {
    if (this.props.order.data.id !== this.props.orderId) {
      this.props.clearCurrentOrder();
    }
  }

  updateCurrentJobType(jobType) {
    this.props.updateCurrentOrderJobType(jobType);
    const { id } = this.props.order.data;
    if (id) {
      this.props.changeJobType(jobType);
    }
  }

  nextStep() {
    const { id, jobType } = this.props.order.data;
    const { history } = this.props;

    this.updateCurrentJobType(jobType);
    const link = id ? `/order/${id}` : `/neworder/${jobType}`;
    history.push(link);
  }

  render() {
    const {
      order
    } = this.props;

    const {
      data
    } = order;

    return (
      <div className="wrapper with-fixed-header-mob">
        <div className="order-select-job-type-wrapper">
          <div className="frame frame-order">
            <div className="order-select-job-type">
              <h2 className="h2">What do you need help with?</h2>
              <ul className="order-select-job-type-list">
                {this.props.jobTypes.map(({ id, siteName }) =>
                  <JobTypeItem
                    isSelected={data.jobType === id}
                    onClick={this.updateCurrentJobType}
                    text={siteName}
                    value={id}
                    key={id}
                  />
                )}
              </ul>
            </div>
            <Button onClick={this.nextStep} disabled={!data.jobType}>Next</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    jobTypes: state.settings.jobTypes,
    orderId: ownProps.match.params.orderId,
    order: state.order,
  }),
  (dispatch, ownProps) => {
    const { orderId } = ownProps.match.params;

    return {
      clearCurrentOrder: () => dispatch(clearCurrentOrder()),
      updateCurrentOrderJobType: (newJobType) => dispatch(updateCurrentOrderJobType(newJobType)),
      changeJobType: (jobType) => dispatch(changeJobType(orderId, jobType)),
    }
  }
)(SelectJobType)
