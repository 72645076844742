import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import classnames from "classnames";

import Button from "./../../components/Button";
import Icon from "./../../components/SvgIcons";
import { Link } from "react-router-dom";

//import { createReminder } from './../../api';
//import convertDateTimeToDeadlineDaysHours from "./../../utils/convertDateTimeToDeadlineDaysHours";
//import getMessageDiscountInfo from "./../../utils/getMessageDiscountInfo";
import { updateCurrentOrder } from "./../../reducers/order";
import { saveOrder, fetchOrder, changeAddon } from "./../../reducers/orders";
import { round } from './../../utils/MeasureConvertor';

//import prepareOrder from './../../utils/prepareOrder'
import { getSizeText } from './../../utils/MeasureConvertor';
import { get } from "lodash";

import { mapServices } from './../../config';
import { userFetchIfNeeded } from "../../reducers/user";
import { orderAvailableServices, isNotActiveServicesAvailable } from "../../utils/orderAvailableServices";


class AdditionalServices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orderLoaded: false,
      isServicesAvailable: true,

      top_writer: false,
      draft_needed: false,
      adv_plagreport: false,
      prioritize_my_task: false,

      showTitle: false,
    };
    this.onPayNow = this.onPayNow.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.triggerTitle = this.triggerTitle.bind(this);
    //this.handleServicesAvailable = this.handleServicesAvailable.bind(this);
  }

  componentDidMount() {
    this.props.userFetchIfNeeded();

    if (this.props.orderId) {
      this.props.fetchOrder().then(
        (resp) => {

          const {
            order
          } = this.props;

          const {
            data
          } = order;

          const { isPaid, system_client_status, services } = data;
          const isRefunded = system_client_status.toLowerCase().indexOf('refund') + 1;

          if (!isPaid || isRefunded) {  // if not paid = redirect /orders or refunded
            const { history } = this.props;
            history.push("/");
          }

          this.setState({
            isServicesAvailable: isNotActiveServicesAvailable(data),
            top_writer: services.top_writer.added,
            draft_needed: services.draft_needed.added,
            adv_plagreport: services.adv_plagreport.added,
            prioritize_my_task: services.prioritize_my_task.added,
            orderLoaded: true,
          });

          return resp;
        }
      ).catch(error => error);
    } else {
      //this.createOrder();
    }
  }

  triggerTitle() {
    this.setState({ showTitle: !this.state.showTitle });
  }

  handleCheckboxChange(event, tag) {
    const target = event.target;
    this.setState({
      [target.name]: target.checked
    });

    let data = {};

    if (target.checked) {
      data = {
        action: 'service_add',
        tag: tag
      }
    } else {
      data = {
        action: 'service_delete',
        tag: tag
      }
    }
    this.props.changeAddon(data);
  }


  onPayNow() {
    const { order } = this.props;
    const {
      data
    } = order;
    window.location = `${data.payment_link}?success_url=${encodeURIComponent(`${window.location.origin}/order/${data._id}/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`;
  }

  render() {
    const {
      order
    } = this.props;

    const {
      data
    } = order;

    const { number, jobType, services, title, size, price, client_paid, refunded } = data;
    const { history } = this.props;

    const onClose = () => {
      history.goBack();
    };

    if (this.state.orderLoaded) {
      return (
        <div className="wrapper">
          {this.state.isServicesAvailable ?
            <div className="additional-services">
              <div className="frame">
                <div className="additional-services-header">
                  {/*                  <Link className="additional-services-close-link" to='/orders'>
                    <Icon className="svg-icon" iconName="close-icon" />
                  </Link>*/}
                  <button type="button" className="additional-services-close-link" onClick={onClose}>
                    <Icon className="svg-icon" iconName="close-icon" />
                  </button>

                  <h1 className="additional-services-title">Additional services</h1>
                </div>
                <div className="additional-services-subheader">
                  <div className="subheader-visible-part" onClick={this.triggerTitle}>
                    <div>
                      <div className="subheader-number-jobtype">{number} • {jobType.charAt(0).toUpperCase() + jobType.slice(1)}</div>
                      <div className="subheader-size">{getSizeText(size)}</div>
                    </div>
                    <button className={classnames("show-more-btn", this.state.showTitle && 'isOpen')} type="button">
                      <Icon className="svg-icon" iconName="down-arrow-icon" />
                    </button>
                  </div>
                  {this.state.showTitle &&
                    <div className="subheader-title">{title}</div>
                  }
                </div>

                <ul className="extra-services-list">
                  {/*
                    {Object.keys(mapServices).map(item => {
                      const service = services[item];
                      const isFree = !service.price;
                      const isActive = service.active;
                      const isRefunded = service.refunded;
                      return (
                        item === 'adv_plagreport' && (jobType !== "writing" && jobType !== "editing" && jobType !== "quiz") ?
                          null
                          : item === 'top_writer' && services.client_recomended_writer.added ?
                            null
                            : isRefunded ?
                              null
                              :
                              <li className={classnames("extra-services-item", {"free": isFree}, {"active": isActive})}
                                  key={`additional-services-item-${item}`}>
                                <input name={item} className="service-checkbox" id={item} type="checkbox"
                                       checked={this.state[item]}
                                       onChange={(e) => {
                                         !isActive && this.handleCheckboxChange(e, item)
                                       }}
                                />
                                <label className="service-checkbox-label" htmlFor={item}>
                                  <Icon className="svg-icon" iconName="free-label"/>
                                  <div className="service-checkbox-icon">
                                    <Icon className="svg-icon" iconName="check-empty"/>
                                    <Icon className="svg-icon" iconName="radio-check"/>
                                  </div>
                                  <div className="service-title">{mapServices[item].title}</div>
                                  <div className="service-price">${services[item].price}</div>
                                  <div className="service-desc">{mapServices[item].desc}</div>
                                </label>
                              </li>
                      )
                    })
                    }
*/}
                  {Object.keys(orderAvailableServices(this.props.order.data)).map(item => {
                    const service = services[item];
                    const isFree = !service.price;
                    const isActive = service.active;
                    const isAdded = service.added;
                    return (
                      <li className={classnames("extra-services-item", { "free": isFree }, { "active": isActive }, { "added": isAdded })}
                        key={`extra-services-item-${item}`}>
                        <input name={item} className="service-checkbox" id={item} type="checkbox"
                          checked={this.state[item]}
                          onChange={(e) => {
                            !isActive && this.handleCheckboxChange(e, item)
                          }}
                        />
                        <label className="service-checkbox-label" htmlFor={item}>
                          {/*<Icon className="svg-icon" iconName="free-label"/>*/}
                          <div className="service-checkbox-icon">
                            <Icon className="svg-icon" iconName="check-empty" />
                            <Icon className="svg-icon" iconName="radio-check" />
                          </div>
                          <div className="service-title">{mapServices[item].title}</div>
                          <div className="service-price">${services[item].price}</div>
                          <div className="service-desc">{mapServices[item].desc}</div>
                        </label>
                      </li>
                    )
                  })
                  }
                </ul>

                {(price - (client_paid - refunded)) > 0 ?
                  <div className="service-price-block">
                    <div className="service-price-block-inner">
                      <Button className="service-pay-btn" onClick={() => this.onPayNow()}>Pay
                        ${round(price - (client_paid - refunded))}</Button>
                    </div>
                  </div>
                  :
                  null
                }

              </div>
            </div>
            :
            <div className="frame unavailable-screen">
              <div className="unavailable-title">Oops!<br />
                We are really sorry.</div>
              <Icon className="svg-icon" iconName="sad-face-icon" />
              <div className="unavailable-msg">… but this extra service is unavailable, as we have already found a Nerd for your task.</div>
              <Link className="go-to-cabinet-link" to='/orders'>
                <span className="link-msg">Go to cabinet</span>
              </Link>
            </div>
          }
        </div>
      )
    } else {
      return null;
    }
  }
}


export default connect(
  (state, ownProps) => {
    const { orderId } = ownProps.match.params;
    const { orders } = state;

    return {
      isLoading: get(orders.data, `${[orderId]}.state.loading`, false),
      orderError: get(orders.data, `${[orderId]}.state.error`, false),
      session: state.user.session,
      order: state.order,
      settings: state.settings,
      user: state.user,
      orderId: ownProps.match.params.orderId,
    }
  },
  (dispatch, ownProps) => {
    const { orderId } = ownProps.match.params;

    return {
      updateCurrentOrder: (payload) => dispatch(updateCurrentOrder(payload)),
      fetchOrder: () => dispatch(fetchOrder(orderId)),
      saveOrder: (payload, opts) => dispatch(saveOrder(payload, opts)),
      userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
      changeAddon: (data) => dispatch(changeAddon(orderId, data)),
    }
  }
)(AdditionalServices)
