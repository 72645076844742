import { fetchUserSubscriptions } from './../api'

const FETCHED_SUBSCRIPTIONS = 'FETCHED_SUBSCRIPTIONS'

const initState = {
  isFetched: false,
  data: null
}

export const getUserSubscriptions = () => (dispatch, getState) => {
  const { user } = getState()
  return fetchUserSubscriptions(user.data.id)
    .then(subscriptions => {
      dispatch({ type: FETCHED_SUBSCRIPTIONS, payload: subscriptions })
    })
}

export default (state = initState, action) => {
  switch (action.type) {
    case FETCHED_SUBSCRIPTIONS:
      return {
        isFetched: true,
        data: action.payload
      }
    default:
      return state;
  }
}