import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { saveUser } from '../../reducers/user'
import {useHistory, Redirect} from 'react-router-dom'

import Modal from '../../components/Modal';
import Icon from './../../components/SvgIcons';

import './index.css';
import { trackClicks, useTrackValues } from '../../lrtracker';
import {mapServices_premiumAddonsItems} from "../../config";

//const STRIPE_SUBSCRIPTIONS_PREMIUM_ADDONS_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PREMIUM_ADDONS_TAG;
const HOST_URL = process.env.REACT_APP_HOST_URL;
const SUCCESS_URL = HOST_URL + '/nerdify-subscription';
const CANCEL_URL = HOST_URL + '/nerdify-subscription/{CHECKOUT_SESSION_ID}';

const SubscriptionPopup = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [btnDisable, setBtnDisable] = useState(false);
  const {data} = useSelector(state => state.user);

  const isSubscriptionPopupShown = data?.userdef?.isSubscriptionPopupShown;
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(!isSubscriptionPopupShown);
  //const isSubscriptionsActive = data && data.subscriptions_active && data.subscriptions_active.length;
  const hasSubscription = data?.tags?.includes("subscription_inactive") || data?.tags?.includes("subscription_active");
  const hasCompletedOrder = data?.has_completed_order;
  //const isBasicPremiumLabel = data?.exp_data?.subscription === 'cfp3448;basic_premium_subscription';
  const isBasicPremiumLabel = true;
  const isPremiumAddonsLabel = data?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';

  useEffect(() => {
    setIsSubscriptionPopupOpen(!isSubscriptionPopupShown)
  }, [isSubscriptionPopupShown])

  useTrackValues('popup-open', 'NerdifySubscription', {
    context: { stage: 'subscription-offer' },
  });

  const onClickCloseSubscriptionPopup = () => {
    dispatch(saveUser({userdef:{isSubscriptionPopupShown:true}})).then(() => history.push('/orders'));
  };

  if (!isSubscriptionPopupOpen || !hasCompletedOrder || hasSubscription || !(isBasicPremiumLabel || isPremiumAddonsLabel)) {
    return <Redirect to={'/orders'} />
  }

  const onClickRedirect = () => {
    trackClicks('target', 'NerdifySubscriptionBtn', {
      context: { stage: 'subscription-offer' },
    })();
    dispatch(saveUser({userdef:{isSubscriptionPopupShown:true}})).then(() => history.push('/nerdify-subscription'));
  };

  const onCreateSubscriptionPGTW = (subscription_id) => {
    trackClicks('target', 'NerdifySubscriptionBtn', {
      context: { stage: 'subscription-offer' },
    })();
    window.location = `${process.env.REACT_APP_PGTW}/s/c${data.referral_code}/${
      subscription_id
    }?success_url=${SUCCESS_URL}&cancel_url=${CANCEL_URL}`;
  };

  return (
    <Modal onClose={onClickCloseSubscriptionPopup} ref={modalRef}>
      {isPremiumAddonsLabel ?

        <div className="subscription-popup">
          <div className="sp__subtitle">Upgrade your experience with</div>
          <div className="sp__title sp__title_type2">Nerdify Premium</div>
          <ul className="sp__list2">
            {mapServices_premiumAddonsItems.map((item, index) => {
              return (
                <div className="sp__list_item" key={`premium-added-no-subs-${index}`}>
                  <img className="sp__list_icon" src={require(`./img/ic-check1.svg`)} alt=""/>
                  <div className="sp__list_msg">
                    <div className="subs-item-title">{item.title}</div>
                    <div className="subs-item-subtitle">{item.desc}</div>
                  </div>
                </div>
              )
            })}
          </ul>
          <button className="button sp__btn" disabled={btnDisable} onClick={() => {
            setBtnDisable(true);
            onCreateSubscriptionPGTW('premium_addons')
          }}>Subscribe</button>
          <div className="sp__msg">$7/mo only</div>
        </div>

      : // isBasicPremiumLabel

      <div className="subscription-popup">
        <div className="sp__subtitle">Upgrade your experience with</div>
        <div className="sp__title">Nerdify Premium</div>
        <ul className="sp__list">
          <li className="sp__list_item">
            <Icon className="sp__list_icon" iconName={`check-active-icon`}/>
            <div className="sp__list_msg">Dedicated VIP phone number</div>
          </li>
          <li className="sp__list_item">
            <Icon className="sp__list_icon" iconName={`check-active-icon`}/>
            <div className="sp__list_msg">Your personal success team</div>
          </li>
          <li className="sp__list_item">
            <Icon className="sp__list_icon" iconName={`check-active-icon`}/>
            <div className="sp__list_msg">FREE unlimited services:<br/>
              “TOP Nerd” & “Prioritize my task”</div>
          </li>
          <li className="sp__list_item">
            <Icon className="sp__list_icon" iconName={`check-active-icon`}/>
            <div className="sp__list_msg">Reduced time frame</div>
          </li>
        </ul>
        <button className="button sp__btn" onClick={ onClickRedirect }>View plans</button>
        <div className="sp__msg">Starting at $7/month only</div>
      </div>
      }
    </Modal>
  );
};

export default SubscriptionPopup;
