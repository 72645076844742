import React, { Component } from 'react'

export default class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: undefined,
    };
    this._toggleActive = this._toggleActive.bind(this)
    this._handleOnClickClose = this._handleOnClickClose.bind(this)
  }


  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.activeIndex !== this.state.activeIndex) {
  //     const el = document.querySelector('#content-' + this.state.activeIndex) || null;
  //     if (el) {
  //       el.scrollIntoView({ alignToTop: true, behavior: 'smooth' })
  //     }
  //   }
  // }

  _toggleActive(e) {
    const { activeIndex } = this.state;
    const index = Number(e.target.dataset.index);

    const isSameIndex = activeIndex === index
    const el = typeof document !== 'undefined'
      ? document.querySelector('#content-' + index)
      : undefined

    if (!el) {
      return false
    }

    this.setState({
      activeIndex: isSameIndex ? undefined : index,
    })
  }

  _handleOnClickClose(e) {
    e.preventDefault()

    this.setState({
      activeIndex: undefined,
    })
  }

  render() {
    const { activeIndex } = this.state;

    return (
        <div className="static-page wrapper with-fixed-header">
          <h1 className="h1">Privacy Policy</h1>

          <div className="accordion" id="accordion2">
            <div className="accordion-group">
              <div className="accordion-heading">
                <h2 className="accordion-toggle question h2" onClick={this._toggleActive} data-index="1">1. Introduction</h2>
              </div>
              <div className={`accordion-body collapse ${activeIndex === 1 ? 'in' : ''}`}>
                <div className='accordion-inner' id='content-1'>
                  <p className="p">Hi! This section will help you better understand how Nerdify collects, stores, processes and uses your personal information. Since privacy laws are constantly changing, the Privacy Policy section can be occasionally updated. The last update of the current version of our Privacy Policy was made on May 25, 2018 and will apply to any use of the Nerdify website and services starting May 25, 2018 onwards. At the same time, this Privacy Policy does not apply to any personal data that you share with the third parties.</p>
                  <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
                </div>
              </div>
            </div>

            <div className="accordion-group">
              <div className="accordion-heading">
                <h2 className="accordion-toggle question h2" onClick={this._toggleActive} data-index="2">2. Collection of Information</h2>
              </div>
              <div className={`accordion-body collapse ${activeIndex === 2 ? 'in' : ''}`}>
                <div className='accordion-inner' id='content-2'>
                  <p className="p">At Nerdify, we collect such personal information as your phone number because this data is crucial for providing our services to you. In case you prefer not to share this information with us, you can still check our website but you will not be able to get access to some services.</p>
                  <p className="p">The data we collect, store, process, and use can be divided into three subtypes: the data you decide to provide to us directly, the information we collect automatically, and the information we receive from other sources.</p>

                  <h3 className="h3">2.1. Information you provide directly</h3>
                  <p className="p">When you fill in the form or contact us directly through any chosen way of communication, you may specify personal details, for example, your email address, phone number, level of education, photos and any other information you decide to share with us.</p>

                  <h3 className="h3">2.2. Information we collect automatically</h3>
                  <p className="p">When you use Nerdify website, the following information is collected automatically:</p>
                  <ul className='ul'>
                    <li>Data about your desktop or mobile device you use to access the website, such as device hardware model, operating system version, and screen resolution.</li>
                    <li>Log information that includes your IP address, browser version, time, duration, and frequency of your visits to the website as well as pages you accessed during them.</li>
                  </ul>
                  <p className="p">The above-mentioned information is gathered with the help of cookies, pixels, and other tracking devices.</p>

                  <h3 className="h3">2.3. Cookie policy</h3>
                  <p className="p">The following list presents different kinds of cookies that our Services use and explains why we use them.</p>
                  <ul className='ul'>
                    <li>Essentials. Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</li>
                    <li>Marketing. Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</li>
                    <li>Performance. Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</li>
                    <li>Functional. These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</li>
                  </ul>

                  <h3 className="h3">2.4. Information we obtain from other sources</h3>
                  <p className="p">We may collect information about you from the third-party services such as social media websites only in case you gave those third-party services your consent to share this information with us. It is vital for providing our free and paid services to you.</p>

                  <h3 className="h3">2.5. Sensitive information</h3>
                  <p className="p">We do not gather any sensitive information about you. We strongly encourage you not to provide this data to us. Sensitive information can include your race, ethnicity, gender, health condition, philosophical and religious beliefs, political views, and the like. If you decide to give this information to other website users, Nerdify does not take any responsibility for it.</p>

                  <h3 className="h3">2.6. Children online privacy protection</h3>
                  <p className="p">Nerdify is not meant for usage by individuals under eighteen (18) years old. We will not intentionally gather, save and use information about such individuals.</p>

                  <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
                </div>
              </div>
            </div>

            <div className="accordion-group">
              <div className="accordion-heading">
                <h2 className="accordion-toggle question h2" onClick={this._toggleActive} data-index="3">3. Use of information</h2>
              </div>
              <div className={`accordion-body collapse ${activeIndex === 3 ? 'in' : ''}`}>
                <div className='accordion-inner' id='content-3'>
                  <p className="p">The information you share with us is used in order to improve, operate and protect Nerdify as well as to communicate with you and give you access to our paid and free services.</p>

                  <h3 className="h3">3.1. Using your data for Nerdify website operation</h3>
                  <p className="p">Improving Nerdify implies the following:</p>
                  <ul className='ul'>
                    <li>Increase of the website performance;</li>
                    <li>Processing transactions;</li>
                    <li>Development of the new products and services;</li>
                    <li>Monitoring and analysis of your activity to create the best experience for all website users;</li>
                    <li>Detection, prevention, and investigation of the fraudulent cases;</li>
                    <li>Prevention of website abuse and activities that are illegal or violate our Terms of Use, Privacy Policy, and other policies;</li>
                    <li>Protection of the rights and safety of all website users;</li>
                    <li>Conduction of the statistical analysis and performance of other administrative tasks.</li>
                  </ul>

                  <h3 className="h3">3.2. Using your data for communicating with you</h3>
                  <p className="p">Your personal data is needed in order to:</p>
                  <ul className='ul'>
                    <li>Provide customer service and technical support;</li>
                    <li>Answer your questions;</li>
                    <li>Update you with the recent news or alerts;</li>
                    <li>Organize surveys.</li>
                  </ul>

                  <h3 className="h3">3.3. Advertising and other uses</h3>
                  <p className="p">Your personal information can also be used for advertising purposes, such as:</p>
                  <ul className='ul'>
                    <li>Providing you with the personalized content and website features;</li>
                    <li>Promoting the content that may be applicable to you;</li>
                    <li>Providing personalized advertising through third-party platforms.</li>
                  </ul>

                  <h3 className="h3">3.4. Legal basis for processing your information</h3>
                  <p className="p">Your personal information is gathered and used legally. The legal basis for it depends on the context in which we receive your data.</p>
                  <ul className='ul'>
                    <li>The legal basis for storing your phone number is that this information is inevitable for providing you with the free and paid website features;</li>
                    <li>Collection and processing information about you might be needed in order to comply with the legal requirements such as fraud prevention, network improvement and database safety, to protect your interests and interests and privacy of other website users.</li>
                    <li>Gathering your personal information is necessary for improving your experience with the website</li>
                  </ul>

                  <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
                </div>
              </div>
            </div>

            <div className="accordion-group">
              <div className="accordion-heading">
                <h2 className="accordion-toggle question h2" onClick={this._toggleActive} data-index="4">4. Sharing information and Affiliate Disclosure</h2>
              </div>
              <div className={`accordion-body collapse ${activeIndex === 4 ? 'in' : ''}`}>
                <div className='accordion-inner' id='content-4'>
                  <p className="p">Information about you can be shared with our third-party service providers and
                    authorities in order to comply with the legal obligations and to protect our legal rights and
                    property.</p>
                  <p className="p">Data obtained through the short code program will not be shared with any
                    third-parties for their marketing reasons/purposes.</p>

                  <h3 className="h3">4.1. Sharing information with the service providers</h3>
                  <p className="p">Third-party services are used for website improvement and effective performance.
                    These services help us operate the payments, send emails and text messages, host our data, show ads
                    across the Internet, and give technical support. We give these services the right to use your
                    personal information for their intended purposes, however, we make sure that this usage does not go
                    beyond our authorization.</p>

                  <h3 className="h3">4.2. Transfer of information to other countries</h3>
                  <p className="p">Your personal information may be transferred to other countries as these countries
                    may have data protection regulation laws that vary from your home country. Data protection measures
                    that apply to such transfers have been taken to provide the correspondence with the requirements of
                    this Privacy Policy.</p>

                  <h3 className="h3">4.3. Sharing information for security reasons</h3>
                  <p className="p">Your personal information is stored securely and it is protected against illegal
                    usage. Our employees are trained in correspondence with this and other data protection policies.
                    Only authorized individuals have access to use your data. Moreover, we constantly monitor our
                    hardware and software for potential vulnerabilities.</p>

                  <h3 className="h3">4.4. Affiliate Disclosure</h3>
                  <p className="p">Some links in our email campaign may be affiliate links. Affiliate links are
                    developed to provide a means for other sites to earn an advertising fees. The final cost will be the
                    same whether you use the affiliate link. But as a member of an affiliate program, Nerdify will earn
                    a commission if you purchase any service through an affiliate link.</p>

                  <h3 className="h3">4.5. Third-party Partners and Privacy</h3>
                  <p className="p">In order to display ads online, to get information regarding the Service usage and
                    the effectiveness of our advertisements and promotions, Nerdify collaborates with third-party
                    partners such as online advertising platforms and analytics providers. These third-parties might
                    implement and use their own tracking tools, such as cookies and web tags, on your devices, as well
                    as to gather or have access to Device ID/Information and other data about you, some of which might
                    qualify as personal data according to specific state regulations. Some of these third-parties can
                    accumulate personal data over time during your engagement with the Service or other online
                    platforms. We might disclose information to third-party partners, typically in an aggregated,
                    depersonalized, or pseudonymized form, along with Device ID/Information and location details, with
                    the intention of presenting you with more relevant advertisements both on and off Service.</p>
                  <div className="p">
                    If you would like to opt-out of third-party advertising platforms and analytics providers, you have
                    several options for that:
                    <ul className='ul'>
                      <li>There are technical specification in your browser settings that you can use to automatically
                      inform websites of your privacy preferences. Learn more about and set up <a className="st-link" href="https://globalprivacycontrol.org/#about" target="_blank" rel="noopener noreferrer">Global Privacy Controls here</a>.</li>
                      <li>We use Google Analytics, which use cookies and similar technologies to collect and analyze
                      information about the use of the Services. Learn about <a className="st-link" href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Google's practices</a>, and opt out of them by downloading the <a className="st-link" href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics opt-out browser add-on</a>.</li>
                      <li>There are settings that request that a web application (web browser) disable its tracking of an
                        individual user. Learn more about and set up <a className="st-link" href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer">Do Not Track here</a>.
                      </li>
                      <li>There are mobile device settings that allow you to disable tracking advertising and/or analytics. Also there is the ability for mobile users to register at <a className="st-link" href="https://youradchoices.com/appchoices" target="_blank" rel="noopener noreferrer">AppChoices</a> for their intent not to receive targeted advertising.</li>
                    </ul>
                  </div>
                  <p className="p">
                    Kindly be aware that utilizing these options to opt out does not exempt you from receiving advertisements. You will still receive generic ads during your online activities.
                  </p>

                  <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
                </div>
              </div>
            </div>

            <div className="accordion-group">
              <div className="accordion-heading">
                <h2 className="accordion-toggle question h2" onClick={this._toggleActive} data-index="5">5. Your choices
                  and data rights</h2>
              </div>
              <div className={`accordion-body collapse ${activeIndex === 5 ? 'in' : ''}`}>
                <div className='accordion-inner' id='content-5'>
                  <p className="p">Your data rights consist of:</p>
                  <ul className='ul'>
                    <li>The right to check, modify and request to delete your information. You can do it by contacting us at <a className="st-link" href="mailto:privacy@mynerdify.com">privacy@mynerdify.com</a>.</li>
                    <li>The right to restrict the processing of your data. If you want to be excluded from the segmentation for communication and other purposes, please, message us at <a className="st-link" href="mailto:privacy@mynerdify.com">privacy@mynerdify.com</a>.</li>
                    <li>The right to transfer your information. If you want to review all the information we have about you, contact us at <a className="st-link" href="mailto:privacy@mynerdify.com">privacy@mynerdify.com</a> and we will send you a data file.</li>
                    <li>The right to unsubscribe from marketing notifications. You can do that by clicking the unsubscribe link in emails you receive from us or by messaging us directly to <a className="st-link" href="mailto:privacy@mynerdify.com">privacy@mynerdify.com</a>.</li>
                    <li>The right to withdraw your permission to process your personal information at any time. You can do it by contacting us at <a className="st-link" href="mailto:privacy@mynerdify.com">privacy@mynerdify.com</a>.</li>
                    <li>The right to complain to your local Data Protection Authority (DPA) about our usage of your data.</li>
                  </ul>
                  <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
                </div>
              </div>
            </div>

          </div>
      </div>
    )
  }
}
