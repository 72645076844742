import React, { Fragment, Component, useCallback } from 'react';
import {
  Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'


import Auth from './api/auth';
import history from './utils/history';

import Login from './containers/Login';
import LoginWithPhone from './containers/LoginWithPhone';
import LoginWithEmail from './containers/LoginWithEmail';
import OrderSelectJobType from './containers/OrderSelectJobType';
import Order from './containers/Order';
import Orders from './pages/Orders';
import ContactUs from './containers/ContactUs';
import RefundPolicy from './containers/RefundPolicy';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import NerdifyPrime from './containers/NerdifyPrime';
import AuthSession from './containers/AuthSession';
import PaymentRedirect from './containers/AuthSession/paymentRedirect';
import AuthLogout from './containers/AuthLogout';
import MessageWasSent from './containers/MessageWasSent';
import MyInfo from './containers/MyInfo';
import NotFound from './containers/NotFound';
import RefCabinet from './containers/RefCabinet';
import Files from './containers/Files';
import NerdifySubscription from "./containers/NerdifySubscription";
import CookiePopUp from './components/CookiePopUp';

import { IconMap } from './components/SvgIcons'

import HeaderMain from './components/Header/HeaderMain'
import HeaderLogin from './components/Header/HeaderLogin'
import HeaderLoginBack from './components/Header/HeaderLoginBack'
import HeaderOrderStep from './components/Header/HeaderOrderStep'
import { FooterMain, FooterLogin } from './components/Footer'
// import FooterNerdify from './components/FooterNerdify'
import BackToTopButton from './components/BackToTopButton'

//import { useCfpAuthRoutes } from '@verdaccio/cfp';
import { useCfpAuthRoutes } from './hooks';
import { userApplyToken } from "./reducers/user";

import './sass/app.css';

const saveQueryToStorage = () => {
  const { pathname, search } = window.location;

  localStorage.setItem('query', JSON.stringify({
    path: pathname,
    search: search.substring(1)
  }))
}

export const AuthorizedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.isAuthenticated ? <Fragment>
      <Component {...props} />
    </Fragment> : (
        <Redirect to={{
          pathname: '/login',
          state: {
            from: props.location.pathname
          }
        }} />
      )
  )} />
)

const UnauthorizedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !Auth.isAuthenticated ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
      )
  )} />
)

class ScrollToTop extends Component {
  componentDidMount() {
    saveQueryToStorage();
  }

  componentDidUpdate() {
    saveQueryToStorage();
    //window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

const App = () => {
  const cfpAuthRoutes = useCfpAuthRoutes({
    loginUser: useCallback((data) => userApplyToken(data), []),
  });

  return (
    <Router history={history}>
      <ScrollToTop>
        <div className="root">
          <IconMap />
          <div className="main-body">
            <Switch>
              <Route exact path="/neworder" render={() => <HeaderOrderStep current={1} />} />
              <Route exact path="/order/:orderId/jobtype" render={() => <HeaderOrderStep current={1} />} />
              <Route exact path="/order/:orderId" render={() => <HeaderOrderStep current={2} />} />
              <Route exact path="/order/:orderId/details" render={() => <HeaderOrderStep current={3} />} />
              <Route exact path="/neworder/:selectedJobType" render={() => <HeaderOrderStep current={2} />} />
              <Route exact path="/order/:orderId/extra-services" render={() => <HeaderOrderStep current={4} />} />
              <Route exact path="/orders" render={() => <HeaderMain type={"withSubscriptionBanner"} />} />
              <Route exact path="/login" component={HeaderLogin} />
              <Route exact path="/login/phone" component={HeaderLoginBack} />
              <Route exact path="/login/email" component={HeaderLoginBack} />
              <Route exact path="/contact-us" component={HeaderLoginBack} />
              <Route exact path="/refunds" component={HeaderMain} />
              <Route exact path="/terms" component={HeaderMain} />
              <Route exact path="/privacy-policy" component={HeaderMain} />
              <Route exact path="/order/:orderId/additional-services" />
              {/*<Route exact path="/nerdify-prime/:sessionId?" />*/}
              <Route exact path="/a/:shortKey/:orderId/:subscriptionId?" />
              <Route exact path="/files/:userId" />
              <Route exact path="/nerdify-subscription/:params?" />

              <Route component={HeaderMain} />
            </Switch>
            <Switch>
              <Route exact path="/message-was-sent" component={MessageWasSent} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/nerdify-prime" component={NerdifyPrime} />
              {/*<AuthorizedRoute exact path="/nerdify-prime/:sessionId?" component={NerdifyPrime} />*/}
              <Route exact path="/refunds" component={RefundPolicy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy-policy" component={Privacy} />
              <AuthorizedRoute exact path="/neworder" component={OrderSelectJobType} />
              <AuthorizedRoute exact path="/neworder/:selectedJobType" component={Order.Order} />
              <AuthorizedRoute exact path="/my-info" component={MyInfo} />
              <AuthorizedRoute path="/orders/filter/:paidStatus" component={Orders} />
              <AuthorizedRoute path="/orders" component={Orders} />

              <AuthorizedRoute exact path="/order/:orderId/jobtype" component={OrderSelectJobType} />
              <AuthorizedRoute exact path="/order/:orderId" component={Order.Order} />
              <AuthorizedRoute exact path="/order/:orderId/details" component={Order.OrderDetails} />
              <AuthorizedRoute exact path="/order/:orderId/extra-services" component={Order.ExtraServices} />
              <AuthorizedRoute exact path="/order/:orderId/additional-services" component={Order.AdditionalServices} />
              <AuthorizedRoute exact path="/order/:orderId/thank-you" component={Order.ThankYouPage} />
              <AuthorizedRoute exact path="/order/:orderId/payment" component={Order.Payment} />

              {/*<AuthorizedRoute exact path="/nerdify-subscription/:sessionId?" component={NerdifySubscription} />*/}
              <AuthorizedRoute exact path="/nerdify-subscription/:params?" component={NerdifySubscription} />

              <AuthorizedRoute exact path="/ref-cabinet" component={RefCabinet} />
              <UnauthorizedRoute exact path="/login" component={Login} />
              <UnauthorizedRoute exact path="/login/phone" component={LoginWithPhone} />
              <UnauthorizedRoute exact path="/login/email" component={LoginWithEmail} />
              <Route exact path="/logout" component={AuthLogout} />
              <Route exact path="/auth/:code" component={AuthSession} />
              <Route exact path="/authkey/:shortKey" component={AuthSession} />

              <Route exact path="/files/:userId" component={Files} />

              <cfpAuthRoutes.rating.route exact path={cfpAuthRoutes.rating.path} />
              <Route exact path="/a/:shortKey/:orderId/:subscriptionId?" component={PaymentRedirect} />
              <Redirect from="/" exact to="/orders" push={false} />

              <Route component={NotFound} />
            </Switch>
          </div>
          <div className="overlay" />
          <Switch>
            <Route exact path="/login" component={FooterLogin} />
            <Route exact path="/login/phone" component={FooterLogin} />
            <Route exact path="/login/email" component={FooterLogin} />
            <Route exact path="/contact-us" component={FooterLogin} />
            <Route exact path="/refunds" component={FooterLogin} />
            <Route exact path="/terms" component={FooterLogin} />
            <Route exact path="/privacy-policy" component={FooterLogin} />
            {/* <Route exact path="/nerdify-prime/:sessionId?" component={FooterNerdify} /> */}
            {/*<Route exact path="/nerdify-prime/:sessionId?" component={FooterLogin} />*/}
            <Route exact path="/logout" component={FooterLogin} />
            <Route exact path="/auth/:code" component={FooterLogin} />
            <Route exact path="/authkey/:shortKey" component={FooterLogin} />
            <Route exact path="/a/:shortKey/:orderId/:subscriptionId?" />

            <Route exact path="/files/:userId" />

            <Route exact path="/nerdify-subscription/:params?" />

            <Route>
              <FooterMain type={'desktop'} />
            </Route>
          </Switch>
          <BackToTopButton />
        </div>
        {process.env.NODE_ENV === 'production' && <CookiePopUp />}
      </ScrollToTop>
    </Router>
  );
}

export default App;
