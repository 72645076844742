import React, { Component, Fragment, useCallback, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import classnames from "classnames";
import * as moment from "moment";
import ReactGA from 'react-ga';
import ButtonsGroup from './../../components/ButtonsGroup';
import LinkButton from './../../components/LinkButton';
import { updateOrder } from "./../../reducers/orders";
import CabinetBasicScreenEmpty from './../CabinetBasicScreenEmpty';
import ProgressTrackerPage from './../ProgressTrackerPage';
import Icon from "./../../components/SvgIcons";
//import convertDateTimeToDeadlineDaysHours from "./../../utils/convertDateTimeToDeadlineDaysHours";
import { getSizeText } from './../../utils/MeasureConvertor';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  fetchOrders,
  ordersInvalidate
} from './../../reducers/orders';

import { mapServices } from './../../config';
import { isNotActiveServicesAvailable } from "./../../utils/orderAvailableServices";

//import { useCfpShowRating } from '@verdaccio/cfp';
import { useCfpShowRating } from '../../hooks';

import "./order.css";

const withHooks = WrappedComponent => {
  const WithHooks = props => {

    useCfpShowRating({
      editOrder: useCallback((orderId, data) => updateOrder(orderId, data), []),
    });

    const mergeProps = { ...props }

    return <WrappedComponent {...mergeProps} />;
  };

  WithHooks.displayName = `withHooks(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return WithHooks;
};

const menu = [
  'active',
  'completed',
]

class OrderList extends Component {

  constructor() {
    super();
    this.state = {
      filter: 'active',
    }

    this.onClickMenu = this.onClickMenu.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onTimeout = this.onTimeout.bind(this);
    this.onClickToTips = this.onClickToTips.bind(this);
    this.onClickToAdditionalServices = this.onClickToAdditionalServices.bind(this);
  }

  componentDidMount() {
    this.props.load()
    const filterValue = sessionStorage.getItem('filter');
    this.setState({ filter: filterValue ? filterValue : 'active'});
  }

  nonPaidServicePrice(order) {
    let nonPaidServicePrice = 0;
    if (order.client_paid) {
      Object.keys(order.services).map(item => {
        const service = order.services[item];
        const isActive = service.active;
        const isAdded = service.added;
        const servicePrice = service.price;
        const isRrefunded = service.refunded;
        if (!isActive && isAdded && !isRrefunded) {
          nonPaidServicePrice += servicePrice;
          //console.log(order.id, order.number, isActive, isAdded, order.price, servicePrice, nonPaidServicePrice);
        }
      })
    }
    return nonPaidServicePrice;
  }

  orderMeasure(orderSize) {
    let orderMeasure = 'order-' + orderSize.split(" ").splice(-1);
    /* pages, minutes, hours, slides, words, sources */
    return orderMeasure
  }

  onClickMenu(e) {
    const {
      history,
    } = this.props;
    this.setState({ filter: e.target.dataset.name })
    sessionStorage.setItem('filter', e.target.dataset.name);
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onCopy(e) {
    const orderNumber = e.target.dataset.number;
    this.setState({ copied: { [orderNumber]: true } })
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.onTimeout, 1500);
  }

  onTimeout() {
    this.setState({ copied: false });
  }

  onClickToTips(orderId) {
    const {
      history,
    } = this.props;
    history.push(`/orders/${orderId}/tips`, { isFromTipsBtn: true })
  }

  onClickToAdditionalServices(orderId) {
    const {
      history,
    } = this.props;
    history.push(`/order/${orderId}/additional-services`)
  }

  render() {
    const { filter, copied } = this.state;
    const { orders, fetched } = this.props;
    const { paid, unpaid, count } = this.props.total;

    const { history } = this.props;

    if (!fetched)
      return null;

    if (count <= 0)
      return <CabinetBasicScreenEmpty />

    const isPremiumAddonsLabel = this.props?.user?.data?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';

    return (
      <div className="orderlist-page">
        <div className="wrapper">
          <div className="frame orderlist-frame">
            <div onClick={() => {
              ReactGA.event({
                category: 'Ref_cabinet_events',
                action: 'Ref_button_click',
                label: 'ref_button_click_orderlist_page'
              })
            }}>
            </div>
            <ButtonsGroup className="filters">
              <div className="filter-list">
                {/*<NavLink exact to="/orders" className="button filter-item">All/!*({this.props.total.all})*!/</NavLink>*/}
                {/*<NavLink exact to="/orders/filter/paid" className="button filter-item">Paid ({this.props.total.paid})</NavLink>*/}
                {/*<NavLink exact to="/orders/filter/unpaid" className="button filter-item">Unpaid ({this.props.total.unpaid})</NavLink>*/}
                {/* <NavLink exact to="/orders" className="filter-item">Active ({active})</NavLink>
                <NavLink exact to="/orders/filter/completed" className="filter-item">Completed ({active})</NavLink> */}
                {menu.map(m =>
                  <div
                    key={m}
                    data-name={m}
                    className={classnames('filter-item', { 'active': filter === m })}
                    onClick={this.onClickMenu}
                  >
                    {m} ({this.props.total[m]})
                  </div>
                )}
              </div>
            </ButtonsGroup>

            {this.props.paidStatus === 'paid' && !paid &&
              <ProgressTrackerPage>Once you get a Personal Nerd working on your task, you will be able to track its progress
            here :)</ProgressTrackerPage>
            }
            {this.props.paidStatus === 'unpaid' && !unpaid &&
              <ProgressTrackerPage>Once you create a new task for our Nerds, you will be able to see it here
            :)</ProgressTrackerPage>
            }

              {(!orders[filter] || orders[filter].length <= 0) &&
              <div className="orderlist-empty">
                <Icon className="svg-icon icon-ill" iconName="icon-ill" />
                <h5 className="orderlist-empty__title">No any <span>{filter}</span> tasks right now</h5>
                <Link className="orderlist__link desktop" to="/neworder">Hire a Nerd</Link>
                <p className="orderlist-empty__desc">Did you know that the more time you give to your Nerd the cheaper the price?</p>
              </div>
            }

            <ul className="orderlist">
              {orders && orders[filter] && orders[filter].map(order => {
                //console.log(order.client_status, order.number)
                const isOrderRefund = order.system_client_status.toLowerCase().indexOf('refund') + 1;
                const isOrderPartiallyRefund = isOrderRefund && order.price > order.refunded;
                const isCopied = copied && copied[order.number];

                const {
                  isCanShowChangeRateBtn,
                  isCanShowTipsBtn
                } = order

                const serviceType = order.service_type === 'Essay (editing)' ? 'Editing' //order.isPromo ? `Promo (${order.service_type})`
                  : order.service_type === 'Essay (writing)' ? 'Writing'
                    : order.service_type === 'Finding sources' ? 'Sources'
                      : order.service_type === 'Proofreading & formatting' ? 'Proofreading'
                        : order.service_type === 'Creating outline' ? 'Outline'
                          : order.service_type === 'Paper formatting' ? 'Formatting'
                            : order.service_type;

                const serviceTypeTitle = serviceType === 'Other task' ? 'task'
                  : serviceType === 'Presentations' ? 'presentation'
                    : serviceType;

                let isOrderServiceListToShow = false;
                {
                  Object.keys(mapServices).map(item => {
                    const service = order.services[item];
                    if (service.active || service.added || service.refunded) isOrderServiceListToShow = true;
                  })
                }

                return (
                  <li className={classnames("orderlist-item", isOrderRefund ? "refunded" : order.decoStatus)} key={order.id}>

                    <div className="orderlist-item-wrapper">
                      {order.client_paid ?
                        isOrderRefund ?
                          <div className="datetime-label">Refunded on:</div>
                          : order.system_client_status === 'Completed' || order.client_status === 'Revision sent' ?
                            <div className="datetime-label highlight">Completed on:</div>
                            : order.client_status === 'On revision' || order.client_status === 'Revision requested' ?
                              <div className="datetime-label">Expected on:</div>
                              : <div className="datetime-label">Expected on:</div> // in progress
                        : // not paid
                        <div className="datetime-label">Created on:</div>
                      }
                      <div className="order-type">{serviceType}</div>
                    </div>

                    <div className="orderlist-item-wrapper">
                      {order.client_paid ?
                        isOrderRefund ?
                          <div className="order-datetime">{moment(order.refund_accept_at).format('D MMM YYYY, h A')}</div>
                          : order.system_client_status === 'Completed' ?
                            <div className="order-datetime">{moment(order.force_review_at).format('D MMM YYYY, h A')}</div>
                            : order.client_status === 'Revision sent' ?
                              <div className="order-datetime">{order.writer_revision_complete_at ? moment(order.writer_revision_complete_at).format('D MMM YYYY, h A') : moment(order.force_review_at).format('D MMM YYYY, h A')}</div>
                              : order.client_status === 'On revision' || order.client_status === 'Revision requested' ?
                                <div className="order-datetime">{moment(order.revision_deadline).format('D MMM YYYY, h A')}</div>
                                : <div className="order-datetime">{moment(order.deadline).format('D MMM YYYY, h A')}</div> // in progress
                        : // not paid
                        <div className="order-datetime">{moment(order.created_at).format('D MMM YYYY, h A')}</div>
                      }

                      <div className="order-number-wrap mobile">
                        <CopyToClipboard text={order.number}>
                          <div className="order-number" onClick={this.onCopy} data-number={order.number}>{order.number}</div>
                        </CopyToClipboard>
                        <span className={classnames("order-number-copy", { "active": isCopied })}>Copied</span>
                      </div>
                      <div className="desktop">
                        <div className="order-number">{order.number}</div>
                      </div>

                    </div>

                    <div className="order-title">
                      {order.title && order.title !== serviceTypeTitle.toLowerCase()
                        ? order.title
                        : order.subject ? order.subject : serviceType
                      }
                    </div>

                      <div className="order-size"> {/*className={classnames("order-size", order.size.split(" ").splice(-1))}*/}
                        <div className="order-size-list">
                          {!order.is_complex && this.orderMeasure(order.size) &&
                          <div className="order-size-item">
                            <Icon className="svg-icon" iconName={this.orderMeasure(order.size)} />
                            <span className="order-size-msg">{getSizeText(order.size)}</span>
                          </div>
                          }
                          {order.is_complex && order.extras && order.extras.length > 0 &&
                            order.extras.map(o =>
                              (<div key={o.id} className="order-size-item">
                                {this.orderMeasure(o.size) &&
                                  <Fragment>
                                    <Icon className="svg-icon" iconName={this.orderMeasure(o.size)} />
                                    <span className="order-size-msg">{getSizeText(o.size)}</span>
                                  </Fragment>
                                }
                              </div>)
                            )
                          }
                        </div>
                      </div>

                    {(order.client_paid && !isOrderRefund && isNotActiveServicesAvailable(order)) || isOrderServiceListToShow ?
                      <div className="order-services">

                        {order.client_paid && !isOrderRefund && isNotActiveServicesAvailable(order) && !isPremiumAddonsLabel ?
                          <button className="order-service-link" onClick={() => this.onClickToAdditionalServices(order.id)}>
                            <Icon className="svg-icon" iconName="plus-round-icon2" />
                            <span className="link-msg">Additional services</span>
                          </button>
/*
                          <Link className="order-service-link" to={`/order/${order.id}/additional-services`}>
                            <Icon className="svg-icon" iconName="plus-round-icon2" />
                            <span className="link-msg">Additional services</span>
                          </Link>
*/
                          : null
                        }
                        {isOrderServiceListToShow &&
                          <ul className="order-service-list">
                            {Object.keys(mapServices).map(item => {
                              const service = order.services[item];
                              const isServiceActive = service.active;
                              const isServiceAdded = service.added;
                              const isServiceRefunded = service.refunded;
                              const serviceClass = mapServices[item].title.toLowerCase().replace(/ /g, "-")
                              //const isFree = !service.price;
                              return (
                                isServiceRefunded ?
                                  <li className={classnames("order-service-item", "refunded", serviceClass)} key={`order-service-item-${item}`}>
                                    <Icon className="svg-icon" iconName="service-refund-icon" />
                                    <span className="service-title">{mapServices[item].title}</span>
                                    <span className="refunded-service-label">refunded</span>
                                  </li>
                                  :
                                  isServiceActive ? // order.client_paid && isActive ?
                                    <li className={classnames("order-service-item", "active", serviceClass)} key={`order-service-item-${item}`}>
                                      <Icon className="svg-icon" iconName="check-service-icon" />
                                      <span className="service-title">{mapServices[item].title}</span>
                                    </li>
                                    : isServiceAdded ? // !order.client_paid && isAdded ?
                                      <li className={classnames("order-service-item", "added", serviceClass)} key={`order-service-item-${item}`}>
                                        <Icon className="svg-icon" iconName="check-service-icon" />
                                        <span className="service-title">{mapServices[item].title}</span>
                                      </li>
                                      : null
                              )
                            })}
                          </ul>
                        }
                      </div>
                      : null
                    }

                    <div className="orderlist-item-wrapper bottom">

                      <div className="orderlist-item-subwrapper1">
                        {order.price > 0 ?
                          isOrderPartiallyRefund ?
                            (
                              <Fragment>
                                <div className="order-price-block">
                                  <div className={classnames("order-price", (order.client_paid - order.refunded) >= 1000 && "tiny")}>
                                    ${(order.client_paid - order.refunded).toFixed(2)}
                                  </div>
                                  <div className="order-refunded">${order.client_paid.toFixed(2)}</div>
                                </div>
                                <div className="refunded-label">Partially refunded</div>
                              </Fragment>
                            )
                            :
                            isOrderRefund ?
                              (
                                <Fragment>
                                  <div className={classnames("order-price", "refunded", order.client_paid >= 1000 && "tiny")}>
                                    ${order.client_paid.toFixed(2)}
                                  </div>
                                  <div className="refunded-label">Refunded</div>
                                </Fragment>
                              )
                              :
                              (
                                <Fragment>
                                  <div className={classnames("order-price", order.price >= 1000 && "tiny")}>
                                    ${(order.price - this.nonPaidServicePrice(order)).toFixed(2)}
                                  </div>

                                  {!isCanShowTipsBtn &&
                                    (
                                      <Fragment>
                                        {order.total_tips_amount &&
                                          <div className="tip-label">Tip: ${order.total_tips_amount.toFixed(2)}</div>
                                        }
                                      </Fragment>
                                    )}

                                  {isCanShowTipsBtn &&
                                    (
                                      <Fragment>

                                        <button className="tip-link" type="button" onClick={() => this.onClickToTips(order.id)}>
                                          <Icon className="svg-icon" iconName="tip-icon" />
                                          <div className="tip-link-msg">Tip nerd</div>
                                        </button>

                                      </Fragment>
                                    )}

                                </Fragment>
                              )
                          : null
                        }
                      </div>

                      <div className="orderlist-item-subwrapper2">
                        {order.client_paid && !isOrderRefund &&
                          (order.client_status === 'Nerd found' || order.client_status === 'In progress' || order.client_status === 'Quality check' || order.client_status === 'Final touches') ?
                          (
                            <div className="order-label in-progress">{order.client_status}</div>
                          )
                          : (order.client_status === 'On revision' || order.client_status === 'Revision requested') && !isOrderRefund ?
                            (
                              <div className="order-label on-revision">On revision</div>
                            )
                            : (order.client_status === 'Sent' || order.client_status === 'Revision sent') ?
                              order.mark === 0 && !isOrderRefund ?
                                (
                                  <Link className="rate-task-btn" to={`/orders/${order.id}/rating`}>
                                    <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                    <span className="rate-task-btn-msg">Rate the task</span>
                                  </Link>
                                )
                                : order.mark > 0 &&
                                (
                                  <Fragment>
                                    <ul className={`rating-stars mark${order.mark}`}>
                                      <li className="rating-star-item">
                                        <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                      </li>
                                      <li className="rating-star-item">
                                        <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                      </li>
                                      <li className="rating-star-item">
                                        <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                      </li>
                                      <li className="rating-star-item">
                                        <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                      </li>
                                      <li className="rating-star-item">
                                        <Icon className="svg-icon" iconName="star-empty-full-icon" />
                                      </li>
                                    </ul>

                                  </Fragment>
                                )
                              : null
                        }

                        {isCanShowChangeRateBtn && <Link className="change-rating-link" to={`/orders/${order.id}/rating/change`}>Change rating</Link>}

                        {!order.client_paid && !order.isPromo && order.type !== "Complex" && ( // !order.client_paid
                          <Link className="edit-pay-button" to={`/order/${order.id}`}>Edit and pay</Link>
                        )}
                      </div>
                    </div>

                  </li>
                )
              })}
            </ul>





            {/*
          <ul className="order-list">

            {orders.map(order => {
              //console.log(order.client_status, order.number)

              return (
                <li className={classnames("order-list-item", (order.system_client_status.toLowerCase().indexOf('refund') + 1) ? "refunded" : order.decoStatus)} key={order.id}>
                  <div className="order-list-item-left">
                    <div className="order-list-item-deadline">
                      <div className="order-list-item-deadline-date">{moment(order.deadline).format("D")}</div>
                      <div className="order-list-item-deadline-month">{moment(order.deadline).format("MMMM")}</div>
                      {moment(order.deadline).format("YYYY") !== moment(new Date()).format("YYYY") && (
                        <div className="order-list-item-deadline-year">{moment(order.deadline).format("YYYY")}</div>
                      )
                      }
                    </div>
                    {((order.client_paid && order.deadlineInHours) || (!order.client_paid && order.deadlineInHours)) && (
                      <div className="order-list-item-deadline-expected">
                        <p>Expected in:</p>
                        {convertDateTimeToDeadlineDaysHours(order.deadline).days > 0 && (
                          <div>{convertDateTimeToDeadlineDaysHours(order.deadline).daysStr}</div>
                        )}
                        {convertDateTimeToDeadlineDaysHours(order.deadline).hours > 0 && (
                          <div>{convertDateTimeToDeadlineDaysHours(order.deadline).hoursStr}</div>
                        )}
                      </div>
                    )}
                    {order.price > 0 && (
                      <div className={classnames("order-list-item-price", order.price >= 1000 && "tiny")}>${(order.price - this.nonPaidServicePrice(order)).toFixed(2)}</div>
                    )}
                  </div>
                  <div className="order-list-item-right">
                    <div className="order-list-item-type">
                      <ServiceType
                        isPromo={order.isPromo}
                        serviceType={order.service_type}
                      />
                    </div>
                    <div className="order-list-item-number">{order.number}</div>
                    <div className="order-list-item-title">{order.subject}</div>
                    <div className="order-list-item-size">{order.size}</div>

                    {order.client_paid && !(order.system_client_status.toLowerCase().indexOf('refund') + 1) && isNotActiveServicesAvailable(order) ?
                      <Link className="order-list-additional-services-link" to={`/order/${order.id}/additional-services`}>
                        <Icon className="svg-icon" iconName="plus-round-icon" />
                        <span className="link-msg">Additional services</span>
                      </Link>
                      :
                      null
                    }

                    <ul className="order-extra-services-list">
                      {Object.keys(mapServices).map(item => {
                        const service = order.services[item];
                        const isActive = service.active;
                        const isAdded = service.added;
                        const isRefunded = service.refunded;
                        //const isFree = !service.price;
                        return (
                          isRefunded ?
                            <li className="order-extra-services-item" key={`order-extra-services-item-${item}`}>
                              <Icon className="svg-icon" iconName="check-added-icon" />
                              <span className="service-title refunded">{mapServices[item].title}</span>
                              <span className="refunded-label">refunded</span>
                            </li>
                            :
                            order.client_paid && isActive ?
                              <li className="order-extra-services-item" key={`order-extra-services-item-${item}`}>
                                <Icon className="svg-icon" iconName="check-active-icon" />
                                <span className="service-title">{mapServices[item].title}</span>
                              </li>
                              : !order.client_paid && isAdded ?
                                <li className="order-extra-services-item" key={`order-extra-services-item-${item}`}>
                                  <Icon className="svg-icon" iconName="check-added-icon" />
                                  <span className="service-title">{mapServices[item].title}</span>
                                </li>
                                : null
                        )
                      })}
                    </ul>

                    {order.client_paid ?
                      (order.client_status === 'Sent' || order.client_status === 'Revision sent') && !(order.system_client_status.toLowerCase().indexOf('refund') + 1) ?
                        order.mark === 0 ?
                          (
                            <div className="rating-block">
                              <Link className="rate-btn" to={`/orders/${order.id}/rating`}>
                                <span className="inner-wrapper">
                                  <Icon className="svg-icon" iconName="star-icon" />
                                  <span className="rate-btn-msg">Rate the task</span>
                                </span>
                              </Link>
                            </div>
                          )
                          : (
                            <div className="rating-block">
                              <div className={`your-rating mark${order.mark}`}>
                                <div className="your-rating-msg">Your rating:</div>
                                <ul className="your-rating-stars">
                                  <li className="rate-star-item">
                                    <Icon className="svg-icon" iconName="star-icon" />
                                  </li>
                                  <li className="rate-star-item">
                                    <Icon className="svg-icon" iconName="star-icon" />
                                  </li>
                                  <li className="rate-star-item">
                                    <Icon className="svg-icon" iconName="star-icon" />
                                  </li>
                                  <li className="rate-star-item">
                                    <Icon className="svg-icon" iconName="star-icon" />
                                  </li>
                                  <li className="rate-star-item">
                                    <Icon className="svg-icon" iconName="star-icon" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )
                        : (
                          <div
                            className={classnames("order-list-item-status", order.client_status.toLowerCase().replace(" ", "-"))}>
                            {(order.system_client_status.toLowerCase().indexOf('refund') + 1) ? 'Refunded' : order.client_status}
                          </div>
                        )
                      : (
                        !order.isPromo && <Link className="order-list-item-button" to={`/order/${order.id}`}>Edit and pay</Link>
                      )
                    }
                  </div>
                </li>
              )
            })}
          </ul>
*/}
          </div>
          <LinkButton path="/neworder">Hire a Nerd</LinkButton>
        </div>
      </div >
    )
  }
}

const filters = {
  'all': order => order,
  'paid': order => order.boolPaidStatus,
  'unpaid': order => !order.boolPaidStatus && new Date(order.deadline) > new Date(),

  'active': order => (!order.boolPaidStatus && new Date(order.deadline) > new Date()) || (order.mark === 0 && !(order.system_client_status.toLowerCase().indexOf('refund') + 1)) || order.client_status === 'On revision' || order.client_status === 'Revision requested',
  'completed': order => order.boolPaidStatus && ((order.mark !== 0 && order.client_status !== 'On revision' && order.client_status !== 'Revision requested') || order.system_client_status.toLowerCase().indexOf('refund') + 1),
}

// const getOrderList = (orders, filter) => ((filters[filter] && orders.filter(filters[filter])) || orders.filter(filters['active']));
// const getOrderList = (orders, filter) => orders

export default withHooks(connect(
  (state, ownProps) => {
    const { user } = state;
    const orders = Object.values(state.orders.data)
      .filter(order => !order.declined)
      .filter(order => order.price)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    /*
          .sort((a, b) => new Date(b.deadline) - new Date(a.deadline))
          .sort((a, b) => {
            if (a.boolPaidStatus > b.boolPaidStatus) return 1;
            if (a.boolPaidStatus < b.boolPaidStatus) return -1;
            if (a.boolInWorkStatus > b.boolInWorkStatus) return 1;
            if (a.boolInWorkStatus < b.boolInWorkStatus) return -1;
            return 0
          });
    */

    const isAuthenticated = state.user.isAuthenticated;

    return {
      user,
      fetched: state.orders.fetched,
      // orders: getOrderList(orders, ownProps.match.params.paidStatus),
      paidStatus: ownProps.match.params.paidStatus,
      isAuthenticated,
      orders: {
        active: orders.filter(filters.active) || [],
        completed: orders.filter(filters.completed) || []
      },
      total: {
        count: orders.length,
        paid: orders.filter(filters.paid).length,
        unpaid: orders.filter(filters.unpaid).length,
        active: orders.filter(filters.active).length,
        completed: orders.filter(filters.completed).length
      }
    }
  },
  (dispatch, ownProps) => {
    return {
      load: () => {
        dispatch(ordersInvalidate());
        dispatch(fetchOrders());
      }
    }
  },
)(OrderList))
