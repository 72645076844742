import {
    jobTypes,
    pagesRange,
    slidesRange,
    wordsRange,
    hoursRange,
    minutesRange,
    deadlinesInHours,
    deadlinesLabels,
    defaultDeadlineInHours,
} from './../config.js'

const initState = {
    jobTypes,
    pagesRange,
    slidesRange,
    wordsRange,
    hoursRange,
    minutesRange,
    deadlinesInHours,
    deadlinesLabels,
    defaultDeadlineInHours,
}

export default (state=initState, action) => state
