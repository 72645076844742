import React, { Component } from "react"
import { connect } from 'react-redux'
import classnames from "classnames"
import InputMask from 'react-input-mask'
import Icon from "./../SvgIcons"

class PhoneInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            currentCountry: 'usa',
            currentMask: '+1 999 999 9999',
        }
        this.onChange = this.onChange.bind(this)
    }

    makeOpen() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    setCountry(countryName) {
      if (this.state.currentCountry !== countryName) {
        if (countryName === "canada") {
            this.setState({ currentMask: '+1 999 999 9999' })
        }
        else if (countryName === "australia") {
            this.setState({ currentMask: '+614 9999 9999' })
        }
        else {
            this.setState({ currentMask: '+1 999 999 9999' })
        }
        this.setState({ value: '' })
      }

      this.setState({ currentCountry: countryName });

      if (this.props.onChangeCountry && countryName) {
            this.props.onChangeCountry(countryName);
        }
    }

    onChange(value) {
        const { currentCountry } = this.state;

        this.setState({ value: value });

        if (this.props.onChange) {
            this.props.onChange(value.replace(/\s/g, ''), currentCountry);
        }
    }

    render() {
        return (
            <div className={classnames("phone-input-block", "clearfix")}>
                <div className="country-dropdown" onClick={this.makeOpen.bind(this)}>
                    <Icon className="svg-icon flag-icon" iconName={`flag-${this.state.currentCountry}`} />
                    <Icon className="svg-icon" iconName="down-arrow-icon" />

                    <ul className={classnames("open-country-dropdown", { isOpen: this.state.isOpen })}>
                        <li className={classnames({active : this.state.currentCountry === "usa"})} onClick={() => this.setCountry('usa')}>
                            <Icon className="svg-icon flag-icon" iconName="flag-usa" />
                            <span className="country-name">United States</span>
                            <Icon className="svg-icon" iconName="check-icon3" />
                        </li>
                        <li className={classnames({active : this.state.currentCountry === "canada"})} onClick={() => this.setCountry('canada')}>
                            <Icon className="svg-icon flag-icon" iconName="flag-canada" />
                            <span className="country-name">Canada</span>
                            <Icon className="svg-icon" iconName="check-icon3" />
                        </li>
                        <li className={classnames({active : this.state.currentCountry === "australia"})} onClick={() => this.setCountry('australia')}>
                            <Icon className="svg-icon flag-icon" iconName="flag-australia" />
                            <span className="country-name">Australia</span>
                            <Icon className="svg-icon" iconName="check-icon3" />
                        </li>
                    </ul>
                </div>
                <InputMask
                    className='phone-input'
                    type='tel'
                    onChange={(e) => this.onChange(e.target.value)}
                    value={this.state.value}
                    mask={this.state.currentMask}
                    maskChar="X"
                    alwaysShowMask="true"
                />
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
    }),
    (dispatch, ownProps) => ({
    })
)(PhoneInput)
