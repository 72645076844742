import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import classnames from 'classnames';
import Icon from './../SvgIcons';

import './index.css';

const Modal = (props, ref) => {
  const { children, className } = props;
  const isClose = typeof props.onClose === 'function';

  useEffect(() => {
    let bodyItem = document.getElementsByTagName('body')[0];
    bodyItem.classList.add('scroll-fix');

    return () => {
      let bodyItem = document.getElementsByTagName('body')[0];
      bodyItem.classList.remove('scroll-fix');
    };
  }, []);

  useImperativeHandle(ref, () => ({
    onClose() {
      let bodyItem = document.getElementsByTagName('body')[0];
      bodyItem.classList.remove('scroll-fix');
    }
  }));

  return (
    <div className={classnames('modal', className)}>
      <div className="modal__wrapper">
        <div className="modal__body">
          {isClose && (
            <button
              className="modal__close-btn"
              type="button"
              onClick={props.onClose}
            >
              <Icon className="svg-icon" iconName="close-round-icon" />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Modal);
