import { sendMessage } from './../api';
import { USER_CREATE_RECEIVE } from './../reducers/user';
import history from './../utils/history';

const actions = (actionsType, actionType, cb) => {
  if (actionsType === actionType) {
    cb();
  }
};

export const user = () => (next) => (action) => {
  if (action && action.error && action.error.status) {
    switch (action.error.status) {
      case 401:
      case 403: {
        console.info(
          `middleware.user not auth, logout on ${action.error.status}`,
          action
        );
        history.push('/logout');
      }
    }
  }

  const result = next(action);

  switch (action.type) {
    case USER_CREATE_RECEIVE: {
      const { after } = action;
      const { actionType } = after;

      actions('sendMessage', actionType, () => {
        const { message, country, phone, landing, landing_query } = after;

        const data = {
          phone,
          landing,
          landing_query,
        };
        sendMessage(data, message, country);
      });
    }
  }

  return result;
};
