import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { userFetchIfNeeded, saveUser, updateUser } from '../../reducers/user';
import Icon from '../../components/SvgIcons'
import RefShareBlock from '../../components/RefShareBlock';
import ReactGA from 'react-ga';

import { useA2hs } from '../../components/Pwa';

import "./ref-cabinet.css";

const withHooks = (WrappedComponent) => {
  const WithHooks = (props) => {
    const a2hs = useA2hs();
    const mergeProps = { ...props, a2hs }
    return <WrappedComponent {...mergeProps} />
  }

  WithHooks.displayName = `withHooks(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return WithHooks;
}

class RefCabinet extends Component {

  componentDidMount() {
    this.props.userFetchIfNeeded();

    ReactGA.event({
      category: 'Ref_cabinet_events',
      action: 'Ref_page_visit',
      label: 'ref_cab_page_visit'
    })
  }

  getWeeks(timestamp) {
    return Math.round(timestamp / 1000 / 60 / 60 / 24 / 7);
  }
  getWeeksLeft() {
    const checkpoint = new Date("May 6, 2018");
    const timestampNow = new Date().getTime();
    const timestampCheckpoint = checkpoint.getTime();
    return this.getWeeks(timestampNow) - this.getWeeks(timestampCheckpoint);
  }

  getUserRank(bonuses_earned) {
    if (bonuses_earned === 10) return 19516;
    else if (bonuses_earned >= 20 && bonuses_earned <= 120)
      return 19516 - bonuses_earned * 129.3 - 1910;
    else if (bonuses_earned >= 130 && bonuses_earned <= 310)
      return 2090 - bonuses_earned * 5.6 - 7;
    else if (bonuses_earned >= 320 && bonuses_earned <= 860)
      return 790 - bonuses_earned * 0.9 - 3;
    else if (bonuses_earned > 860)
      return 9;
    else return 34521
  }

  render() {
    if (!this.props.user.fetched) {
      return null
    }
    if (this.props.user?.data?.tags?.includes("NOreferral")) {
      return <Redirect to="/orders" push={false} />
    }

    var friends_invited = 0, friends_who_have_used = 0, total_bonuses_earned = 0;
    if (this.props.user.data.referral_details) {
      friends_invited = this.props.user.data.referral_details.count;
      friends_who_have_used = this.props.user.data.referral_details.count_with_orders;
      total_bonuses_earned = Math.round(this.props.user.data.referral_details.earn / 10) * 10;
    }
    const user_rank = Math.round(this.getUserRank(total_bonuses_earned));

    const {
      a2hs
    } = this.props;

    return (
      <>
        <div className="wrapper with-fixed-header">
          <div className="frame_ref">
            <div className="title title_ref"><span className="highlight">Get $20</span> for every friend you refer!</div>
            <div className="subtitle_ref">They’ll receive <span className="highlight">$20 OFF</span> their 1st order.</div>
            <div className="img-wrap_ref">
              <Icon className="svg-icon" iconName="ref-cabinet-img" />
            </div>
            <ul className="counter">
              <li className="counter__item counter__item_1">
                <div className="counter__item_number">{friends_invited}</div>
                <div className="counter__item_info">Friends invited</div>
              </li>
              <li className="counter__item counter__item_2">
                <div className="counter__item_number">{friends_who_have_used}</div>
                <div className="counter__item_info">Friends who have used Nerdify</div>
              </li>
              <li className="counter__item counter__item_3">
                <div className="counter__item_number">${total_bonuses_earned}</div>
                <div className="counter__item_info">Total bonuses earned</div>
              </li>
            </ul>

            <RefShareBlock />

            <div className="hiw__title">How it works</div>
            <ul className="hiw__list">
              <li className="hiw__list-item">
                <div className="hiw__list-item-step">Step 1</div>
                <Icon className="hiw__list-item-icon" iconName="hiw-img1" />
                <div className="hiw__list-item-msg"><span className="hiw__list-item-msg-highlight">Share</span> your personal referral link with friends</div>
              </li>
              <li className="hiw__list-item">
                <div className="hiw__list-item-step">Step 2</div>
                <Icon className="hiw__list-item-icon" iconName="hiw-img2" />
                <div className="hiw__list-item-msg">Your friends <span className="hiw__list-item-msg-highlight">get $20 OFF</span> their 1st order</div>
              </li>
              <li className="hiw__list-item">
                <div className="hiw__list-item-step">Step 3</div>
                <Icon className="hiw__list-item-icon" iconName="hiw-img3" />
                <div className="hiw__list-item-msg">You <span className="hiw__list-item-msg-highlight">get $20</span> bonus for every friend, who have used Nerdify</div>
              </li>
            </ul>

            <div className="ref-users-block">
              <div className="ref-users-title">Users who made the most out of referral program</div>
              <table className="ref-users-table">
                <thead>
                  <tr>
                    <td className="ref-users-table__header ref-users-table__col1">Rank</td>
                    <td className="ref-users-table__header ref-users-table__col2">Client email</td>
                    <td className="ref-users-table__header ref-users-table__col3">Bonuses earned</td>
                  </tr>
                </thead>
              </table>
              <table className="ref-users-table">
                <tbody>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">1</td>
                    <td className="ref-users-table__cell ref-users-table__col2">f****u@gmail.com</td>
                    <td className="ref-users-table__cell ref-users-table__col3">${8670 + 60 * Math.floor(this.getWeeksLeft() / 2)}</td>
                  </tr>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">2</td>
                    <td className="ref-users-table__cell ref-users-table__col2">r****6@yahoo.com</td>
                    <td className="ref-users-table__cell ref-users-table__col3">${4830 + 30 * Math.floor(this.getWeeksLeft() / 2)}</td>
                  </tr>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">3</td>
                    <td className="ref-users-table__cell ref-users-table__col2">5***t@gmail.com</td>
                    <td className="ref-users-table__cell ref-users-table__col3">${3360 + 30 * Math.floor(this.getWeeksLeft() / 2)}</td>
                  </tr>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">4</td>
                    <td className="ref-users-table__cell ref-users-table__col2">g***t@gmail.com</td>
                    <td className="ref-users-table__cell ref-users-table__col3">${3030 + 30 * Math.floor(this.getWeeksLeft() / 3)}</td>
                  </tr>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">5</td>
                    <td className="ref-users-table__cell ref-users-table__col2">d****h@gmail.com</td>
                    <td className="ref-users-table__cell ref-users-table__col3">${1950 + 30 * Math.floor(this.getWeeksLeft() / 4)}</td>
                  </tr>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">…</td>
                    <td className="ref-users-table__cell ref-users-table__col2">&nbsp;</td>
                    <td className="ref-users-table__cell ref-users-table__col3">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <div className="ref-users-table-highlight-wrapper">
                <table className="ref-users-table">
                  <tbody>
                    <tr>
                      <td className="ref-users-table__cell ref-users-table__col1 ref-users-table__cell_highlighted">{user_rank}</td>
                      <td className="ref-users-table__cell ref-users-table__col2 ref-users-table__cell_highlighted">You</td>
                      <td className="ref-users-table__cell ref-users-table__col3 ref-users-table__cell_highlighted">${total_bonuses_earned}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table className="ref-users-table">
                <tbody>
                  <tr>
                    <td className="ref-users-table__cell ref-users-table__col1">…</td>
                    <td className="ref-users-table__cell ref-users-table__col2">&nbsp;</td>
                    <td className="ref-users-table__cell ref-users-table__col3">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <RefShareBlock />
          </div>

        </div>

        <a2hs.component />
      </>
    )
  }
}


export default withHooks(
  connect(
    (state, ownProps) => ({
      user: state.user
    }),
    (dispatch, ownProps) => ({
      userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
      saveUser: (payload) => dispatch(saveUser(payload)),
      updateUser: (payload) => dispatch(updateUser(payload)),
    })
  )(RefCabinet)
)
