import React from "react";
import classnames from "classnames";
import "./buttons-group.css";

export default props => {
	const {className} = props;
	return (
		<div className={classnames("buttons-group", className)}>
		    {props.children}
		</div>
	);
}