import React from 'react';
import Tooltip from 'rc-tooltip';
import * as moment from 'moment'
import Slider from 'rc-slider';
import DayPicker from 'react-day-picker'
//import {deadlinesInHours, deadlinesLabels} from './../../config';
import convertDateTimeToDeadlineHours from './../../utils/convertDateTimeToDeadlineHours'

import 'rc-slider/assets/index.css';
import './select-deadline.css';
import 'react-day-picker/lib/style.css';


const SelectDeadline = props => {
  const {deadlinesInHours, deadlinesLabels} = props;

  const deadlinesLabelsMap = deadlinesLabels.reduce(
    (dict, value, i) => {
      dict[i] = value;
      return dict
    },
    {});

    const onDayPickerSelectedDay = (day, {disabled, selected}) => {
        if (disabled) {
            return;
        }
        const newDeadlineInHours = convertDateTimeToDeadlineHours(moment(day).hours(9));
        props.changeDeadline(newDeadlineInHours);
    };

  function normalizeDeadlineHours(hours) {
    for (let i = 0; i < deadlinesInHours.length; i ++) {
      if (deadlinesInHours[i] >= hours) {
        return deadlinesInHours[i];
      }
    }
    return deadlinesInHours[deadlinesInHours.length - 1];
  }

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={deadlinesLabelsMap[value]}
        visible={true}
        placement="top"
        key={index}
      >
        <Slider.Handle value={value} {...restProps} />
      </Tooltip>
    );
  };


    const normalizeDeadline = normalizeDeadlineHours(props.deadlineInHours);

    const maxValue = deadlinesInHours.length - 1;
    const showDayPicker = normalizeDeadline >= deadlinesInHours[maxValue];
    const defaultValue = deadlinesInHours.indexOf(normalizeDeadline);
    return (
      <div>
        <div className="order-block-item">
            <h3 className="h3">I need it done in</h3>
            <Slider
                className="order-block-item"
                value={defaultValue}
                max={maxValue}
                marks={deadlinesLabelsMap}
                onChange={(value) => props.changeDeadline(deadlinesInHours[value])}
                handle={handle} />
        </div>
        {showDayPicker &&
            <div>
                <DayPicker
                    onDayClick={onDayPickerSelectedDay}
                    selectedDays={moment(new Date()).add(props.deadlineInHours, 'hours')._d}
                    disabledDays={[{before: moment(new Date()).add(24, 'hours')._d}]}
                />
                <p className="deliver-msg">All of the tasks will be delivered to<br /> you by 9 AM PST.</p>
            </div>
        }
    </div>
    )
};

export default SelectDeadline
