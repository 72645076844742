import React, { useCallback, useRef } from 'react';
//import { useCfpRating, useCfpLastUnratedOrder } from '@verdaccio/cfp';
import { useCfpRating, useCfpLastUnratedOrder } from '../../hooks';
import {
  reviewOrder,
  orderFetchIfNeeded,
  orderFilesFetchIfNeeded,
  ordersFetchIfNeeded,
  ordersInvalidate,
} from '../../reducers/orders';
import Modal from '../../components/Modal';
import classnames from 'classnames';
import Icon from './../../components/SvgIcons';

import './index.css';

const Rating = (props) => {
  const modalRef = useRef();
  const {
    onClickClose,
    onClickStar,
    onChangeFeeback,
    onClickSubmit,
    title,
    subject,
    mark,
    isFetching,
    isMark,
    isMarkNegative,
    isParam,
    onClickTag,
    tags,
  } = useCfpRating({
    ...props,
    getOrder: useCallback((orderId) => orderFetchIfNeeded(orderId), []),
    rateOrder: useCallback((orderId, data) => reviewOrder(orderId, data), []),
    getOrderFiles: useCallback(
      (orderId) => orderFilesFetchIfNeeded(orderId),
      []
    ),
    getOrders: useCallback((orderId) => ordersFetchIfNeeded(orderId), []),
    ordersInvalidate: useCallback((orderId) => ordersInvalidate(orderId), []),
  });

  const onCloseModal = () => {
    modalRef.current.onClose();
    onClickClose();
  };

  const { isHaveLastUnratedOrder } = useCfpLastUnratedOrder();

  // no render when using direct link from email for mark
  if (isParam) {
    return null;
  } else {
    localStorage.setItem('_is_rating_popup_shown', 1);
  }

  return (
    <Modal onClose={onCloseModal} ref={modalRef}>
      <div className="rating">
        <div className="rating__info-subject">{subject}</div>
        <div className="rating__info-title">{title}</div>
        <div className="rating__divider" />
        <div className="rating__title">
          {isHaveLastUnratedOrder ? (
            <>
              Please tap to rate the quality
              <br /> of your last task!
            </>
          ) : (
            <>Please rate your task</>
          )}
        </div>

        <div className={classnames('rating__stars-list')}>
          {[...Array(5)].map((i, k) => {
            const index = k + 1;
            const isChecked = mark > 0 && index <= mark;
            const isCurrentMark = mark === index;
            return (
              <div
                className={classnames(
                  'rating__stars-item',
                  `rating__stars-item_${index}`,
                  {
                    'rating__stars-item_checked': isChecked,
                    'rating__stars-item_current_mark': isCurrentMark,
                  }
                )}
                onClick={() => onClickStar(index)}
                key={`rating__stars-item-${k}`}
              >
                <Icon
                  className="svg-icon rating__stars-img"
                  iconName={`rating-smile${index}`}
                />
              </div>
            );
          })}
        </div>

        {isMarkNegative && (
          <>
            <div className="tags">
              <div className="tags__list">
                {[
                  { value: 'quality', text: 'Task quality' },
                  {
                    value: 'deadline',
                    text: 'Deadline issue',
                  },
                  {
                    value: 'support',
                    text: 'Client support',
                  },
                ].map((item, key) => {
                  const isChecked = tags.includes(item.value);

                  return (
                    <div
                      className={classnames('tags__item', {
                        tags__item_1: isChecked,
                      })}
                      onClick={() => onClickTag(item.value)}
                      key={`tags__item_${key}`}
                    >
                      {item.text}
                    </div>
                  );
                })}
              </div>
            </div>

            <textarea
              className="rating__feedback"
              placeholder="Tell us what went wrong (optional)"
              onChange={onChangeFeeback}
            />
          </>
        )}

        {!isMarkNegative && (
          <div className="rating__info-line">
            We use your feedback to evaluate your Nerd,
            <br /> and it's <span className="strong">100%</span> anonymous.
          </div>
        )}

        {isMark && (
          <button
            className="rating__button"
            type="button"
            onClick={onClickSubmit}
            disabled={isFetching}
          >
            Submit feedback
          </button>
        )}
      </div>
    </Modal>
  );
};

export default Rating;
