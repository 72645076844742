import React, { useRef } from 'react';
import Modal from '../../components/Modal';
//import { useCfpRatingNegative } from '@verdaccio/cfp';
import { useCfpRatingNegative } from '../../hooks';
import Icon from '../../components/SvgIcons';

import './index.css';
import { Link } from 'react-router-dom';

const RatingNegative = (props) => {
  const modalRef = useRef();
  const { onClose, isOpen, orderId } = useCfpRatingNegative(props);

  const onCloseModal = () => {
    modalRef.current.onClose();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} ref={modalRef}>
      <div className="rating-negative">
        <Icon
          className="svg-icon rating-negative__img"
          iconName="rating-smile3"
        />
        <div className="rating-negative__title">
          Ouch! We are sorry you're not satisfied with your Nerd's work.
        </div>
        <div className="rating-negative__subtitle">
          Our QA team will review your case and get back to you in 1-2 business
          days. Please note that if smth went wrong, you can request revision
          and we'll fix all issues for free.
        </div>
        <button
          className="modal__button modal__button_narrow"
          type="button"
          onClick={onCloseModal}
        >
          Got it
        </button>
        <Link
          className="rating-negative__change-rating-link"
          to={`/orders/${orderId}/rating/change`}
        >
          Change rating
        </Link>
      </div>
    </Modal>
  );
};

export default RatingNegative;
