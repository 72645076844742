import React, { Component } from "react";
import classnames from "classnames";
import Uploader from "./../../utils/Uploader";
import { useMobile } from '../../components/Hooks';
import Icon from "./../../components/SvgIcons";
import DragAndDrop from './../../components/FileUpload'

import './files.css';
import './pie-progress.css';

const SMSTOOL_NAME = process.env.REACT_APP_SMSTOOL_NAME;
//console.log("SMSTOOL_NAME: ",SMSTOOL_NAME);

const WithHook = (props) => {
  const { isMobile } = useMobile();
  return props.children({
    isMobile
  });
};

class Files extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      step: 1,
      isSendFilesBtnEnable: false,
      isTryAgainBtnDisabled: false,
      description: '',
      userId: this.props.match.params.userId,
    };

    this.uploader = new Uploader({
      onAddedFiles: files => this.setState({ files }),
      onProgressFiles: files => this.setState({ files, isSendFilesBtnEnable: false }),
      onLoadedFiles: files => this.onUpdateFileList(files),
      onRemovedFiles: files => this.onUpdateFileList(files),
      //onErrorUpload:  files => this.onErrorUpload(files),
    });
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onReloadFile = this.onReloadFile.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    fetch(`https://${SMSTOOL_NAME === 'stage' ? 'connect-stage.dsh-agency.com' : 'connect.dsh-agency.com'}/api/webhooks/files/${this.state.userId}/check`,{
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      //.then(res => res.json())
      .then(
        (response) => {
          if (response.status !== 200) {
            console.log('Status сode: ', response.status);
            this.setState({ step: 5 });
          }
        },
        (error) => {
          this.setState({
            step: 5,
          });
          console.log("error: ",error);
        }
      );

    window.addEventListener("dragover",function(e){
      e.preventDefault();
    },false);
    window.addEventListener("drop",function(e){
      e.preventDefault();
    },false);
  }


  updateState(state) {
    this.setState(state)
  }

  onUpdateFileList(files) {
    if (Object.values(files).every(item => item.status === "loaded")) {
      this.setState({ files, isSendFilesBtnEnable: true });
    }
  }

  onUploadFiles(files, filetype) {
    this.uploader.addFiles(files, filetype);
  }

  onRemoveFile(id) {
    this.uploader.removeFile(id);
  }

  onReloadFile(originalFile) {
    this.uploader.reloadFile(originalFile, 'user_file');
  }

  handleChange(event) {
    this.setState({description: event.target.value});
  }

  handleDrop = (files) => {
    //console.log("drop files: ",files);
    this.onUploadFiles(Array.from(files), "user_file");
    this.setState({step: 2});
  };

  requestSend() {
    let fileList = [];
    let location;
    this.state.files.map((file) => {
      location = file.location.split('/');
      location[location.length-1] =  encodeURIComponent(location[location.length-1]);
      location = location.join('/');
      fileList.push({
          'filename': file.filename,
          'url': location,
        }
      )
    });

    fetch(`https://${SMSTOOL_NAME === 'stage' ? 'connect-stage.dsh-agency.com' : 'connect.dsh-agency.com'}/api/webhooks/files/${this.state.userId}/receive`,{
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        files: fileList,
        description: this.state.description,
      }),
    })
      .then(
        (response) => {
          if (response.status === 200 || response.status === 201 ) {
            this.setState({step: 3});
          } else {
            this.setState({step: 4});
          }
          //console.log('Status сode: ', response.status);
        },
        (error) => {
          this.setState({
            step: 4,
          });
          console.log("error: ",error);
        }
      );
  }

  render() {
    const extensions = {
      'graphic': ['bmp', 'gif', 'jpeg', 'jpg', 'ico', 'png', 'svg', 'webp'],
      'word': ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm', 'rtf', 'xml'],
      'excel': ['xls', 'xlsx', 'xlsm', 'xlt', 'xltx', 'xltm', 'xltm', 'xlsb', 'xla', 'xlam', 'csv', 'd8f', 'dif', 'ods'],
      'powerPoint': ['odp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'],
      'pdf': ['pdf'],
      'archive': ['rar', '7z', 'ace', 'arj', 'bin', 'cab', 'cbr', 'gz', 'gzip', 'pak', 'sh', 'tar', 'tar-gz', '.tgz', 'zip', '.zipx']
    };
/*
    const files = [
      {
        filename: "image.jpg",
        status: "error",
        size: "100000000"
      },
      {
        filename: "image.zip",
        status: "loading",
        progress: "33",
        size: "100000000"
      },
      {
        filename: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consequuntur illum maxime molestias natus optio porro ratione tempora voluptas.doc",
        status: "loaded",
        progress: "33",
        size: "1000000000"
      },
    ];
*/
    let step = this.state.step;
    return (
      <WithHook>
        {({
            isMobile
          }) => {
          return (
      <>
        {step === 1 &&
        <div className="file-upload-page">
          {isMobile ?
            <Icon className="logo" iconName="logo-icon2" />
            :
            <Icon className="logo" iconName="logo-icon3" />
          }
          {!isMobile && <div className="info-line">Add your files to share them with Nerdify</div>}


          <DragAndDrop handleDrop={this.handleDrop}>

          <div className="file-frame file-frame1">
            <div className="stretch-element">
              <img
                className="file-img1"
                src={require("./img/ill.png")}
                srcSet={`${require("./img/ill@2x.png")} 2x, ${require("./img/ill@3x.png")} 3x`}
                alt=""
              />
            </div>
            {isMobile && <div className="info-line">Add files or take photos to share them with Nerdify+</div>}
            {!isMobile && <>
              <div className="info-line2">Drag and drop files here</div>
              <div className="or-line">or</div>
            </>
            }

            <label className="file-upload-btn">
              <span>Upload</span>
              <input type="file" multiple
                     onChange={(event)=> {
                       this.onUploadFiles(Array.from(event.target.files), "user_file");
                       this.setState({step: 2});
                     }}
                     onClick={(event)=> {
                       event.target.value = null;
                       //console.log(SMSTOOL_NAME);
                     }}
                     />
            </label>
          </div>
          </DragAndDrop>
        </div>
        }

        {step === 2 &&
        <div className="file-send-page">
          {!isMobile && <>
            <Icon className="logo" iconName="logo-icon3" />
            <div className="info-line">Add your files to share them with Nerdify</div>
          </>
          }
          <DragAndDrop  handleDrop={this.handleDrop}>
          <div className="file-send-wrapper">
            <div className="top-part">
              <div className="info-block">
                <div className="title">Added items</div>
                <div className="file-count">
                  {this.state.files.length} file{this.state.files.length !== 1 && <>s</>}
                </div>
              </div>
              <label className="upload-btn">
                <span>+ Upload</span>
                <input type="file" multiple
                       onChange={(event)=> {
                         this.onUploadFiles(Array.from(event.target.files), "user_file");
                       }}
                       onClick={(event)=> {
                         event.target.value = null
                       }}
                       />
              </label>
            </div>
            <div className="middle-part">
              <ul className="file-list">
                {this.state.files.map((file, index) => {
                  let fileName = file.filename.replace(/\.[^/.]+$/, "");
                  let fileExtension = file.filename.split('.').pop().trim().toLowerCase();
                  return (
                    <li className={classnames("file-list-item",file.status)} key={index}>
                      <div className="file-icon">
                        {extensions.graphic.includes(fileExtension) ?
                          <img className="preview-icon" src={(file.status === "loaded" && file.progress === 100) ? file.location : require('./img/ext/unknown-file.svg')} alt=""/> :
                          extensions.word.includes(fileExtension) ?
                            <img className="preview-icon" src={require('./img/ext/word-file.svg')} alt=""/> :
                            extensions.excel.includes(fileExtension) ?
                              <img className="preview-icon" src={require('./img/ext/xls-file.svg')} alt=""/> :
                              extensions.powerPoint.includes(fileExtension) ?
                                <img className="preview-icon" src={require('./img/ext/ppt-file.svg')} alt=""/> :
                                extensions.pdf.includes(fileExtension) ?
                                  <img className="preview-icon" src={require('./img/ext/pdf-file.svg')} alt=""/> :
                                  extensions.archive.includes(fileExtension) ?
                                    <img className="preview-icon" src={require('./img/ext/archive-file.svg')} alt=""/> :
                                    <img className="preview-icon" src={require('./img/ext/unknown-file.svg')} alt=""/>}
                      </div>

                      <div className="file-info">
                        <div className="file-name">
                          <div className="name">{fileName}</div>
                          <div className="extension">.{fileExtension}</div>
                        </div>
                        {(file.status === "error" || file.status === "failed") ?
                          <div className="error-line">
                            <img className="error-icon" src={require('./img/error-icon.png')} srcSet={`${require("./img/error-icon@2x.png")} 2x, ${require("./img/error-icon@3x.png")} 3x`} alt=""/>
                            <span className="error-message"> Unknown error</span>
                          </div>
                          :
                          <div className="file-size">{(file.size / 1024 / 1024).toFixed(1)} Mb</div>
                        }
                      </div>

                      {file.status === "loading" &&
                        <button className={`pie-wrapper progress-${file.progress}`} type="button" onClick={() => this.onRemoveFile(file.id)}>
                          <div className="label">
                            <Icon className="download-break-icon" iconName="cross" />
                          </div>
                          <div className="pie">
                            <div className="left-side half-circle"/>
                            <div className="right-side half-circle"/>
                          </div>
                        </button>
                      }
                      {file.status === "loaded" &&
                        <button className="file-delete-btn" type="button" onClick={() => this.onRemoveFile(file.id)}>
                          <Icon className="file-delete-icon" iconName="trash-icon2" />
                        </button>
                      }
                      {(file.status === "error" || file.status === "failed") &&
                        <button type="button" onClick={() => this.onReloadFile(file.reloadFile)}>
                          <Icon className="reload-icon" iconName="reload" />
                        </button>
                      }
                    </li>
                  )
                })}
              </ul>
              <div className="description-block">
                <div className="description-title"><span className="strong">Description</span> (optional)</div>
                <textarea
                  className="description-field"
                  name="description"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </div>

            </div>
            <div className="bottom-part">
              <button className="file-upload-btn" disabled={!this.state.isSendFilesBtnEnable || !this.state.files.length}
                      onClick={() => {
                        this.requestSend();
                      }}>
                Send files
              </button>
            </div>

          </div>
          </DragAndDrop>
        </div>
        }

        {step === 3 &&
        <div className="file-upload-page">
          {isMobile ?
            <Icon className="logo" iconName="logo-icon2" />
            :
            <Icon className="logo" iconName="logo-icon3" />
          }
          <div className="file-frame file-frame2">
            <div className="frame-title">Well done!</div>
            <div className="frame-subtitle">Your files were sent{isMobile && <br/>} successfully</div>
            <div className="stretch-element">
              <img
                className="file-img2"
                src={require("./img/done.png")}
                srcSet={`${require("./img/done@2x.png")} 2x, ${require("./img/done@3x.png")} 3x`}
                alt=""
              />
            </div>

            <div className="info-line3">
              {!isMobile && <>You can close this window<br/> or </>}
              <button className="file-link" onClick={() => {
                this.state.files.map((file) => {
                  this.uploader.removeFile(file.id);
                });
                this.setState({description:'', step: 1});
                }
              }
            >{isMobile ? 'S':'s'}end more files</button>
            </div>
          </div>
        </div>
        }

        {step === 4 && // error
        <div className="file-upload-page">
          {isMobile ?
            <Icon className="logo" iconName="logo-icon2" />
            :
            <Icon className="logo" iconName="logo-icon3" />
          }
          <div className="file-frame file-frame3">
            <div className="frame-title">File upload failed</div>
            <div className="frame-subtitle">Something went wrong during the upload{isMobile && <br/>}</div>
            <div className="stretch-element">
              <img
                className="file-img3"
                src={require("./img/design.png")}
                srcSet={`${require("./img/design@2x.png")} 2x, ${require("./img/design@3x.png")} 3x`}
                alt=""
              />
            </div>
            <div className="file-btn-block">
              <button
                className="file-btn file-secondary-btn"
                onClick={() => {
                  this.setState({step: 2});
                }}
              >Back</button>
              <button
                className="file-btn file-primary-btn"
                disabled={this.state.isTryAgainBtnDisabled}
                onClick={() => {
                  this.setState({
                    isTryAgainBtnDisabled: true
                  });
                  setTimeout(() => this.setState({ isTryAgainBtnDisabled: false }), 2000);
                  this.requestSend();
                }}
              >Try again</button>
            </div>
            </div>
          </div>
          }


          {step === 5 && // page does not exist
          <div className="file-upload-page">
            {isMobile ?
              <Icon className="logo" iconName="logo-icon2" />
              :
              <Icon className="logo" iconName="logo-icon3" />
            }
            <div className="file-frame file-frame2">
              <div className="frame-title">Oops,</div>
              <div className="frame-subtitle">the page you are looking<br/>
                for does not exist</div>
              <div className="stretch-element">
                <img
                  className="file-img4"
                  src={require("./img/404.png")}
                  srcSet={`${require("./img/404@2x.png")} 2x, ${require("./img/404@3x.png")} 3x`}
                  alt=""
                />
              </div>
            </div>

        </div>
        }


      </>
          )
        }}
      </WithHook>
    )
  }
}

export default Files
