import React from 'react';
import Icon from './../../components/SvgIcons'
import classnames from 'classnames';

import Accordion, {
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon as AccordionIconBase,
  AccordionItemHolder
} from './../Accordion';

import './index.css';

const FaqTheme = (props) => (
  <div className={classnames('faq', props.className)}>
    {props.children}
  </div>
)

const Faq = (props) => <FaqTheme className="faq_1" {...props} />
const Faq2 = (props) => <FaqTheme className={classnames('faq_2', {[`faq_2_${props.theme}`]: props.theme})} {...props} />

export const AccordionIcon = ({ icons, ...rest }) => <AccordionIconBase {...rest}>
  {icons.map(item => <Icon
    className={`svg-icon`}
    iconName={`${item}`}
    key={`accordion-icon-${item}`}
  />)}
</AccordionIconBase>;

export {
  Faq as default,
  Faq2,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionItemHolder
}
