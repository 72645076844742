import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Icon from './../SvgIcons'
import  './header-login.css'

export default class HeaderLogin extends Component {
    render() {
        return (
            <header className='header-login'> {/* add .with-back-link */}
                <div className="wrapper clearfix">
                    <div className="img-wrap">
                        <Link className="back-link" to="#"><Icon className="svg-icon" iconName="back-arrow" /></Link>
                        <Icon className="svg-icon" iconName="nerdify-logo" />
                    </div>
                </div>
            </header>
        )
    }
}
