import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames';
import * as moment from "moment";
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import {deadlinesInHours, deadlinesLabels, deadlinesInHoursPremium, deadlinesLabelsPremium} from './../../config';

import {
  changeDiscount,
  changeJobType,
  changeMeasure,
  changeSize,
  fetchOrder,
  updateOrder,
  saveOrder,
  changeDeadlineIfNeeded,
  clearDiscountError
} from './../../reducers/orders';

import { convertMinutesTo_hm } from './../../utils/MeasureConvertor';
import { createReminder } from './../../api';
import convertDateTimeToDeadlineDaysHours from "./../../utils/convertDateTimeToDeadlineDaysHours";
import { getSizeText } from "./../../utils/MeasureConvertor";
import getMessageDiscountInfo from "./../../utils/getMessageDiscountInfo";
import { fetchPricing } from './../../reducers/pricing';
import prepareOrder from './../../utils/prepareOrder';
import { round } from './../../utils/MeasureConvertor';
import calcPrice from './../../utils/calcPrice';
import Button from './../../components/Button';
import Subjects from "../../components/Subjects";
import SelectTime from './../../components/SelectTime';
import SelectPages from './../../components/SelectPages';
import SelectSlides from './../../components/SelectSlides';
import SelectDeadline from './../../components/SelectDeadline';
import Discount from './../../components/Discount';
import Icon from './../../components/SvgIcons';

import {
  ALL_SUBJECTS,
} from "../../config";

import './order.css';
import {updateCurrentOrder} from "../../reducers/order";

const helpTextMap = {
  quiz: (
    <div className="order-block-item">
      <h4 className="h4">Text us to get an estimated time needed to complete your quiz</h4>
      <h5 className="h5">Tip: If your quiz is not timed, count 1 multiple choice question as 15 minutes of nerd’s
        work.</h5>
    </div>
  ),
  problems: (
    <div className="order-block-item">
      <h4 className="h4">We charge for the time nerd spends working on your task</h4>
      <h5 className="h5">Tip: Text us to get estimated time needed to complete your task</h5>
    </div>
  ),
  task: (
    <div className="order-block-item">
      <h4 className="h4">Just let us know what you need and we will find a suitable person who will charge for the
        time spent working on your task</h4>
    </div>
  )
};

class Order extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      errorField: false,
      errorFieldMsg: '',
      isFormSubmitted: false,
      subject: ''
    };

    this.updateCurrentJobType = this.updateCurrentJobType.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.changeDiscount = this.changeDiscount.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.updateErrorTime = this.updateErrorTime.bind(this);
    this.errorCheck = this.errorCheck.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
  }

  makeOpen() {
    this.setState({ isOpen: !this.state.isOpen });
    if (!this.state.isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  }

  handleOutsideClick(e) {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onChange(value) {
    this.setState({ value: value });
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  updateCurrentJobType(jobType) {
    this.props.changeJobType(jobType);

    const { id } = this.props.order.data;
    const { history } = this.props;
    const link = id ? `/order/${id}` : `/neworder/${jobType}`;

    history.push(link);

    this.setState({ isOpen: !this.state.isOpen })
  }

  updateErrorTime(value) {
    this.setState({ errorTime: value })
  }

  componentDidMount() {
    this.props.fetchPricing();
    if (this.props.orderId) {
      this.props.fetchOrder().then(
        (resp) => {
          const { isPaid } = this.props.order.data;
          this.redirectPaidOrder(isPaid);
          this.draftCheck();

          const { order } = this.props;
          const { data } = order;

          this.updateState({
            subject: data.subject,
          });

          return resp;
        }
      ).catch(error => error);
    } else {
      this.createOrder().then(resp => {
        const {user} = this.props;
        if (user.data.paid_orders_count === 0) {
          return;
        }
/*
        this.props.updateOrder(resp._id, {
          discount: 'PRIORITYFREE'
        })
*/
      });
    }
  }

  updateState(state) {
    this.setState(state)
  }

  componentDidUpdate(prevProps) {
    if (this.props.order.data !== prevProps.order.data) {
      this.errorCheck();
    }
  }

  errorCheck() {
    const { pages, slides, minutes } = this.props.order.data;
    const { pagesRange, slidesRange, hoursRange } = this.props.settings;
    let hours = convertMinutesTo_hm(minutes).hours;

    if (['editing', 'writing'].indexOf(this.props.selectedJobType) > -1) {
      if (pages > Math.max.apply(null, pagesRange)) {
        this.setState({ errorField: true, errorFieldMsg: 'This value must be below 50 pages' });
      } else {
        this.setState({ errorField: false });
      }
    }

    if (['presentation'].indexOf(this.props.selectedJobType) > -1) {
      if (slides > Math.max.apply(null, slidesRange)) {
        this.setState({ errorField: true, errorFieldMsg: 'This value must be below 50 slides' });
      } else {
        this.setState({ errorField: false });
      }
    }

    if (['problems', 'quiz', 'task'].indexOf(this.props.selectedJobType) > -1) {
      if (hours > Math.max.apply(null, hoursRange)) {
        this.setState({ errorField: true, errorFieldMsg: 'This value must be below 24 hours' });
      } else {
        this.setState({ errorField: false });
      }
    }
  }


  draftCheck() {
    const {
      order
    } = this.props;

    const {
      data
    } = order;

    if (!data.pages && data.slides) {
      this.props.changeSize(1);
    }

    if (!data.service_type) {
      const { id } = data;
      const { history } = this.props;
      const link = `/order/${id}/jobtype`;
      history.push(link);
    }
  }


  createOrder() {
    const { order, history } = this.props;
    const data = Object.assign(order.data, this.props.selectedJobType);

    return this.props.saveOrder(prepareOrder(data)).then(resp => {
      history.push(`/order/${resp._id}`);
      return resp;
    }).catch(error => error);
  }

  redirectPaidOrder(isPaid) {
    if (isPaid) {
      const { history } = this.props;
      history.push("/");
    }
  }

  changeDiscount(data) {
    this.props.changeDiscount(data);
  }

  async nextStep() {
    this.setState({ isFormSubmitted: true });

    const { order, discountInfo, allSubjects } = this.props;
    const { data } = order;
    const { id, discountValid, discountCode, deadline, service_type, size, price, number } = order.data;
    const new_order_event = true;

    const { subject } = this.state;
    const formValid = subject && allSubjects.includes(subject);

    if (!formValid) {
      return false;
    }

    let discountMessage = null;
    if (discountInfo) {
      discountMessage = getMessageDiscountInfo(order.data);
    }

    let discount = discountCode;
    let discountType = discountMessage;

    if (discountCode && !discountValid) {
      discount = '';
      discountType = '';
    }

    const { user, history } = this.props;
    const link = `/order/${id}/details`;

    const dd = convertDateTimeToDeadlineDaysHours(deadline);

    let message = `
    User has just placed an unpaid order.
    Type of task: ${service_type}.
    Length: ${getSizeText(size)}.
    Due: ${dd.days}d ${dd.hours}h / ${moment(deadline).format("ll")}.`;

    if (discount && discountType) {
      message = message + `
    Coupon: ${discount}.
    Discount: ${discountType}.`;
    }

    message = message + `
    Price: ${price}.
    Order number: ${number}.`;

    await createReminder(user, message, number, new_order_event)
      .then((resp) => {
        window.scrollTo(0, 0);
        return resp;
      }).catch(error => error);

    const payload = prepareOrder(data);

    this.props.saveOrder(payload)
      .then((resp) => {
        return resp;
      }).catch(error => error)

    if (discountCode && !discountValid) {
      this.props.changeDiscount({
        action: 'discount_del',
        code: discountCode
      })
        .then((resp) => {
          history.push(link);
          return resp
        }).catch(error => error)
    } else {
      history.push(link);
    }
  }

  renderPrice() {
    const { order, error, user } = this.props;
    const { discountValid, quote_price } = order.data;

    /*var plugreportPriceMinus = plagiarism_check ? discount_info && discount_info.value === 'plagreport' ? 0 : 10 : 0;*/

    //console.log(2, plagiarism_check, plugreportPriceMinus, this.props.currentPrice && this.props.currentPrice.calculatedServerPrice);

    const Price = !discountValid ? (
      <div className="price" key="price">
        {/*Price: <span>${round(this.props.currentPrice && this.props.currentPrice.calculatedServerPrice - plugreportPriceMinus)}</span>*/}
        Price: <span>${round(quote_price)}</span>
      </div>
    ) : null;

    return [
      Price,
      <Discount
        key="discount"
        changeDiscount={this.changeDiscount}
        clearDiscountError={this.props.clearDiscountError}
        isLoading={this.props.isLoading}
        orderError={this.props.orderError}
        order={order}
        error={error}
        user={user}
        phone={user && user.data && user.data.phone}
      />,
      <div id="get-done-btn" key="get-done-btn">
        <Button onClick={this.nextStep}>Get it done</Button>
      </div>
    ]
  }

  onChangeSubject(subject) {
    this.setState({subject}, function() {
      const { order, allSubjects } = this.props;
      const { data } = order;

      if (subject && allSubjects.includes(subject)) {
        const payload = prepareOrder(data);
        this.props.saveOrder(payload)
          .then((resp) => {
            return resp;
          }).catch(error => error)
      }
    });
  }

  render() {
    const {
      order,
      ordersSubjects,
      allSubjects,
      user
    } = this.props;

    const { subject, isFormSubmitted } = this.state;

    const userData = (user.isAuthenticated && user.fetched && user?.data) ?? {};
    const subscriptionsActive = userData?.subscriptions_active;
    const isPremiumActive = subscriptionsActive?.includes('nerdify_premium') || subscriptionsActive?.includes('nerdify_premium_trial');;

        const searchSubjects = allSubjects
          .filter(item => subject.trim().split(' ')
            .find(i => item.toLowerCase()
              .includes(i.toLowerCase())));

        const isSearchError = subject.length > 0 && searchSubjects.length === 0;

        const isSubjectError = isFormSubmitted && (!subject || !allSubjects.includes(subject));

    const {
      data
    } = order;

    const jobTypeTitle =  data.jobType === 'task' ? 'Other task' : data.jobType.charAt(0).toUpperCase() + data.jobType.slice(1); //capitalize the first letter

    if (this.props.orderId && !data.id) {
      return null;
    }

    const { isPromo, type } = data;

    if (isPromo || type === "Complex") {
      return <Redirect to={'/orders'} />
    }

    const restrictions = (
      <div className="err-msg center">{this.props.currentPrice && this.props.currentPrice.restrictions}</div>
    );

    const helpText = helpTextMap[this.props.selectedJobType] || null;

    const jobTypeItem = ({ id, siteName }) => {
      const isSelected = data.jobType === id;

      return (
        <div key={id}
          className={classnames("item-name", { selected: isSelected })}
          onClick={() => this.updateCurrentJobType(id)}
        >
          {siteName}
          <Icon className="svg-icon" iconName="check-icon3" />
        </div>
      )
    };


    return (
      <div className="wrapper with-fixed-header-mob">
        <div className="frame frame-order">

          <div className="order-job-type-dropdown">
            <button className="order-job-type-dropdown-btn" onClick={this.makeOpen.bind(this)}>
              {jobTypeTitle}
              <Icon className="svg-icon" iconName="down-arrow-icon" />
            </button>
            <div className={classnames("order-job-type-dropdown-list", { isOpen: this.state.isOpen })}>
              {this.props.jobTypes.map(jobTypeItem)}
            </div>
          </div>

          <Subjects
            allSubjects={allSubjects}
            searchSubjects={searchSubjects}
            isSearchError={isSearchError}
            isSubjectError={isSubjectError}
            onChangeSubject={this.onChangeSubject}
            subject={subject}
            ordersSubjects={ordersSubjects}
            updateCurrentOrder={this.props.updateCurrentOrder}
          />

          {helpText}

          {['editing', 'writing'].indexOf(this.props.selectedJobType) > -1 &&
            <SelectPages
              {...data}
              {...this.props.settings}
              changeMeasure={this.props.changeMeasure}
              changeSize={this.props.changeSize}
              errorField={this.state.errorField}
            />
          }

          {['presentation'].indexOf(this.props.selectedJobType) > -1 &&
            <SelectSlides
              {...data}
              {...this.props.settings}
              changeSize={this.props.changeSize}
              errorField={this.state.errorField}
            />
          }

          {['problems', 'quiz', 'task'].indexOf(this.props.selectedJobType) > -1 &&
            <SelectTime
              {...data}
              {...this.props.settings}
              changeSize={this.props.changeSize}
              errorField={this.state.errorField}
            />
          }

          <SelectDeadline
            deadlineInHours={data.deadlineInHours}
            changeDeadline={this.props.changeDeadline}
            deadlinesInHours={isPremiumActive ? deadlinesInHoursPremium : deadlinesInHours}
            deadlinesLabels={isPremiumActive ? deadlinesLabelsPremium : deadlinesLabels}
          />

          {this.state.errorField &&
            <div className="err-msg center">{this.state.errorFieldMsg}</div>
          }
          {this.props.currentPrice && !this.state.errorField && (
            <div className="order-block-item thin">
              {this.props.currentPrice.restrictions
                ? restrictions
                : this.renderPrice()
              }
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => {
    const { orderId } = ownProps.match.params;
    const { orders } = state;

    const ordersIds = Object.keys(orders.data);

    const ordersSubjects = [... new Set(ordersIds
      .map(orderId => orders.data[orderId].subject))]
      .filter(item => ALL_SUBJECTS.includes(item)).slice(0, 3) || [];

    const allSubjects = [...new Set(ALL_SUBJECTS.concat(ordersSubjects))];

    return {
      isLoading: get(orders.data, `${[orderId]}.state.loading`, false),
      orderError: get(orders.data, `${[orderId]}.state.error`, false),
      session: state.user.session,
      order: state.order,
      currentPrice: calcPrice(state.order.data, state.pricing),
      settings: state.settings,
      user: state.user,
      selectedJobType: ownProps.match.params.selectedJobType || state.order.data.jobType,
      orderId: ownProps.match.params.orderId,
      jobTypes: state.settings.jobTypes,
      ordersSubjects,
      allSubjects,
    }
  },
  (dispatch, ownProps) => {
    const { orderId } = ownProps.match.params;

    return {
      fetchPricing: () => dispatch(fetchPricing()),
      fetchOrder: () => dispatch(fetchOrder(orderId)),
      updateOrder: (orderId, payload) => dispatch(updateOrder(orderId, payload)),
      saveOrder: (payload, opts) => dispatch(saveOrder(payload, opts)),
      changeJobType: (jobType) => dispatch(changeJobType(orderId, jobType)),
      changeMeasure: (newMeasure) => dispatch(changeMeasure(orderId, newMeasure)),
      changeDiscount: (data) => dispatch(changeDiscount(orderId, data)),
      changeSize: (newSize) => dispatch(changeSize(orderId, newSize)),
      changeDeadline: (newDeadline) => dispatch(changeDeadlineIfNeeded(orderId, newDeadline)),
      clearDiscountError: () => dispatch(clearDiscountError(orderId)),
      updateCurrentOrder: (payload) => dispatch(updateCurrentOrder(payload))
    }
  }
)(Order)
