import { convertWithSize } from './../utils/MeasureConvertor';
import convertDateTimeToDeadlineHours from './../utils/convertDateTimeToDeadlineHours';
import { jobTypes, deadlinesInHours, shortPhoneNumber } from './../config';
import Auth from './../api/auth';
import _ from 'lodash';
import moment from 'moment-timezone';

//import { cfpMapOrder } from '@verdaccio/cfp';
import { cfpMapOrder } from '../hooks';

const CRM_HOST_URL = process.env.REACT_APP_CRM_HOST_URL;
const SMSTOOL_NAME = process.env.REACT_APP_SMSTOOL_NAME;
//const ENV = process.env.REACT_APP_ENV;
const SEND_MESSAGE_TIMEOUT = 2000;

const getHeaders = () => {
  if (Auth.isAuthenticated) {
    return new Headers({
      //'X-Token': Auth.getToken(),
      'Authorization': 'Bearer ' + Auth.getToken(),
      'Content-Type': 'application/json',
    });
  }
  return new Headers({
    'Content-Type': 'application/json',
  });
};

function orderTypeConformity(orderType) {
  if (
    orderType.search(/writing/i) !== -1 ||
    orderType === 'Research paper' ||
    orderType === 'Project'
  ) {
    orderType = 'Essay (writing)';
  } else if (
    orderType.search(/editing/i) !== -1 ||
    orderType === 'Proofreading'
  ) {
    orderType = 'Essay (editing)';
  } else if (orderType.search(/presentation/i) !== -1) {
    orderType = 'Presentations';
  } else if (orderType.search(/problem/i) !== -1) {
    orderType = 'Problems';
  } else if (orderType.search(/quiz/i) !== -1) {
    orderType = 'Quiz';
  } else {
    orderType = 'Other task';
  }
  return orderType;
}

// === pricing ===

const pricingUrl = () => CRM_HOST_URL + `/api/v1/pricing/gonerdify`;

export const getPricing = () =>
  fetchWrapper(pricingUrl(), {
    method: 'GET',
  });

// === orders ===

const ordersUrl = () => CRM_HOST_URL + `/api/v3/orders?page=0`;
const orderUrl = (orderId) => CRM_HOST_URL + `/api/v3/orders/${orderId}`;

export const orderFilesUrl = (orderId) =>
  CRM_HOST_URL + `/api/v3/orders/${orderId}/files`;

const fetchWrapper = (url, opts) => {
  return fetch(url, opts)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }

      return resp
        .json()
        .catch(() =>
          Promise.reject({
            status: resp.status,
            text: 'Request JSON error',
          })
        )
        .then((json) => {
          const errorType = json.error_type
            ? { error_type: json.error_type }
            : {};

          return Promise.reject(
            Object.assign(
              {
                status: resp.status,
                text: json.detail || json.error_message,
                error_value: json.detail || json.error_value,
              },
              errorType
            )
          );
        });
    })
    .catch((error) => {
      if (error instanceof Error) {
        return Promise.reject({ text: error.toString() });
      }

      if (typeof error === 'string' || error instanceof String) {
        return Promise.reject({ text: error });
      }

      if (error && typeof error === 'object' && error.constructor === Object) {
        return Promise.reject(error);
      }

      return Promise.reject({
        text: 'Unknown error',
      });
    });
};

export const mapOrder = (order) => {
  const id = order._id;
  const priceName = orderTypeConformity(order.type);
  const subject = order.subject;
  const deadlineInHours = convertDateTimeToDeadlineHours(order.deadline);
  const createdAt = order.created_at;
  const _size = parseInt(order.size.split(' ')[0], 10);
  let _measure = order.size.split(' ')[1];
  _measure = _measure === 'page' || _measure === 'pages' ? 'Pages' : _measure;
  _measure =
    _measure === 'slide' || _measure === 'slides' ? 'Slides' : _measure;
  _measure =
    _measure === 'minute' || _measure === 'minutes' ? 'Minutes' : _measure;
  _measure = _measure === 'word' || _measure === 'words' ? 'Words' : _measure;

  _measure =
    _measure === 'source' || _measure === 'sources' ? 'Sources' : _measure;

  const { measure, pages, words, slides, minutes } = convertWithSize(_size, {
    measure: _measure,
  });

  const measurePagesOrWords = measure === 'Words' ? 'Words' : 'Pages';

  const jobIndex = jobTypes.findIndex((jt) => jt.priceName === priceName);
  const jobType = jobIndex > -1 ? jobTypes[jobIndex].id : 'task';
  const attachments = [];
  const decoStatus = order.client_status.toLowerCase().replace(' ', '-');
  const boolPaidStatus = !!order.client_paid;
  const boolInWorkStatus =
    decoStatus === 'in-progress' || decoStatus === 'nerd-found' ? 1 : 0;
  const boolAddonPlagReport = order.plagiarism_check;
  const isPaid = order.system_client_status !== 'Unpaid';

  // discount
  const discount = order.discount || '';

  const discountInfoValid = _.get(order, 'discounts_info', []).filter(
    (item) => item.discount_valid
  );
  const discountInfoInValid = _.get(order, 'discounts_info', []).filter(
    (item) => !item.discount_valid
  );

  const discountInfo =
    (discountInfoValid.length > 0 && discountInfoValid) ||
    (discountInfoInValid.length > 0 && discountInfoInValid);

  const isDiscountInfo = discountInfo.length > 0;
  const discountCode = (isDiscountInfo && discountInfo[0].code) || '';

  const discountErrorMessage =
    (isDiscountInfo && discountInfo[0].discount_error_message) || '';
  const discountErrorType =
    (isDiscountInfo && discountInfo[0].discount_error_type) || '';
  const discountErrorValue =
    (isDiscountInfo && discountInfo[0].discount_error_value) || '';
  const discountValid =
    (isDiscountInfo && discountInfo[0].discount_valid) || false;
  const discountOrderQuote = order.discount_amount || 0;

  const isPromo = order.is_promo_type;

  const services = order.services || 'test';

  return {
    ...order,
    ...cfpMapOrder(order),
    id,
    priceName,
    deadlineInHours,
/*
    deadlineInHours:
      deadlineInHours >= deadlinesInHours[0]
        ? deadlineInHours
        : deadlinesInHours[0],
*/
    createdAt,
    measure,
    measurePagesOrWords,
    pages,
    subject,
    jobType,
    words,
    slides,
    minutes,
    attachments,
    decoStatus,
    boolPaidStatus,
    boolInWorkStatus,
    boolAddonPlagReport,
    isPaid,
    discount,
    discountCode,
    isPromo,
    services,
    discountErrorMessage,
    discountErrorType,
    discountErrorValue,
    discountValid,
    discountInfo,
    discountOrderQuote,
    isDiscountInfo,
  };
};

const isValidOrder = (order) =>
  order.size &&
  order.deadline &&
  (order.client_paid ||
    (!order.client_paid && convertDateTimeToDeadlineHours(order.deadline)));

export const getOrders = () =>
  fetchWrapper(ordersUrl(), {
    method: 'GET',
    headers: getHeaders(),
  })
    // .then(r => r.json())
    .then((r) => r.results.filter(isValidOrder).map(mapOrder));

export const getOrder = (orderId) =>
  fetchWrapper(orderUrl(orderId), {
    method: 'GET',
    headers: getHeaders(),
  })
    // .then(r => r.json())
    .then((order) => mapOrder(order));

export const newPostOrder = (orderId, data) =>
  fetchWrapper(orderUrl(orderId), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((order) => mapOrder(order));

export const postOrder = (payload) => {
  try {
    delete payload.id;
  } catch (err) {}
  return (
    fetchWrapper(ordersUrl(), {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(payload),
    })
      // .then(r => r.json())
      .then((order) => mapOrder(order))
  );
};

export const patchOrder = (orderId, payload) => {
  try {
    delete payload.client_id;
  } catch (err) {}
  try {
    delete payload.id;
  } catch (err) {}
  return fetchWrapper(orderUrl(orderId), {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });
};

export const getOrderFiles = (orderId) =>
  fetchWrapper(orderFilesUrl(orderId), {
    method: 'GET',
    headers: getHeaders(),
  })
    // .then(r => r.json())
    .then((r) => r.results.map((file) => ({ ...file, id: file._id })));

// === user ===

const meUrl = () => CRM_HOST_URL + `/api/v3/users/client/me`;
//const clientUrl = (userId) => CRM_HOST_URL + `/api/v3/users/client/${userId}`;

const getUserCredential = (user, credentialType) => {
  const targetCredential =
    user.credentials &&
    user.credentials.find(
      (credential) => credential.split(',')[1] === credentialType
    );
  return targetCredential ? targetCredential.split(',')[2] : null;
};

const mapUser = (user) => {
  const { facebook, facebook_messenger, phone } = user;
  const email =
    user.email && user.email.endsWith('@qavls.com') ? '' : user.email;
  const facebook_page_id =
    facebook || getUserCredential(user, 'facebook_unaccessible');
  const facebook_messenger_id =
    getUserCredential(user, 'facebook') || facebook_messenger;
  return {
    ...user,
    id: user._id,
    email,
    credentials: {
      phone,
      facebook_page_id,
      facebook_messenger_id,
    },
  };
};

export const getUser = () =>
  fetchWrapper(meUrl(), {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'omit',
  })
    // .then(r => r.json())
    .then((user) => mapUser(user));

export const fetchUserSubscriptions = (userId) =>
  fetchWrapper(
    CRM_HOST_URL + `/api/v3/users/client/${userId}/rawsubscriptions`,
    {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'omit',
    }
  ).then((result) => (result.results.length > 0 ? result.results[0] : null));

export const fetchSubscriptionSesssion = (
  userId,
  tag,
  successUrl,
  cancelUrl
) =>
  fetchWrapper(
    CRM_HOST_URL +
      `/api/v3/users/client/${userId}/create_subscription_sesssion`,
    {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'omit',
      body: JSON.stringify({
        tag,
        success_url: successUrl,
        cancel_url: cancelUrl,
      }),
    }
  )
    .then((session) => session)
    // .catch(data => { if (data.status === 422) { fetchUserSubscriptions(userId) }})
    .catch((data) => {
      if (data.status === 422) {
        window.location.reload();
      }
    });

export const fetchSetupPaymentMethodSesssion = (
  userId,
  subscriptionId,
  customerId,
  successUrl,
  cancelUrl
) =>
  fetchWrapper(
    CRM_HOST_URL +
      `/api/v3/users/client/${userId}/create_setup_payment_method_sesssion`,
    {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'omit',
      body: JSON.stringify({
        subscription_id: subscriptionId,
        customer_id: customerId,
        success_url: successUrl,
        cancel_url: cancelUrl,
      }),
    }
  ).then((session) => session);

export const patchUser = (payload) =>
  fetchWrapper(meUrl(), {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
    // .then(r => r.json())
    .then((user) => mapUser(user));

export const postUser = (payload) =>
  fetchWrapper(meUrl(), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
    .then((user) => mapUser(user));

// auth

const requestTokenUrl = () => CRM_HOST_URL + `/api/v3/factor_auth`;

export const requestToken = ({ factor, value }) =>
  fetch(requestTokenUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'request_code',
      factor,
      value,
    }),
  }).then((r) => r.ok);

export const applyToken = ({ factor, value, code }) =>
  fetchWrapper(requestTokenUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'check_code',
      timezone_str: moment.tz.guess(),
      factor,
      value,
      code,
    }),
  })
    // .then(r => r.json())
    .then((resp) => {
      return resp;
    });

// messages

//const sendMessageUrl = () => CRM_HOST_URL + `/smstool/send_message_new/`;
const MessageUrl = SMSTOOL_NAME === 'stage' ? `https://connect-stage.dsh-agency.com/api/external/messages/textback` : `https://connect.dsh-agency.com/api/external/messages/textback`;
const sendMessageUrl = () => MessageUrl;

const createClientUrl = CRM_HOST_URL + `/api/v1/client`;

export const createUser = (data) =>
  fetchWrapper(createClientUrl, {
    method: 'POST',
    body: data,
  });

/*export const createClient = (phone, country) => {

  const emptyUser = {
      data: {
          credentials: {
              phone
          }
      }
  };
  const form = new FormData();
  form.append('phone', phone);
  //form.append('phone_ref', phone);
  if (country) {
    form.append('country', shortCountry[country]);
  }
  form.append('landing', '/contact-us');

  //console.log('country: ',country);

  return fetchWrapper(createClientPath(), {
    method: 'POST',
    credentials: 'omit',
    body: form
  }).then(() => {
      return sendMessage(
        emptyUser,
        message,
        country
      );
    }
  );
};*/

export const sendMessage = (data, message, country, isSystem = false) => {
  const {
    phone,
    facebook_page_id,
    facebook_messenger_id,
    landing,
    landing_query,
  } = data;

  const form = new FormData();

  form.append('to', phone);

  if (country) {
    form.append('from_num', shortPhoneNumber[country]);
  }

  form.append('fake', isSystem);
  form.append('body', message);

  if (facebook_page_id) {
    form.append('facebook_page_id', facebook_page_id);
  }

  if (facebook_messenger_id) {
    form.append('facebook_messenger_id', facebook_messenger_id);
  }

  if (landing) {
    form.append('landing', landing);
  }

  if (landing_query) {
    form.append('landing_query', landing_query);
  }

  form.append('smstool_name', SMSTOOL_NAME);

  return fetchWrapper(sendMessageUrl(), {
    method: 'POST',
    credentials: 'omit',
    body: form,
  });
};

export const createReminder = (user, message, number, new_order_event) => {
/*
  const {
    phone,
    facebook_page_id,
    facebook_messenger_id,
  } = user.data.credentials;
*/

  const data = {
    crm_id: user.data.id,
    //to: phone,
    fake: true,
    body: message,
    smstool_name: SMSTOOL_NAME,
    order: {
      number: number,
    },
    new_order_event: new_order_event,
  };

  //facebook_page_id && (data.facebook_page_id = facebook_page_id);
  //facebook_messenger_id && (data.facebook_messenger_id = facebook_messenger_id);

  return new Promise((resolve) => {
    setTimeout(resolve, SEND_MESSAGE_TIMEOUT);

    fetch(sendMessageUrl(), {
      method: 'POST',
      credentials: 'omit',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resolve(resp))
      .catch((error) => resolve(error));
  });
};

// ==== Reviews ====
const submitReviewUrl = (orderId) => CRM_HOST_URL + `/api/v3/orders/${orderId}`;

export const submitReview = (orderId, payload) => {
  return (
    fetchWrapper(submitReviewUrl(orderId), {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(payload),
    })
      // .then(r => r.json())
      .then((order) => mapOrder(order))
  );
};

export const get = (url, opts) =>
  fetchWrapper(url, {
    method: 'GET',
    headers: getHeaders(),
    ...opts,
  });
