import React from 'react'
import {Link} from 'react-router-dom'
import Icon from './../../components/SvgIcons'

import './CabinetBasicScreenEmpty.css'

const CabinetBasicScreenEmpty = () => (
    <div className="msg-page cabinet-basic-empty-page">
        <div className="wrapper with-fixed-header">
            <div className="img-wrap">
                <Icon className="svg-icon" iconName="cabinet-basic" />
            </div>
            <h2 className="title">Looks like you haven't used Nerdify before</h2>
            <div className="subtitle">Tell our Nerds what you need help with and get someone working on your task in a matter of minutes!</div>
                <Link className="link type1" to="/neworder">Get Started</Link>
        </div>
    </div>
)

export default CabinetBasicScreenEmpty
