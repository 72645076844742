import React from "react";
import classnames from "classnames";
import ButtonsGroup from "./../ButtonsGroup";
import Button from "./../Button";
import "./select-pages.css";

const SelectPages = (props) => {
    return (
        <div>
            <div className="order-block-item">
                <h3 className="h3">How long should it be?</h3>

                <ButtonsGroup>
                    <Button modificatorByMeasure="measure-item" color="red" active={props.measure === "Words"} onClick={() => props.changeMeasure("Words")}>Words</Button>
                    <Button modificatorByMeasure="measure-item" active={props.measure === "Pages"} onClick={() => props.changeMeasure("Pages")}>Pages</Button>
                </ButtonsGroup>
            </div>

            {props.measure === "Words" && (
                <div className="order-block-item">
                    <h3 className="h3">Number of words</h3>
                    <div className="select-block">
                        <div className={classnames("select-substrate", { error: props.errorField })}>{props.words}</div>
                        <select className="select" value={props.words} onChange={e => props.changeSize(e.target.value)}>
                            {props.wordsRange.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div>
                </div>
            )}

            {props.measure === "Pages" && (
                <div className="order-block-item">
                    <h3 className="h3">Number of pages</h3>
                    <div className="select-block">
                        <div className={classnames("select-substrate", { error: props.errorField })}>{props.pages}</div>
                        <select className="select" value={props.pages} onChange={e => props.changeSize(e.target.value)}>
                            {props.slidesRange.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectPages
