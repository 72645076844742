import React, { useState, useEffect, useCallback } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import Scroll from 'react-scroll'
//import _ from 'lodash'

import { useMobile } from '../../components/Hooks';
import {
  Faq2,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
  AccordionItemHolder,
} from './../../components/Faq'
import { FooterLogin } from '../../components/Footer';

import classnames from 'classnames';

import { getUserSubscriptions } from '../../reducers/subscriptions'
import { userFetchIfNeeded, saveUser } from '../../reducers/user'
import { fetchSubscriptionSesssion, fetchSetupPaymentMethodSesssion } from '../../api'

import { mapServices_premiumAddonsItems } from '../../config'

import './nerdify-subscription.css';
//import regexps from "../../utils/regexps";
import HeaderMain from "../../components/Header/HeaderMain";

export const basicItems = [
  { title: 'Dedicated VIP phone number', subtitle: 'to get the fastest replies' },
  { title: 'Personal success team', subtitle: 'to manage all your requests' },
  { title: 'Free "Prioritize task" service', subtitle: 'applied to all your tasks' },
];

export const premiumItems = [
  { title: 'Dedicated VIP phone number', subtitle: 'to get the fastest replies' },
  { title: 'Personal success team', subtitle: 'to manage all your requests' },
  { title: 'Free "Prioritize task" service', subtitle: 'applied to all your tasks' },
  { title: 'Free “TOP Nerd” service', subtitle: 'applied to all your tasks' },
  { title: 'Reduced time frame', subtitle: 'to get urgent tasks done in 2 hours' },
];

export const primeItems = [
  { title: '$40 added to account monthly', subtitle: 'to use for any of your tasks' },
  { title: 'VIP client status', subtitle: 'with 24/7 personalized support' },
];

export const primePlusItems = [
  { title: '$60 added to account monthly', subtitle: 'to use for any of your tasks' },
  { title: 'VIP client status', subtitle: 'with 24/7 personalized support' },
  { title: 'Free "Prioritize Task" service', subtitle: 'applied to all your tasks' },
];

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const STRIPE_SUBSCRIPTIONS_PRIME_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PRIME_TAG;
const STRIPE_SUBSCRIPTIONS_PRIME_PLUS_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PRIME_PLUS_TAG;
const STRIPE_SUBSCRIPTIONS_BASIC_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_BASIC_TAG;
const STRIPE_SUBSCRIPTIONS_PREMIUM_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PREMIUM_TAG;
const STRIPE_SUBSCRIPTIONS_BASIC_TRIAL_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_BASIC_TRIAL_TAG;
const STRIPE_SUBSCRIPTIONS_PREMIUM_TRIAL_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PREMIUM_TRIAL_TAG;
//const STRIPE_SUBSCRIPTIONS_PREMIUM_ADDONS_TAG = process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_PREMIUM_ADDONS_TAG;

const HOST_URL = process.env.REACT_APP_HOST_URL;
const SUCCESS_URL = HOST_URL + '/nerdify-subscription';
const CANCEL_URL = HOST_URL + '/nerdify-subscription/{CHECKOUT_SESSION_ID}';

const Subscription = ({ match }) => {
  const { isMobile } = useMobile();

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const subscriptions = useSelector(state => state.subscriptions);
  const paidOrdersCount = user?.data?.paid_orders_count;
  const hasInactiveSubscription = user?.data?.tags?.includes("subscription_inactive");

  const [btnDisable, setBtnDisable] = useState(false);
  const [userNickname, setUserNickname] = useState('');
  const [isNicknameSubmitBtnVisible,setNicknameSubmitBtnVisible] = useState(false);

/*
  if (user?.fetched) {
    console.log("user",user);
  }
  if (subscriptions?.isFetched) {
    console.log("subscriptions",subscriptions);
  }
*/

  useEffect(() => {
    if (user.isAuthenticated && !user.fetched) {
      dispatch(userFetchIfNeeded())
    }
    if (user.fetched && user.data.email) {
      dispatch(getUserSubscriptions())
    }
    setUserNickname(user?.data?.nickname);
  }, [user.isAuthenticated, user.data, user.fetched, dispatch]);


  const onChangeNickname = (nickname) => {
    setUserNickname(nickname);
    setNicknameSubmitBtnVisible(true);
  };

  const onSaveUser = useCallback((userNickname) => {
    dispatch(saveUser({nickname: userNickname}));
    // console.log("userNickname:",userNickname);
    setNicknameSubmitBtnVisible(false);
  }, [user, dispatch]);

  const userData = (user.isAuthenticated && user.fetched && user?.data) ?? {};
  const expData = (user.isAuthenticated && user.fetched && user?.data?.exp_data) ?? {};

  //const isBasicPremiumLabel = (expData.subscription === 'crm3549;basic_premium_subscription' || expData.subscription === 'cfp3448;basic_premium_subscription'); //c
  const isBasicPremiumLabel = true;
  //const isPrimeDualLabel = expData.subscription === 'crm3549;prime_dual_subscription';  //b
  const isPrimeDualLabel = false;
  const isPremiumAddonsLabel = expData?.subscription_nerdy === 'cfp4221;addons_subscription';

  const subscriptionsActiveList = userData?.subscriptions_active;

  const hasTrial = (
    (paidOrdersCount >= 2 && !hasInactiveSubscription) ||
    expData?.subscription_nerdy === 'cfp5468;canceled_addons_subscription'
  );

  const isBasicActive = subscriptionsActiveList?.includes('nerdify_basic') || subscriptionsActiveList?.includes('nerdify_basic_trial') || user?.data?.tags?.includes("subscription_nerdify_basic");
  const isPremiumActive = subscriptionsActiveList?.includes('nerdify_premium') || subscriptionsActiveList?.includes('nerdify_premium_trial') || user?.data?.tags?.includes("subscription_nerdify_premium");
  //const isBasicTrialActive = subscriptionsActiveList?.includes('nerdify_basic_trial');
  //const isPremiumTrialActive = subscriptionsActiveList?.includes('nerdify_premium_trial');
  const isPrimeActive = subscriptionsActiveList?.includes('nerdify_prime') || user?.data?.tags?.includes("subscription_nerdify_prime");
  const isPrimePlusActive = subscriptionsActiveList?.includes('nerdify_prime_plus') || user?.data?.tags?.includes("subscription_nerdify_prime_plus");

  //const isUnsubscribe = subscriptions.isFetched && subscriptions.data === null;
  const isPaymentFailedSubscriptions = subscriptions.isFetched && subscriptions.data && ['past_due', 'incomplete'].indexOf(subscriptions.data.status) !== -1;
  const isSubscriptionPastdue = (subscriptions.isFetched && subscriptions.data && ['past_due'].indexOf(subscriptions.data.status) !== -1) || user?.data?.tags?.includes("subscription_pastdue");

  const isSubscriptionActive = isBasicActive || isPremiumActive || isPrimeActive || isPrimePlusActive;  //userData?.has_subscription
  const isPremiumAddonsActive = subscriptionsActiveList?.includes('premium_addons') || user?.data?.tags?.includes("subscription_premium_addons");

  const onCreateSubscription = match.params.sessionId
    ? async () => {
      const stripePromise = loadStripe(match.params.pk_key);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: match.params.sessionId,
      })
    }
    : async (stripeSubscriptionID) => {
    const session = await fetchSubscriptionSesssion(user.data.id, stripeSubscriptionID, SUCCESS_URL, CANCEL_URL);
      const stripePromise = loadStripe(session.pk_key);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: session.id,
      })
    };

  const onCreateSubscriptionPGTW = (subscription_id) => {
    window.location = `${process.env.REACT_APP_PGTW}/s/c${userData.referral_code}/${
      subscription_id
    }?success_url=${SUCCESS_URL}&cancel_url=${CANCEL_URL}`;
  };

  const onChangePaymentPGTW = () => {
    window.location = `${process.env.REACT_APP_PGTW}/m/c${userData.referral_code}?success_url=${SUCCESS_URL}&cancel_url=${CANCEL_URL}`;
  };


  const onUpdatePaymentDetails = match.params.sessionId
    ? async () => {
      const stripePromise = loadStripe(match.params.pk_key);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: match.params.sessionId,
      })
    }
    : async () => {
      const session = await fetchSetupPaymentMethodSesssion(
        user.data.id,
        subscriptions.data.id,
        subscriptions.data.customer,
        SUCCESS_URL,
        CANCEL_URL
      );
      const stripePromise = loadStripe(session.pk_key);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: session.id,
      })
    };

  const isSubscription2020Active = subscriptionsActiveList?.includes("nerdify_top_50");
  if (user.isAuthenticated && user.fetched && isSubscription2020Active) {
    return <Redirect to="/orders" push={false} />
  }
  if (user.isAuthenticated && user.fetched && !((isBasicPremiumLabel || isPrimeDualLabel) && user.data.email)) {
    return <Redirect to="/my-info" push={false} />
  }
  if (!(user.isAuthenticated && user.fetched && subscriptions.isFetched)) {
    return null;
  }

  const PastdueInfo  = ({type}) => {
    return (
      <div className={`pastdue-info pastdue-info_type${type}`}>
        <div className="pastdue-info-title-wrapper">
          <div className="pastdue-info-title">Oh no!</div>
        </div>
        <img className="pastdue-info-icon" src={require(`./img/ic-emoji_hushed_face.svg`)} alt=""/>
        <p className="pastdue-info-msg">It seems like the payment for your
          subscription is <strong>past due.</strong><br/>
          You will be charged within 7 days after
          the last unsuccessful payment.</p>
        <p className="pastdue-info-msg2">In case you are facing issues with the
          recurring payment or want your subscription
          to be renewed earlier - please,
          <Link className="pastdue-info-msg-link" to="/contact-us">contact our Support Team.</Link></p>
      </div>
      )
  };


  if (!(isPremiumAddonsLabel || isPremiumAddonsActive)) {
  return (
    <div className="subscription-page">
      <header className={classnames("header-subscription", isSubscriptionActive ? "header-subscription-active":"")}>
        <div className="wrapper wrapper-type2">
          <Link className="back-link" to="/orders">
            <img className="back-icon" src={require(`./img/back-icon.svg`)} alt=""/>
          </Link>
          {isPrimeDualLabel ?
            <img className="logo prime-logo" src={require(`./img/prime-logo.svg`)} alt=""/>
            :
            <img className="logo club-logo" src={require(`./img/club-logo.svg`)} alt=""/>
          }
        </div>
      </header>

      <div className="wrapper wrapper-type2">
        {!isSubscriptionActive ?
          <>
            <div className="hero">
              {isPrimeDualLabel ?
                <>
                  <div className="title-block">
                    <div className="title">Save up to 54% on your<br/> tasks with Nerdify Prime</div>
                  </div>
                  <img className="hero-prime-img" src={require(`./img/ill-prime@2x.png`)} alt=""/>
                </>
                :
                <>
                  <div className="title-block">
                    {hasTrial && <div className="trial-label">30 days FREE trial</div>}
                    <div className="title">Get premium features<br/> with Nerdify subscription</div>
                  </div>
                  <img className="hero-basic-img" src={require(`./img/ill-basic@2x.png`)} alt=""/>
                </>
              }
            </div>
            {isMobile &&
            <div className="view-plan-block">
              <div className="subtitle">starting from ${isPrimeDualLabel ? '29':'7'}/mo</div>
              <button className="subs-bnt1" onClick={() => Scroll.animateScroll.scrollTo(640)}>view plans</button>
            </div>
            }
            <div className="bg-block">

            <div name="scrollElement">
            {isPrimeDualLabel ? ( // Prime/Prime plus
              <div className="subs-block">
                <div className="subs-item type1">
                  <div className="subs-item-body">
                    <img className="subs-icon" src={require(`./img/glasses-icon.svg`)} alt=""/>
                    <div className="subs-item-main-title type1">Prime</div>
                    <div className="subs-label type1">for just $29/mo</div>
                    <div className="subs-list">
                      {primeItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`prime-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })}
                    </div>
                    <button className="subs-bnt2" disabled={btnDisable} onClick={() => {setBtnDisable(true);onCreateSubscription(STRIPE_SUBSCRIPTIONS_PRIME_TAG)}}>Subscribe now</button>
                  </div>
                  {isMobile && <div className="agree-line1">By clicking the button you agree<br/>
                    to our <Link className="link-underline" to="/terms">Terms</Link> and <Link className="link-underline" to="/refunds">Refund Policy</Link></div>}
                </div>

                <div className="subs-item type1">
                  <div className="subs-item-body">
                    <img className="subs-icon" src={require(`./img/crown-icon.svg`)} alt=""/>
                    <div className="subs-item-main-title type2">Prime Plus</div>
                    <div className="subs-label type2">for just $39/mo</div>
                    <div className="subs-list">
                      {primePlusItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`prime-plus-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })}
                    </div>
                    <button className="subs-bnt2" disabled={btnDisable} onClick={() => {setBtnDisable(true);onCreateSubscription(STRIPE_SUBSCRIPTIONS_PRIME_PLUS_TAG)}}>Subscribe now</button>
                  </div>
                </div>
              </div>

            ) : ( // Basic/Premium [ new naming Premium/Elite CFP-5404]

              <div className="subs-block">
                <div className="subs-item type1">
                  <div className="subs-item-body">
                    <img className="subs-icon" src={require(`./img/glasses-icon.svg`)} alt=""/>
                    <div className="subs-item-main-title type1">Premium</div> {/* ex Basic */}
                    {hasTrial ?
                      <>
                        <div className="subs-label3">for just $7/mo</div>
                        <button className="subs-bnt2 type2" disabled={btnDisable} onClick={() => {
                          setBtnDisable(true);
                          onCreateSubscription(STRIPE_SUBSCRIPTIONS_BASIC_TRIAL_TAG)
                        }}>Start Free Trial</button>
                      </>
                      :
                      <div className="subs-label type1">for just $7/mo</div>
                    }
                    <div className="subs-list">
                      {basicItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`basic-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })}
                    </div>
                    {!hasTrial &&
                    <button className="subs-bnt2 subscribe-btn" disabled={btnDisable} onClick={() => {
                      setBtnDisable(true);
                      onCreateSubscription(STRIPE_SUBSCRIPTIONS_BASIC_TAG)
                    }}>Subscribe now</button>
                    }
                  </div>
                  {isMobile && <div className="agree-line1">By clicking the button you agree<br/>
                    to our <Link className="link-underline" to="/terms">Terms</Link> and <Link className="link-underline" to="/refunds">Refund Policy</Link></div>}
                </div>

                <div className="subs-item type1">
                  <div className="subs-item-body">
                    <img className="subs-icon" src={require(`./img/crown-icon.svg`)} alt=""/>
                    <div className="subs-item-main-title type2">Elite</div> {/* ex Premium */}
                    {hasTrial ?
                      <>
                        <div className="subs-label3">for just $27/mo</div>
                        <button className="subs-bnt2 type2" disabled={btnDisable} onClick={() => {
                          setBtnDisable(true);
                          onCreateSubscription(STRIPE_SUBSCRIPTIONS_PREMIUM_TRIAL_TAG)
                        }}>Start Free Trial</button>
                      </>
                      :
                      <div className="subs-label type2">for just $27/mo</div>
                    }
                    <div className="subs-list">
                      {premiumItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`premium-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })}
                    </div>
                    {!hasTrial &&
                    <button className="subs-bnt2 subscribe-btn" disabled={btnDisable} onClick={() => {
                      setBtnDisable(true);
                      onCreateSubscription(STRIPE_SUBSCRIPTIONS_PREMIUM_TAG)
                    }}>Subscribe now</button>
                    }
                  </div>
                </div>
              </div>
            )}
            </div>

            <div className="agree-line1">By clicking the button you agree <br className="mobile"/>
              to our <Link className="link-underline" to="/terms">Terms</Link> and <Link className="link-underline" to="/refunds">Refund Policy</Link></div>
            {hasTrial ?
              <div className="agree-line2">By activating a trial you'll get to use all perks included in your plan for FREE for 30 days. After the trial period ends, you'll<br className="desktop"/> be automatically charged a respective subscription fee monthly until you cancel. The bonuses and perks added to your<br className="desktop"/> account remain valid till the end of each monthly term after auto-renewal.</div>
              :
              <div className="agree-line2">Your subscription auto-renews at the end of each monthly term and will
                continue until you cancel. The bonuses and perks<br className="desktop"/> added to your account remain
                valid till the end of each monthly term after auto-renewal.</div>
            }
            <div className="contact-line">To learn more about Nerdify subscription plans please <Link className="contact-link" to="/contact-us">сontact us</Link></div>

            </div> {/*END .bg-block*/}
          </>

          : // isSubscriptionActive (active or in past_due/incomplete status)

          <div className="active-subs-block">

          {isSubscriptionPastdue ?
            <PastdueInfo type={1}/>
            :
            <div className="active-subs-title">Your subscription plan includes</div>
          }

            <div className="subs-item type2">
              <div className="subs-item-body">
                <div className="subs-list">
                    {!!isBasicActive &&
                      basicItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`basic-active-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })
                    }
                    {!!isPremiumActive &&
                      premiumItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`premium-active-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })
                    }
                    {!!isPrimeActive &&
                      primeItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`prime-active-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })
                    }
                    {!!isPrimePlusActive &&
                      primePlusItems.map((item, index) => {
                        return (
                          <div className="subs-list-item" key={`prime-plus-active-${index}`}>
                            <img className="check-icon" src={require(`./img/check-icon.svg`)} alt=""/>
                            <div className="subs-item-title">{item.title}</div>
                            <div className="subs-item-subtitle">{item.subtitle}</div>
                          </div>
                        )
                      })
                    }
                </div>
                <div className="subs-label2">for just{' '}
                  {!!isBasicActive && '7'}
                  {!!isPremiumActive && '27'}
                  {!!isPrimeActive && '29'}
                  {!!isPrimePlusActive && '39'}
                  /mo</div>
              </div>
              {(isBasicActive || isPremiumActive) &&
                <div className="subs-name-block">
                  <div className="subs-name-title">Tell us how we can call you</div>
                  <input className="subs-name-input" type="text"
                         placeholder="Choose your nickname"
                         value={userNickname}
                         onChange={(e) => onChangeNickname(e.target.value)}
                  />
                  {!!isNicknameSubmitBtnVisible &&
                    <button className="subs-bnt2 submit-nickname-btn" onClick={() => {onSaveUser(userNickname)}}>Submit</button>
                  }
                </div>
              }
              <div className="subs-item2">
                <div className="subs-item2-title">Nerdify{' '}
                  {!!isBasicActive && 'Premium'}
                  {!!isPremiumActive && 'Elite'}
                  {!!isPrimeActive && 'Prime'}
                  {!!isPrimePlusActive && 'Prime Plus'}
                  {' '}Subscription</div>
                <div className="subs-item2-status">Subscription status:{' '}
                  {isPaymentFailedSubscriptions ?
                    <span className="status failed">Payment failed</span>
                    :
                    <span className="status active">Active</span>
                  }
                </div>
                <button className="subs-bnt2" onClick={onUpdatePaymentDetails}>change payment method</button>
                <div className="subs-item2-subtitle">To manage your subscription please<br/>
                  <Link className="subs-item2-link" to="/contact-us">Contact Us</Link>
                </div>
              </div>

              <div className="agree-line2">Your subscription auto-renews at the end of each monthly term and will continue until you cancel. The bonuses and perks<br className="desktop"/> added to your account remain valid till the end of each monthly term after auto-renewal.</div>

            </div>
          </div>
        }


        <Faq2 theme={"1"}>
          <div className="h3">FAQ</div>
          <Accordion>
            <AccordionItemHolder>
              <AccordionItem index={1}>
                <AccordionHead>
                  <AccordionIcon icons={['plus', 'minus']} />
                  <div className="h6">Can I cancel my subscription?</div>
                </AccordionHead>
                <AccordionBody>
                  <p className="p">You can cancel your subscription at any time. Just contact us via SMS or Facebook Messenger and ask to cancel your subscription.</p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem index={2}>
                <AccordionHead>
                  <AccordionIcon icons={['plus', 'minus']} />
                  <div className="h6">What is auto-renew?</div>
                </AccordionHead>
                <AccordionBody>
                  <p className="p">Your subscription will be automatically renewed each month. The money will be automatically charged by Stripe from your payment method until you cancel. You can cancel your subscription at any time.</p>
                </AccordionBody>
              </AccordionItem>
            </AccordionItemHolder>

            {isPrimeDualLabel ? (
            <AccordionItemHolder>
              <AccordionItem index={3}>
                <AccordionHead>
                  <AccordionIcon icons={['plus', 'minus']} />
                  <div className="h6">How will I be charged for my subscription?</div>
                </AccordionHead>
                <AccordionBody>
                  <p className="p">Each month you will be automatically charged 29 USD (Nerdify Prime subscription plan) or 39 USD (Nerdify Prime Plus subscription plan) from your card. You can cancel your subscription anytime, just drop us a message.</p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem index={4}>
                <AccordionHead>
                  <AccordionIcon icons={['plus', 'minus']} />
                  <div className="h6">What happens to money on my account if I don’t use it all?</div>
                </AccordionHead>
                <AccordionBody>
                  <p className="p">Being Nerdify Prime user each month you will get a special bonus to your balance. You can use this amount to pay for Nerdify services. At the end of each subscription term (1 month) all unused bonuses will be deleted from your account.</p>
                </AccordionBody>
              </AccordionItem>
            </AccordionItemHolder>
              ) : (
              <AccordionItemHolder>
                <AccordionItem index={5}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <div className="h6">How will I be charged for my subscription?</div>
                  </AccordionHead>
                  <AccordionBody>
                    <p className="p">Each month you will be automatically charged 7 USD (Nerdify Premium subscription plan) or 27 USD (Nerdify Elite subscription plan) from your card. You can cancel your subscription anytime, just drop us a message.</p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={6}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <div className="h6">What does the personal success team mean?</div>
                  </AccordionHead>
                  <AccordionBody>
                    <p className="p">Every client enrolled in the Nerdify subscription plan gets 2 dedicated support managers and a QA team who will provide personalized 24/7 assistance upon request. It becomes their top priority to solve any problems that may arise and they always handle your requests first.</p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>
              )}
          </Accordion>
        </Faq2>


      </div>
      <FooterLogin />
    </div>
  )

  } else { // isPremiumAddonsLabel (CFP-4223) or isPremiumAddonsActive
    return (
      <div className="premium-addons">
        <HeaderMain />

        <div className="wrapper wrapper-type3">
          {!isPremiumAddonsActive ?
            <div className="no-subs-block">
              <h1 className="title1">
                Get exclusive features with <strong>Nerdify Elite</strong>
              </h1>
              <div className="subs-list">
                <div className="label">$7.00/mo</div>
                {mapServices_premiumAddonsItems.map((item, index) => {
                  return (
                    <div className="subs-list-item" key={`premium-added-no-subs-${index}`}>
                      <img className="check-icon" src={require(`./img/ic-check1.svg`)} alt=""/>
                      <div className="subs-item-msg">
                        <div className="subs-item-title">{item.title}</div>
                        <div className="subs-item-subtitle">{item.desc}</div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <button className="subs-bnt3" disabled={btnDisable} onClick={() => {
                setBtnDisable(true);
                onCreateSubscriptionPGTW('premium_addons')
                //onCreateSubscription(STRIPE_SUBSCRIPTIONS_PREMIUM_ADDONS_TAG)
              }}>Subscribe</button>
              <div className="agree-line agree-line3">By clicking the button you agree <br className="mobile"/>
                to our <Link className="link-underline" to="/terms">Terms</Link> and <Link className="link-underline" to="/refunds">Refund Policy</Link></div>
              <div className="agree-line agree-line4">Your subscription auto-renews at the end of each monthly term and will continue until you cancel. The bonuses and perks added to your account remain valid till the end of each monthly term after auto-renewal.</div>
            </div>

          : // isPremiumAddonsActive
          <div className="is-subs-block">

            {isSubscriptionPastdue ?
              <PastdueInfo type={2}/>
              :
              <h1 className="title2">Your subscription<br/> plan includes</h1>
            }

            <div className="subs-list2">
              {mapServices_premiumAddonsItems.map((item, index) => {
                return (
                  <div className="subs-list-item" key={`premium-added-no-subs-${index}`}>
                    <img className="check-icon" src={require(`./img/ic-check2.svg`)} alt=""/>
                    <div className="subs-item-msg">
                      <div className="subs-item-title">{item.title}</div>
                      <div className="subs-item-subtitle">{item.desc}</div>
                    </div>
                  </div>
                )
              })}
              <div className="label2">$7/mo only</div>
            </div>

            <div className="subs-item3">
              <div className="subs-item3-title">Nerdify Elite Subscription</div>
              <div className="subs-item3-status">Subscription status:{' '}
                {isPaymentFailedSubscriptions ?
                  <span className="status failed">Payment failed</span>
                  :
                  <span className="status active">Active</span>
                }
              </div>
              <button className="subs-bnt2" onClick={onChangePaymentPGTW}>change payment method</button>
              <div className="subs-item3-subtitle">To manage your subscription please<br/>
                <Link className="subs-item3-link" to="/contact-us">Contact Us</Link>
              </div>
            </div>

            <div className="agree-line agree-line5">Your subscription auto-renews at the end of each monthly term and will continue until you cancel. The bonuses and perks added to your account remain valid till the end of each monthly term after auto-renewal.</div>
          </div>
        }

          <Faq2 theme={"1"}>
            <div className="h3">FAQ</div>
            <Accordion>
              <AccordionItemHolder>

                <AccordionItem index={1}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <div className="h6">Can I cancel my subscription?</div>
                  </AccordionHead>
                  <AccordionBody>
                    <p className="p">You can cancel your subscription at any time. Just contact us via SMS or Facebook Messenger and ask to cancel your subscription.</p>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem index={2}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <div className="h6">What is auto-renew?</div>
                  </AccordionHead>
                  <AccordionBody>
                    <p className="p">Your subscription will be automatically renewed each month. The money will be automatically charged by Stripe from your payment method until you cancel. You can cancel your subscription at any time.</p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>

              <AccordionItemHolder>
                <AccordionItem index={5}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <div className="h6">How will I be charged for my subscription?</div>
                  </AccordionHead>
                  <AccordionBody>
                    <p className="p">Each month you will be automatically charged 7 USD from your card. You’re able to change your card right in the account once you’re subscriber.</p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>
          </Accordion>
          </Faq2>

        </div>

        <FooterLogin />
      </div>
    )
  }
};

export default Subscription;
