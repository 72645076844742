export const pageSizeInPages = 1
export const pageSizeInWords = 300
export const pageSizeInSlides = 1
export const pageSizeInMinutes = 60

export const minOrderInPages = 1
export const maxOrderInPages = 50

export const minPageSizeInPages = minOrderInPages
export const minPageSizeInWords = 100
export const minPageSizeInMinutes = 15
export const minPageSizeInSlides = 1

export const converter = {
  convertPagesToWords: (pages) => pages * pageSizeInWords,
  convertPagesToSlides: (pages) => pages * pageSizeInSlides,
  convertPagesToMinutes: (pages) => pages * pageSizeInMinutes,
  convertSourcesToPages: (pages) => pages,
  convertWordsToPages: (words) => Math.ceil(words / pageSizeInWords),
  convertWordsToSlides: (words) => converter['convertWordsToPages'](words) * pageSizeInSlides,
  convertWordsToMinutes: (words) => converter['convertWordsToPages'](words) * pageSizeInMinutes,
  convertSlidesToPages: (slides) => slides * pageSizeInSlides,
  convertSlidesToWords: (slides) => converter['convertSlidesToPages'](slides) * pageSizeInWords,
  convertSlidesToMinutes: (slides) => converter['convertSlidesToPages'](slides) * pageSizeInMinutes,
  convertMinutesToPages: (minutes) => Math.ceil(minutes / pageSizeInMinutes),
  convertMinutesToWords: (minutes) => converter['convertMinutesToPages'](minutes) * pageSizeInWords,
  convertMinutesToSlides: (minutes) => converter['convertMinutesToPages'](minutes) * pageSizeInSlides
}

export function convertWithSize(newSize, {measure}) {
  const newSizeInPages = measure === 'Pages' ? newSize : converter[`convert${measure}ToPages`](newSize)
  const newSizeInWords = measure === 'Words' ? newSize : converter.convertPagesToWords(newSizeInPages)
  const newSizeInMinutes = measure === 'Minutes' ? newSize : converter.convertPagesToMinutes(newSizeInPages);


  return {
    measure,
    pages: newSizeInPages,
    words: newSizeInWords,
    slides: converter.convertPagesToSlides(newSizeInPages),
    minutes: newSizeInMinutes,
  }
}

export function convertMinutesTo_hm(minutes) {
  const h = Math.floor(minutes / 60)
  const m = minutes % 60
  return {hours: h, minutes: m}
}

export function convert_hmToMinutes(hours, minutes) {
  return hours * 60 + minutes
}

export function round(value, decimals = 2) {
  let _value = Number(value);
  _value = Number(_value.toFixed(decimals + 1));
  return Number(Math.round(_value + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

export function getSizeText(orderSize) {
  if (!orderSize) return null;
  const unitSizes = orderSize.split(' ')[0];
  const measure = orderSize.split(' ')[1];
  let mappedUnit;

  if (measure === 'minutes' && unitSizes >=60) {
    const hours = Math.trunc(unitSizes/60);
    const minutes = unitSizes - hours*60;
    if (!minutes) {
      return `${hours} hour${hours !== 1 ? 's':''}`
    } else {
      return `${hours} hour${hours !== 1 ? 's':''} ${minutes} minute${minutes !== 1 ? 's':''}`;
    }
  }

  if (unitSizes === '1') {
    switch (measure) {
      case 'minutes':
        mappedUnit = 'minute';
        break;
      case 'slides':
        mappedUnit = 'slide';
        break;
      case 'words':
        mappedUnit = 'word';
        break;
      case 'sources':
        mappedUnit = 'source';
        break;
      case 'hours':
        mappedUnit = 'hour';
        break;
      default:
        mappedUnit = 'page';
    }
    return `${unitSizes} ${mappedUnit}`;
  }
  return orderSize;
}
