import React, { Component } from 'react';
import Icon from './../SvgIcons';
import classnames from "classnames";
import 	"./backToTopButton.css";

class BackToTopButton extends Component {
    constructor() {
        super();

        this.state = {
            intervalId: 0,
            buttonActive: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop(delayInMs) {
        let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        this.setState({ intervalId: intervalId });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll(event) {
		if (window.pageYOffset > window.innerHeight)
			this.setState({ buttonActive: true });
		else
			this.setState({ buttonActive: false });
    }


    render() {
        return (
			<button className={classnames("back-to-top-button", { "back-to-top-button_active": this.state.buttonActive })} onClick={ () => { this.scrollToTop(20); }}>
				<Icon className="svg-icon back-to-top-button__svg" iconName="back-to-top"/>
			</button>
        );
    }
}

export default BackToTopButton;