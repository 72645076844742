import React from 'react';
import { PGTW_DETAILS } from '../../config';

const { url, currency, company, vendor } = PGTW_DETAILS;

const PgtwExternalForm = props => {
  const {
    details,
    userId,
    amount,
    buttonText,
    className,
    orderId,
    onSubmit,
    disabled,
    paymentAccountStripe
  } = props;

  const successUrl = `${window.location.origin}/orders/${orderId}/tips-success/${amount}`;
  const cancelUrl = `${window.location.origin}/orders/${orderId}/tips`;

  return (
    <form
      action={url || ''}
      method="POST"
      onSubmit={onSubmit ? onSubmit : () => {}}
    >
      <input type="hidden" name="details" value={details || ''} />
      <input type="hidden" name="client_id" value={userId || ''} />
      <input type="hidden" name="amount" value={amount || ''} />
      <input type="hidden" name="currency" value={currency || ''} />
      <input type="hidden" name="company" value={company || ''} />
      <input type="hidden" name="vendor" value={vendor || ''} />
      <input
        type="hidden"
        name="payment_method_name"
        value={paymentAccountStripe || ''}
      />

      <input type="hidden" name="success_url" value={successUrl} />
      <input type="hidden" name="cancel_url" value={cancelUrl} />

      <button type={'submit'} disabled={disabled} className={className}>
        {buttonText}
      </button>
    </form>
  );
};

export default PgtwExternalForm;
