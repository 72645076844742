import { createSelector } from 'reselect';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { cfpMapOrder, cfpMapFile } from './map';

export function useCfpEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export const cfpOrderSelector = createSelector(
  (state) => state.order,
  (order) => {
    return {
      ...order,
      data: cfpMapOrder(order.data),
    };
  }
);

export const cfpOrdersSelector = createSelector(
  (state) => state.orders,
  (orders) => {
    const data = orders.data;

    return {
      ...orders,
      data: _.filter(_.map(data, cfpMapOrder), (item) => !item.isDeleted),
    };
  }
);

export const cfpFilesSelector = createSelector(
  [(state) => state.orderFiles, cfpOrderSelector],
  (files, order) => {
    const orderData = order.data;
    const { isCanShowRating } = orderData;
    const { isFetching, error, isDownloaded } = files;

    const filesByWriter =
      files.data
        .map((file) => cfpMapFile(file))
        .filter(
          (item) =>
            item.type === 'file_draft_paper' || item.type === 'file_final_draft'
        ) || [];

    const filesByWriterLast =
      _.orderBy(filesByWriter, ['created_at'], ['desc'])[0] || {};

    const filesByClient = files.data.filter((file) => {
      const { type, deleted } = file;

      return (
        (type === 'order_form' || type === 'client_revision_request_file') &&
        deleted === false
      );
    });

    return {
      isFetching,
      error,
      filesByWriter,
      filesByClient,
      filesByWriterLast,
      isCanShowRating,
      isDownloaded,
    };
  }
);

export const cfpWriterFilesSelector = createSelector(
  [cfpFilesSelector],
  (files) => {
    const { isCanShowRating, filesByWriter, isFetching, error } = files;

    const isFilesForRating = filesByWriter
      .filter(
        (item) => !item.revision_accept && !item.type === 'file_draft_paper'
      )
      .some((item) => item.client_accept);

    return {
      isFetching,
      error,
      writerFiles: filesByWriter.map((item) => {
        return {
          ...item,
          isCanShowRatingForFiles: !isFilesForRating && isCanShowRating,
        };
      }),
    };
  }
);

export const cfpClientFilesSelector = createSelector(
  [cfpFilesSelector],
  (files) => {
    const { filesByClient, isFetching, error } = files;

    return {
      isFetching,
      error,
      clientFiles:
        _.orderBy(
          filesByClient.filter((item) => item.type === 'order_form'),
          ['created_at'],
          ['asc']
        ) || [],
    };
  }
);
