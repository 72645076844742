import React, { Component } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import './index.css';

export class AccordionHead extends Component {

  render() {
    const { children, index, ...rest } = this.props;

    return (
      <div className='accordion__heading' onClick={rest.onClickToggleActive}>
        <div className='accordion__toggle question'>
          {children}
        </div>
      </div>
    )
  }
}

export class AccordionBody extends Component {
  render() {
    const { children, isActive } = this.props;

    return (
      <div className={classnames('accordion__body', {
        'accordion__body_active': isActive,
      })}>
        <div className='accordion__inner'>
          {children}
        </div>
      </div>
    )
  }
}

export class AccordionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndexes: []
    }

    this.onClickToggleActive = this.onClickToggleActive.bind(this);
  }

  onClickToggleActive(e) {
    const { activeIndexes } = this.state;

    const {
      index
    } = this.props;

    const currentIndex = Number(index);
    const isSameIndex = activeIndexes.indexOf(currentIndex) !== -1;

    this.setState({
      activeIndexes: isSameIndex
        ? activeIndexes.filter(item => item !== currentIndex)
        : this.setActive(currentIndex)
    })
  }

  setActive(index) {
    const { forcedClosure } = this.props;
    const { activeIndexes } = this.state;

    if (forcedClosure) {
      activeIndexes.splice(0, 1, index);
      return activeIndexes;
    }

    return [].concat(activeIndexes, index);
  }

  render() {
    const { children, index } = this.props;

    const {
      activeIndexes
    } = this.state;

    const isActive = activeIndexes.indexOf(index) !== -1;

    return (
      <div
        className={classnames('accordion__item', {
          'accordion__item_active': isActive,
        })}>
        {React.Children.map(children, (child) => {
          switch (child.type) {
            case AccordionBody:
              return React.cloneElement(child, {
                isActive,
              });

            case AccordionHead:
              return React.cloneElement(child, {
                onClickToggleActive: this.onClickToggleActive,
              });
            default:
              return React.cloneElement(child);
          }
        })}
      </div>
    )
  }
}

export class AccordionIcon extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="accordion__icon">
        <LazyLoad>
          {children}
        </LazyLoad>
      </div>
    )
  }
}

export const AccordionItemHolder = (props) => {
  return (
    <div className="accordion__item-holder">
      {props.children}
    </div>
  )
}

export default class Accordion extends Component {
  render() {
    const { className } = this.props;

    return (
      <div className={classnames('accordion', className)}>
        {this.props.children}
      </div>
    )
  }
}
