import { mapServices } from '../config';

export function orderAvailableServices(data) {
  const { jobType, services, deadline_left, waat } = data;

  return Object.keys(mapServices).reduce((res, val) => {
    switch (val) {
      case 'adv_plagreport':
        if (!services.adv_plagreport.refunded) {
          res = { ...res, [val]: mapServices[val] };
        }
        break;

      case 'top_writer':
        if (
          !services.client_recomended_writer.added &&
          !waat &&
          !services.top_writer.refunded
        ) {
          res = { ...res, [val]: mapServices[val] };
        }
        break;

      case 'draft_needed':
        if (deadline_left >= 6 && !waat && !services.draft_needed.refunded) {
          res = { ...res, [val]: mapServices[val] };
        }
        break;

      case 'prioritize_my_task':
        if (!waat && !services.prioritize_my_task.refunded) {
          res = { ...res, [val]: mapServices[val] };
        }
        break;

      default:
        res = { ...res, [val]: mapServices[val] };
    }
    return res;
  }, {});
}

export function isNotActiveServicesAvailable(data) {
  const { services } = data;

  let serviceFlag = false;

  {
    Object.keys(orderAvailableServices(data)).map((item) => {
      const service = services[item];
      if (!service.active) serviceFlag = true;
    });
  }
  return serviceFlag;
}
