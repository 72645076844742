import React, { Component } from 'react'
//import {Link} from 'react-router-dom'
import Icon from './../SvgIcons'
//require '/HeaderLogin/header-login.css'

export default class HeaderLogin extends Component {
    render() {
        return (
            <header className='header-login with-back-link'>
                <div className="wrapper clearfix">
                    <div className="img-wrap">
                        <button type="button" className="back-link" onClick={this.props.history.goBack}><Icon className="svg-icon" iconName="back-arrow" /></button>
                        {/*<Link className="back-link" to="/login"><Icon className="svg-icon" iconName="back-arrow" /></Link>*/}
                        <Icon className="svg-icon" iconName="nerdify-logo" />
                    </div>
                </div>
            </header>
        )
    }
}
