import _ from 'lodash';

import {
  getUser,
  patchUser,
  postUser,
  requestToken,
  applyToken,
  createUser,
} from './../api';

import Auth from './../api/auth';

const STATE_USER = 'STATE_USER';
const UPDATE_USER = 'UPDATE_USER';
const FETCHED_USER = 'FETCHED_USER';
const REQUEST_TOKEN_USER = 'REQUEST_TOKEN_USER';
const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
const CLEAR_USER = 'CLEAR_USER';
const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_RECEIVE = 'USER_CREATE_RECEIVE';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const FETCHED_USER_ERROR = 'FETCHED_USER_ERROR';
export const FETCHED_USER_INVALIDATE = 'FETCHED_USER_INVALIDATE';

export const fetchUser = () => (dispatch) => {
  dispatch({ type: STATE_USER, payload: { loading: true } });

  getUser()
    .then((user) =>
      dispatch({
        type: FETCHED_USER,
        payload: user,
      })
    )
    .catch((error) => {
      dispatch({ type: FETCHED_USER_ERROR, error });
    });
};

const userShouldFetch = (state) => {
  const userState = state.user;

  const { loading } = userState;

  if (loading) {
    return false;
  }

  if (!Object.keys(_.get(userState, 'data', {})).length) {
    return true;
  }

  return userState.isDidInvalidate;
};

export const userFetchIfNeeded = () => (dispatch, getState) => {
  if (!userShouldFetch(getState())) {
    return Promise.resolve();
  }
  return dispatch(fetchUser());
};

export const userInvalidate = () => (dispatch) =>
  dispatch({ type: FETCHED_USER_INVALIDATE });

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateStateUser = (payload) => ({
  type: STATE_USER,
  payload,
});

export const saveUser = (user) => (dispatch) => {
  return patchUser(user)
    .then((user) => dispatch(updateUser(user)))
    .catch((error) => {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: error,
      });
    });
};

export const savePostUser = (user) => (dispatch) => {
  return postUser(user)
    .then((user) => dispatch(updateUser(user)))
    .catch((error) => {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: error,
      });
    });
};

export const userRequestToken = ({ factor, value }) => (dispatch) => {
  return requestToken({ factor, value });
};

export const userApplyToken = ({ factor, value, code }, after = {}) => (
  dispatch
) => {
  return applyToken({ factor, value, code })
    .then((resp) => {
      Auth.authenticate(resp.jwt);

      dispatch({
        type: AUTHENTICATED_USER,
        payload: {
          isAuthenticated: Auth.isAuthenticated,
          session: resp.jwt,
          factor: null,
          factorValue: null,
        },
        after,
      });

      return resp;
    })
};

export const userCreate = (data, after = {}) => (dispatch) => {
  dispatch({
    type: USER_CREATE_REQUEST,
  });

  const mergedData = Object.assign(data, { _uniq: true });

  let formData = new FormData();
  Object.keys(mergedData).forEach((item, key) =>
    formData.append(item, mergedData[item])
  );

  return createUser(formData)
    .then((resp) => {
      dispatch({
        type: USER_CREATE_RECEIVE,
        payload: resp,
        after,
      });
    })
    .catch((error) => {
      dispatch({
        type: USER_CREATE_ERROR,
        error: error,
      });
    });
};

export const userClearSession = () => (dispatch) => {
  localStorage.removeItem('_is_subscription_banner_closed');

  Auth.signout();
  dispatch({
    type: CLEAR_USER,
  });
};

export const userRestoreSession = (session) => (dispatch) => {
  Auth.authenticate(session);

  dispatch({
    type: AUTHENTICATED_USER,
    payload: {
      isAuthenticated: Auth.isAuthenticated,
      session: session,
    },
  });
};

const initState = {
  data: {},
  isAuthenticated: false,
  fetched: false,
  loading: false,
  error: null,
  isDidInvalidate: false,
  session: null,
  factor: null,
  factorValue: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case REQUEST_TOKEN_USER:
    case AUTHENTICATED_USER:
    case STATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER:
      return {
        ...initState,
      };
    case FETCHED_USER:
      return {
        ...state,
        data: action.payload,
        fetched: true,
        loading: false,
      };
    case FETCHED_USER_ERROR:
      return {
        ...state,
        fetched: false,
        loading: false,
        error: action.error,
      };
    case FETCHED_USER_INVALIDATE:
      return {
        ...state,
        isDidInvalidate: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
