import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import ReactGA from 'react-ga';
import { userFetchIfNeeded, saveUser, updateUser } from '../../reducers/user';
import Icon from './../SvgIcons';
import './refShareBlock.css';

class RefShareBlock extends Component {
  render() {
    if (!this.props.user.fetched) {
      return null
    }
    const profile_referral_code = this.props.user.data.profile_referral_code;

    return (
      <div>
        <div className="title_share">Share Nerdify with friends</div>
        <div className="ref-msg-block">
          <div className="ref-msg-block__title"><span className="ref-msg-block__title_msg">You can use this message</span></div>
          <div className="ref-msg-block__info">Check it! These guys can help with
                        any homework you want<br className="desktop" /> to get done.
                        Here you can get $20 OFF:</div>
          <a className="ref-msg-block__link" href={"https://gonerdify.com/r/" + profile_referral_code} target="_blank" rel="noopener noreferrer" onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Ref_link_click', label: 'Ref_personal_link_click' }) }}>https://gonerdify.com/r/{profile_referral_code}</a>
        </div>

        <ul className="ref-social-block">
          <li className="ref-social-block__item ref-social-block__item_link-copy">
            <CopyToClipboard text={"https://gonerdify.com/r/" + profile_referral_code}>
              <div className="ref-social-block__link ref-social-block__link_copy" onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_text' }) }}>Copy link</div>
            </CopyToClipboard>
          </li>
          <li className="ref-social-block__item desktop" onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_email' }) }}>
            <EmailShareButton className="ref-social-block__link ref-social-block__link_email" subject="Check it!" body={"Check it! These guys can help with any homework you want to get done. Here you can get $20 OFF: https://gonerdify.com/r/" + profile_referral_code} url={"https://gonerdify.com/r/" + profile_referral_code}>
              <Icon className="ref-social-block__icon" iconName="email-icon2" />
            </EmailShareButton>
          </li>
          <li className="ref-social-block__item" onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_fb' }) }}>
            <FacebookShareButton className="ref-social-block__link ref-social-block__link_fb" quote="Check it! These guys can help with any homework you want to get done. Here you can get $20 OFF:" url={"https://gonerdify.com/r/" + profile_referral_code}>
              <Icon className="ref-social-block__icon" iconName="fb-icon2" />
            </FacebookShareButton>
          </li>
          <li className="ref-social-block__item" onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_twitter' }) }}>
            <TwitterShareButton className="ref-social-block__link ref-social-block__link_twitter" title="Check it! These guys can help with any homework you want to get done. Here you can get $20 OFF:" url={"https://gonerdify.com/r/" + profile_referral_code}>
              <Icon className="ref-social-block__icon" iconName="twitter-icon" />
            </TwitterShareButton>
          </li>
          <li className="ref-social-block__item mobile">
            <a className="ref-social-block__link ref-social-block__link_msg" href={"fb-messenger://share/?link=https%3A%2F%2Fgonerdify.com%2Fr%2F" + profile_referral_code} onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_msg' }) }}>
              <Icon className="ref-social-block__icon" iconName="msg-icon2" />
            </a>
          </li>
          <li className="ref-social-block__item mobile">
            <a className="ref-social-block__link ref-social-block__link_sms" href={"sms:?&body=Check%20it%21%20These%20guys%20can%20help%20with%20any%20homework%20you%20want%20to%20get%20done.%20Here%20you%20can%20get%20%2410%20OFF%3A%20https%3A//gonerdify.com/r/" + profile_referral_code} onClick={() => { ReactGA.event({ category: 'Ref_cabinet_events', action: 'Share_button_click', label: 'share_button_click_sms' }) }}>
              <Icon className="ref-social-block__icon" iconName="sms-icon" />
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user
  }),
  (dispatch, ownProps) => ({
    userFetchIfNeeded: () => dispatch(userFetchIfNeeded()),
    saveUser: (payload) => dispatch(saveUser(payload)),
    updateUser: (payload) => dispatch(updateUser(payload)),
  })
)(RefShareBlock)
