import { getPricing } from './../api'

const RECEIVE_PRICING = 'RECEIVE_PRICING'

export const fetchPricing = () => (dispatch, getState) => {
    const pricing = getState().pricing;
    if (pricing) {
        return;
    }
    getPricing()
        .then(json => {
            dispatch({ type: RECEIVE_PRICING, payload: json })
        })
}

export default (state = null, action) => {
    switch (action.type) {
        case RECEIVE_PRICING:
            return action.payload
        default:
            return state
    }
}
