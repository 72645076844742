export default function getMessageDiscountInfo(order) {
    let discountMessage = null;
    const { value, quote_amount, cashback_amount, discount_type } = order.discount_info;
    
    if (discount_type === 'addon') {
      discountMessage = `${value}`;
    }

    if (quote_amount) {
      discountMessage = `${quote_amount} OFF`;
    }

    if (cashback_amount) {
      discountMessage = `${cashback_amount} to balance`;
    }

    return discountMessage;
}
