import React from 'react';
import classnames from "classnames";

const SelectSlides = props => (
    <div className="order-block-item">
        <h2 className="h2">How many slides</h2>
        <div className="select-block">
            <div className={classnames("select-substrate", { error: props.errorField })}>{props.slides}</div>
            <select className="select" value={props.slides} onChange={e => props.changeSize(e.target.value)}>
                {props.slidesRange.map(value => <option key={value} value={value}>{value}</option>)}
            </select>
        </div>
    </div>
)

export default SelectSlides
