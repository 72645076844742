import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { userClearSession } from "./../../reducers/user";

class Logout extends Component {

    componentDidMount() {
        this.props.userClearSession();
    }

    render() {
        if (!this.props.user.isAuthenticated) {
          return <Redirect to="/login" push={false} />;
        }
        return (
            <div className="login-page">
                <div className="wrapper">
                    <div className="frame-no-border">
                        <h2 className="title">Logout...</h2>
                        <div className="login-contact">
                            Have never used Nerdify or have<br /> trouble signing in? <Link className="login_link" to="/contact-us">Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
    }),
    (dispatch, ownProps) => ({
        userClearSession: () => dispatch(userClearSession()),
    })
)(Logout);
