import React from 'react';
import {Link} from 'react-router-dom'
import Icon from './../../components/SvgIcons'
import './EmailSent.css'

const EmailSent = (props) => (
    <div className="msg-page email-sent-page">
        <div className="wrapper">
            <div className="frame-no-border">
                <div className="img-wrap">
                    <Icon className="svg-icon" iconName="email-sent" />
                </div>
                <h2 className="title">Please check your email</h2>
                <div className="subtitle">We have just sent a link you can use to access your account</div>
                <Link className="link type1" to="/login">Got it!</Link>
            </div>
        </div>
    </div>
)

export default EmailSent
