import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import classnames from "classnames";
import PhoneInput from './../../components/PhoneInput'
import { userRequestToken, userApplyToken } from "./../../reducers/user";
import LoginWithPhoneStep2 from './../LoginWithPhoneStep2';
import regexps from "./../../utils/regexps";


class LoginWithPhone extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      result: null,
      codeError: null,
    };
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onRequestToken = this.onRequestToken.bind(this);
    this.onSubmitCode = this.onSubmitCode.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
  }

  onChangePhone(phone) {
    this.setState({ phone, result: null });
  }

  onRequestToken(e) {
    e.preventDefault();
    const error = !regexps.phone(this.state.phone);
    if (error) {
      this.setState({ result: false });
      return
    }
    this.props.userRequestToken({
      factor: 'phone',
      value: this.state.phone,
    })
      .then(ok => this.setState({ result: ok }));
  }

  onResendCode() {
    this.setState({ codeError: null });
    this.props.userRequestToken({
      factor: 'phone',
      value: this.state.phone,
    })
      .then(ok => this.setState({ result: ok }));
  }

  onSubmitCode(code) {
    this.props.userApplyToken({
      factor: 'phone',
      value: this.state.phone,
      code: code,
    })
      .catch(error => {
        this.setState({
          codeError: error && error.text
        });
      });
  }

  render() {
    if (this.props.user.isAuthenticated) {
      return <Redirect to="/orders" push={false} />
    }
    if (this.state.result === true) {
      return <LoginWithPhoneStep2
        onSubmitCode={this.onSubmitCode}
        phone={this.state.phone}
        codeError={this.state.codeError}
        onResendCode={this.onResendCode}
      />
    }
    const error = this.state.result === false;
    const phoneError = regexps.phone(this.state.phone);
    return (
      <div className="login-page step1">
        <div className="wrapper">
          <div className="frame-no-border">
            <h2 className="title">Phone login</h2>
            <form className={classnames("form-login", error && "error")}>
              <div className="subtitle">
                Please type in the phone number you have previously used to contact Nerdify and we’ll send you an SMS with authorization code.
                            </div>
              <div className="subtitle error-msg">
                Looks like you have mistyped your phone number. Please check and re-enter it.
                            </div>
              <ul className="link-block">
                <li className="input-item">
                  <PhoneInput onChange={this.onChangePhone} />
                </li>
                <li>
                  <button className="list-link" onClick={this.onRequestToken} disabled={!phoneError}>
                    <span>Request the code</span>
                  </button>
                </li>
              </ul>
              <div className="notice">SMS messages are sent by Nerdify. One message per request. Message and data rates may apply. Reply HELP for help and STOP to opt-out.<br className="mobile"/></div>

              <div className="login-contact">
                You can also <Link className="login_link_underline" to="/login/email">log in with email</Link><br/>
                Have trouble signing in? <Link className="login_link" to="/contact-us">Contact us</Link></div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
  (state, ownProps) => ({
    user: state.user,
  }),
  (dispatch, ownProps) => ({
    userRequestToken: (payload) => dispatch(userRequestToken(payload)),
    userApplyToken: (payload) => dispatch(userApplyToken(payload)),
  })
)(LoginWithPhone);


