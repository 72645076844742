import React from 'react';
import {Link} from 'react-router-dom'
import Icon from './../../components/SvgIcons'
import './EmailNotSent.css'

const EmailNotSent = () => (
    <div className="msg-page email-not-send-page">
        <div className="wrapper">
            <div className="frame-no-border">
                <div className="img-wrap">
                    <Icon className="svg-icon" iconName="email-not-send" />
                </div>
                <h2 className="title">Sorry, we don't recognize this email</h2>
                <div className="subtitle">Please check that this is the email you have used to contact Nerdify before, or message us to get help</div>
                <Link className="link type1" to="/">Got it!</Link>
            </div>
        </div>
    </div>
)

export default EmailNotSent
