import React, { useState } from 'react'
import { useSelector } from "react-redux";
import classnames from 'classnames'
import { useHistory, Link } from 'react-router-dom'
import { useMobile } from '../../components/Hooks';

import MainMenu from './../MainMenu'
import { trackClicks, useTrackValues } from '../../lrtracker';
import Icon from './../SvgIcons'
import  './header-main.css'

const SubscriptionBanner = ({onClose, hasTrial, isPremiumAddonsLabel}) => {
  const { isMobile } = useMobile();
  const history = useHistory();

  useTrackValues('banner', 'NerdifySubscription', {
    context: { stage: 'subscription-offer' },
  });

  const onClickClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const onClickRedirect = () => {
    onClose();
    trackClicks('target', 'NerdifySubscriptionBanner', {
      context: { stage: 'subscription-offer' },
    })();
    history.push('/nerdify-subscription');
  };

  return (
    <>
      {isPremiumAddonsLabel ?

    <div className="subscription-banner" onClick={ isMobile ? onClickRedirect : null }>
      <div className="wrapper subscription-banner-wrapper">
        <div className="subscription-banner-msg2">Upgrade with <span className="subscription-banner-msg-highlight2">Nerdify Premium</span></div>
        <button className="subscription-banner-btn" onClick={ !isMobile ? onClickRedirect : null }>
          View plan
        </button>
        <button className="subscription-banner-close-btn" onClick={ onClickClose }>
          <Icon className="svg-icon" iconName="close-icon" />
        </button>
      </div>
    </div>

        :

    <div className="subscription-banner" onClick={ isMobile ? onClickRedirect : null }>
      <div className="wrapper subscription-banner-wrapper">
        <div className="subscription-banner-msg">Upgrade with <span className="subscription-banner-msg-highlight">Nerdify Premium!</span></div>
        <button className="subscription-banner-btn" onClick={ !isMobile ? onClickRedirect : null }>
          {hasTrial ?
            <>Start free trial</>
            :
            <>View plans</>
          }
        </button>
        <button className="subscription-banner-close-btn" onClick={ onClickClose }>
          <Icon className="svg-icon" iconName="close-icon" />
        </button>
      </div>
    </div>
      }
    </>
  )
};

const HeaderMain = (props) => {
  const {data} = useSelector(state => state.user);

  const paidOrdersCount = data?.paid_orders_count;
  const hasInactiveSubscription = data?.tags?.includes("subscription_inactive");
  const hasTrial = (
    (paidOrdersCount >= 2 && !hasInactiveSubscription) ||
    data?.exp_data?.subscription_nerdy === 'cfp5468;canceled_addons_subscription'
  );

  //const isSubscriptionsActive = data && data.subscriptions_active && data.subscriptions_active.length;
  const hasSubscription = data?.tags?.includes("subscription_inactive") || data?.tags?.includes("subscription_active");
  const hasCompletedOrder = data?.has_completed_order;
  //const isBasicPremiumLabel = data?.exp_data?.subscription === 'cfp3448;basic_premium_subscription';
  const isBasicPremiumLabel = true;
  const isPremiumAddonsLabel = data?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';

  const [isSubscriptionBannerClosed, setIsSubscriptionBannerClosed] = useState(
    localStorage.getItem('_is_subscription_banner_closed')
  );

  const onClickCloseSubscriptionBanner = () => {
    localStorage.setItem('_is_subscription_banner_closed', 1);
    setIsSubscriptionBannerClosed(true);
  };

  //const isSubscriptionBanner = true;
  const isSubscriptionBanner = props.type === 'withSubscriptionBanner' && !hasSubscription && hasCompletedOrder && !isSubscriptionBannerClosed;
/*
  console.log("data:", data);
  console.log("props.type:", props.type);
  console.log("hasSubscription:", hasSubscription);
  console.log("hasCompletedOrder:", hasCompletedOrder);
  console.log("isSubscriptionBannerClosed:", isSubscriptionBannerClosed);
  console.log("isBasicPremiumSubscription:", isBasicPremiumSubscription);

  console.log("isSubscriptionBanner:", isSubscriptionBanner);
*/
  return (
    <header className={classnames("header-main", {"header-with-banner": isSubscriptionBanner})}>
      {isSubscriptionBanner &&
        <SubscriptionBanner
          onClose={onClickCloseSubscriptionBanner}
          hasTrial = {hasTrial}
          isPremiumAddonsLabel = {isPremiumAddonsLabel}
        />
      }
      <div className="wrapper header-main-wrapper clearfix">
        <MainMenu />
        <div className="img-wrap">
          <Icon className="svg-icon" iconName="logo-icon" />
          <Link className="logo-link" to="/"><Icon className="svg-icon" iconName="nerdify-logo" /></Link>
        </div>
        {/*<Link className={classnames('hire-hdr-btn')} to="/neworder">Hire a Nerd</Link>*/} {/*, {disabled: this.state.isOpen}*/}
        <Link className={classnames('ref-link')} to="/ref-cabinet">
          <Icon className="svg-icon" iconName="people-icon" />
          <span className="ref-link-msg">Get $20</span>
        </Link>
      </div>
    </header>
  )
};
export default HeaderMain;
