import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Icon from './../SvgIcons'
import MainMenu from './../MainMenu'

class HeaderOrderStep extends Component {
  render() {
    const { id, jobType } = this.props.order.data;
    const link1 = !id ? `/neworder` : `/order/${id}/jobtype`;
    const link2 = !id ? `/neworder/${jobType}` : `/order/${id}`;
    const link3 = !id ? `/neworder/${jobType}/details` : `/order/${id}/details`;

    const hasPremiumAddonsSubscriptionLabel = this.props?.user?.data?.exp_data?.subscription_nerdy === 'cfp4221;addons_subscription';

    const isSubscriptionPaymentFailed = ((this.props?.user?.data?.tags.includes("subscription_inactive") && !this.props?.user?.data?.tags.includes("subscription_refunded") && !this.props?.user?.data?.tags.includes("subscription_canceled")) || this.props?.user?.data?.tags.includes("subscription_pastdue"));
    const totalOrderSteps = hasPremiumAddonsSubscriptionLabel && isSubscriptionPaymentFailed ? 3 : 4;
    //console.log("isSubscriptionPaymentFailed: ",isSubscriptionPaymentFailed);

    return (
      <Fragment>
        <header className='header-main order-step'>
          <div className="wrapper header-main-wrapper clearfix">
            {this.props.current === 1 &&
              <MainMenu />
            }

            {this.props.current === 2 &&
              <div>
                <Link className="step-back-link" to={link1}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
                <div className="desktop-menu">
                  <MainMenu />
                </div>
              </div>
            }

            {this.props.current === 3 &&
              <div>
                <Link className="step-back-link" to={link2}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
                <div className="desktop-menu">
                  <MainMenu />
                </div>
              </div>
            }

            {this.props.current === 4 &&
              <div>
                <Link className="step-back-link" to={link3}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
                <div className="desktop-menu">
                  <MainMenu />
                </div>
              </div>
            }

            <div className="img-wrap">
              <Icon className="svg-icon" iconName="logo-icon" />
              <Link className="logo-link" to="/"><Icon className="svg-icon" iconName="nerdify-logo" /></Link>
            </div>
            <div className="step-info">Step {this.props.current} of {totalOrderSteps}</div>
          </div>
        </header>

        <div className="order-step-block">
          <div>
            {this.props.current === 2 &&
              <Link className="step-back-link" to={link1}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
            }
            {this.props.current === 3 &&
              <Link className="step-back-link" to={link2}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
            }
            {this.props.current === 4 &&
              <Link className="step-back-link" to={link3}><Icon className="svg-icon" iconName="back-arrow2" /><span>Back</span></Link>
            }
          </div>
          <div className="step-info">Step {this.props.current} of {totalOrderSteps}</div>
        </div>

      </Fragment>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    order: state.order
  }),
  {}
)(HeaderOrderStep)
