import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { userApplyToken } from './../../reducers/user';
import { fetchOrder, fetchOrders } from '../../reducers/orders';

class PaymentRedirect extends Component {
  componentDidMount() {
    this.ApplyToken();
    document.querySelector('body').classList.add('clear');
  }

  ApplyToken() {
    const { history } = this.props;
    let subscription_id = '';
    if (this.props.subscriptionId) {
      subscription_id = `subscription_id=${this.props.subscriptionId}&`
    }
    if (this.props.shortKey && this.props.orderId) {
      //orderKey
      this.props
        .userApplyToken({
          factor: 'key',
          value: this.props.shortKey,
        })
        .then((isFetchUser) => {
          if (isFetchUser) {
            //console.log(`https://testcompany.paymentsgateway.co/p/i${this.props.shortKey}?success_url=${encodeURIComponent(`${window.location.origin}/order/thank-you`)}&cancel_url=${encodeURIComponent(`${window.location.origin}/orders`)}`);
            this.props
              .fetchOrder()
              .then((resp) => {
                const {
                  system_client_status,
                  payment_link,
                  _id,
                } = this.props.order.data;
                const isRefunded =
                  system_client_status.toLowerCase().indexOf('refund') + 1;
                if (isRefunded) {
                  // if refunded = redirect /orders
                  console.log('Refunded order');
                  history.push('/');
                } else {
/*
                  console.log(`${payment_link}?${subscription_id}success_url=${encodeURIComponent(
                    `${window.location.origin}/order/${_id}/thank-you`
                  )}&cancel_url=${encodeURIComponent(
                    `${window.location.origin}/orders`
                  )}`);
*/
                  window.location = `${payment_link}?${subscription_id}success_url=${encodeURIComponent(
                    `${window.location.origin}/order/${_id}/thank-you`
                  )}&cancel_url=${encodeURIComponent(
                    `${window.location.origin}/orders`
                  )}`;

                }

                return resp;
              })
              .catch((error) => error);
          }

          return isFetchUser;
        })
        .catch((error) => error);
    } else {
      console.log('Error payment url');
      history.push('/');
    }
  }
  render() {
    return null;
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    code: ownProps.match.params.code,
    shortKey: ownProps.match.params.shortKey,
    orderKey: ownProps.match.params.orderKey,
    orderId: ownProps.match.params.orderId,
    subscriptionId: ownProps.match.params.subscriptionId,
    order: state.order,

    next: queryString.parse(ownProps.location.search).next,
    ownProps,
  }),
  (dispatch, ownProps) => ({
    userApplyToken: (payload) => dispatch(userApplyToken(payload)),
    fetchOrders: () => dispatch(fetchOrders()),
    fetchOrder: () => dispatch(fetchOrder(ownProps.match.params.orderId)),
  })
)(PaymentRedirect);
