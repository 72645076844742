import React, {useEffect, useState} from 'react';
import OrderList from '../containers/OrderList';

import { Route, Redirect } from 'react-router-dom';
import { TipsSuccess, Tips } from '../containers/Tips';
import SubscriptionPopup from '../containers/SubscriptionPopup';
import { setCid } from '../lrtracker';

import Rating from '../containers/Rating';
import RatingNegative from '../containers/RatingNegative';
import { useA2hs } from '../components/Pwa';

//import { useCfpRoutes, useCfpLastUnratedOrder } from '@verdaccio/cfp';
import { useCfpRoutes, useCfpLastUnratedOrder } from '../hooks';
import {useSelector} from "react-redux";

const OrdersRedirect = () => {
  const {data, ...rest} = useSelector(state => state.user);
  const isSubscriptionPopupShown = data?.userdef?.isSubscriptionPopupShown;
  //const isSubscriptionsActive = data?.subscriptions_active?.length;
  const hasSubscription = data?.tags?.includes("subscription_inactive") || data?.tags?.includes("subscription_active");
  //const isBasicPremiumSubscription = data?.exp_data?.subscription === 'cfp3448;basic_premium_subscription';
  const isBasicPremiumSubscription = true;

  const hasCompletedOrder = data?.has_completed_order;
  const isLoading = rest?.loading === undefined || rest?.loading;

  const [isRatingPopupShown, setIsRatingPopupShown] = useState(
    localStorage.getItem('_is_rating_popup_shown')
  );

  const {
    isHaveLastUnratedOrder,
  } = useCfpLastUnratedOrder();

  //console.log("user data:",data);

  useEffect(() => {
    const userId = data?.id;
    setCid(userId);

    if (isHaveLastUnratedOrder) {
      localStorage.setItem('_is_rating_popup_shown', 1);
      setIsRatingPopupShown(true);
    }
  });

  return isLoading ? null : isSubscriptionPopupShown ? null : !hasCompletedOrder ? null : hasSubscription ? null : !isBasicPremiumSubscription ? null : isRatingPopupShown ? null : <Redirect to={'/orders/subscriptions'} />
};

const Orders = (props) => {
  const cfpRoutes = useCfpRoutes();
  const a2hs = useA2hs();

  return (
    <>
      <OrderList {...props} />

      <cfpRoutes.tips.route path={cfpRoutes.tips.path} component={Tips} />

      <cfpRoutes.tipsSuccess.route
        path={cfpRoutes.tipsSuccess.path}
        component={TipsSuccess}
      />

      <cfpRoutes.rating.route path={cfpRoutes.rating.path} component={Rating} />

      <cfpRoutes.ratingNegative.route
        path={cfpRoutes.ratingNegative.path}
        component={RatingNegative}
      />

      <Route
        path={'/orders/subscriptions'}
        component={SubscriptionPopup}
      />

      <OrdersRedirect />

      <a2hs.component />
    </>
  );
};

export default Orders;
