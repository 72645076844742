import { useEffect } from 'react';

import getTracker, { METRICS } from '@verdaccio/lrtrack-js';

const tracker = getTracker({
  trackerId: process.env.REACT_APP_LR_TRACK_ID,
  baseUrl: process.env.REACT_APP_LR_TRACK_URL,
  isDebug: process.env.NODE_ENV === 'development',
});

const useTrackValues = tracker.metrics.getUseTrackValues({ useEffect });
const trackClicks = tracker.metrics.trackClicks;
const trackValues = tracker.metrics.trackValues;
const trackLocation = tracker.metrics.trackLocation;
const trackGoogleClientId = tracker.metrics.trackGoogleClientId;

const storageGetItem = (key, req) =>
  tracker.utils.storageGetItem(tracker.utils.getStorageKey(key), req);

const storageSetItem = (key, payload, res) => {
  tracker.utils.storageSetItem(tracker.utils.getStorageKey(key), payload, res);
};

const setCid = cid => tracker.config.setCid(cid);

export {
  tracker as default,
  useTrackValues,
  trackClicks,
  trackValues,
  storageGetItem,
  storageSetItem,
  setCid,
  trackLocation,
  trackGoogleClientId,
  METRICS,
};
