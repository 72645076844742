import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { userApplyToken } from './../../reducers/user';
import { fetchOrders } from '../../reducers/orders';

class Logging extends Component {
  componentDidMount() {
    this.ApplyToken();
  }

  componentDidUpdate(prevProps) {
    this.ApplyToken();
    if (!prevProps.user.isAuthenticated && this.props.user.isAuthenticated) {
      const next = this.props.next || '/orders';
      this.props.history.replace(next);
    }
  }

  ApplyToken() {
    if (this.props.user.factor && this.props.code) {
      this.props.userApplyToken({
        factor: this.props.user.factor,
        value: this.props.user.factorValue,
        code: this.props.code
      });
    }
    if (this.props.shortKey) {
      this.props
        .userApplyToken({
          factor: 'key',
          value: this.props.shortKey
        })
        .then(isFetchUser => {
          if (isFetchUser) {
            //this.props.fetchOrders();
          }
        });
    }
  }

  render() {
    if (this.props.user.isAuthenticated) {
      return null;
    }
    return (
      <div className="login-page">
        <div className="wrapper with-fixed-header">
          <div className="frame-no-border">
            <h2 className="title">
              Please bear with us.
              <br /> We are logging you in :)
            </h2>
            <div className="loader ball-pulse">
              <div />
              <div />
              <div />
            </div>
            <div className="login-contact">
              Have never used Nerdify or have
              <br /> trouble signing in?{' '}
              <Link className="login_link" to="/contact-us">Contact us</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    code: ownProps.match.params.code,
    shortKey: ownProps.match.params.shortKey,
    next: queryString.parse(ownProps.location.search).next,
    ownProps
  }),
  (dispatch, ownProps) => ({
    userApplyToken: payload => dispatch(userApplyToken(payload)),
    fetchOrders: () => dispatch(fetchOrders())
  })
)(Logging);
