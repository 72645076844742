import React, { Component } from 'react'
import classnames from "classnames";
import {convertMinutesTo_hm, convert_hmToMinutes} from './../../utils/MeasureConvertor';
import "./select-time.css";


export default class SelectTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: convertMinutesTo_hm(this.props.minutes).hours,
      minutes: convertMinutesTo_hm(this.props.minutes).minutes
    }
  }

  render() {
    const hours = convertMinutesTo_hm(this.props.minutes).hours;
    const minutes = convertMinutesTo_hm(this.props.minutes).minutes;
    const curValue = {hours, minutes};

    const onChangeHours = hours => {
      const newSize = convert_hmToMinutes(hours, curValue.minutes);
      if (newSize) {
        this.props.changeSize(newSize)
      }
    };
    const onChangeMinutes = minutes => {
      const newSize = convert_hmToMinutes(curValue.hours, minutes);
      if (newSize) {
        this.props.changeSize(newSize)
      }
    };

    const helpTimeTextMap = {
      quiz: (
        <h2 className="h2">Complete my quiz in</h2>
      ),
      problems: (
        <h2 className="h2">Work on my task for</h2>
      ),
      task: (
        <h2 className="h2">Work on my task for</h2>
      )
    };

    const helpTimeText = helpTimeTextMap[this.props.jobType] || null;

    return (
      <div className="select-time">
        {helpTimeText}
        <div className="clearfix">
          <div className="select-block time-item left">
            <div className={classnames("select-substrate", { error: this.props.errorField })}>{hours} {hours === 1 ? 'hour': 'hours'}</div>
            <select className="select" value={hours} onChange={e => {
              onChangeHours(parseInt(e.target.value, 10));
            }}>
              {this.props.hoursRange.map(value => <option key={value} value={value}>{value} {value === 1 ? 'hour': 'hours'}</option>)}
            </select>
          </div>
          <div className="select-block time-item right">
            <div className="select-substrate">{minutes ? minutes: '00'} mins</div>
            <select className="select" value={minutes} onChange={e => onChangeMinutes(parseInt(e.target.value, 10))}>
              {this.props.minutesRange.map(value => <option key={value} value={value}>{value ? value: '00'} mins</option>)}
            </select>
          </div>
        </div>
      </div>
    )
  }
}
