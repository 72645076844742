import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { get } from 'lodash';

import { fetchOrder } from '../../reducers/orders';
import { userFetchIfNeeded } from '../../reducers/user';

import Modal from '../../components/Modal';
import classnames from 'classnames';
import Icon from '../../components/SvgIcons';
import PgtwExternalForm from '../../components/PgtwExternalForm';

import smileImg from './img/smile-nerd.svg';

import './index.css';

const tipsItems = [5, 10, 15];

export const TipsSuccess = (props) => {
  const history = useHistory();
  const { amount } = useParams();
  const modalRef = useRef();

  const onClose = () => {
    modalRef.current.onClose();
    history.push(`/orders`);
  };

  return (
    <Modal onClose={onClose} ref={modalRef}>
      <div className="tips__thank-you">
        <img className="tips__thank-you-img" src={smileImg} alt="thank-you" />

        <div className="tips__thank-you-title">Thank you!</div>
        <div className="tips__thank-you-text-1">
          Your Nerd will be so happy, thanks!
          <br />
          We are looking forward to helping
          <br />
          you with new tasks.
        </div>

        <div className="tips__thank-you-text-2">
          Now you have +${amount} on your balance.
        </div>

        <button
          className="modal__button modal__button_narrow"
          type="button"
          onClick={onClose}
        >
          Got it
        </button>
      </div>
    </Modal>
  );
};

export const Tips = (props) => {
  const modalRef = useRef();
  const { orderId } = useParams();

  const location = useLocation();
  const isFromTipsBtn = _.get(location, 'state.isFromTipsBtn', false);

  const [tipPrice, setTipsPrice] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrder(orderId));
    dispatch(userFetchIfNeeded());
  }, [dispatch, orderId]);

  const orderState = useSelector((state) => state.order);
  const userState = useSelector((state) => state.user);

  const { price, number, isTipsPaid, id } = orderState.data;

  const isFetching =
    userState.isFetching || orderState.isFetching || id !== orderId;

  const userId = get(userState.data, 'id', '');
  const paymentAccountStripe = get(
    userState.data,
    'payment_account_stripe',
    ''
  );

  const [activeTipIndex, setActiveTipIndex] = useState(0);
  const [isTipCanceled, setCanceled] = useState(false);

  const setActiveTip = useCallback(
    (index, key) => {
      setActiveTipIndex((prevIndex) => {
        if (prevIndex === index) {
          return 0;
        }

        return index;
      });
      setTipsPrice(Math.ceil((price * tipsItems[key]) / 100));
    },
    [setActiveTipIndex, setTipsPrice, price]
  );

  const onClickTipCancel = () => {
    if (isFromTipsBtn) {
      onClose();
    } else {
      setCanceled(true);
    }
  };

  const history = useHistory();
  const onClose = () => {
    modalRef.current.onClose();
    history.push(`/orders`);
  };

  const tipPriceCent = tipPrice * 100;

  useEffect(() => {
    if (!isFetching) {
      return;
    }

    if (isTipsPaid) {
      setCanceled(true);
    }
  }, [isFetching, isTipsPaid]);

  return (
    <Modal onClose={onClose} ref={modalRef}>
      <CSSTransition
        in={isTipCanceled}
        unmountOnExit
        exit={false}
        timeout={250}
        classNames="tips__anim"
      >
        <div className="tips__thank-you">
          <Icon className="tips__thank-you-img" iconName="smile-happy" />
          <div className="tips__thank-you-title">
            Thank you for sharing
            <br />
            your feedback
          </div>
          <div className="tips__thank-you-text-3">
            Please text us or contact us via Facebook messenger in case you need
            any help!
          </div>
          <button
            className="modal__button modal__button_narrow"
            type="button"
            onClick={onClose}
          >
            Got it
          </button>
        </div>
      </CSSTransition>

      <CSSTransition
        in={!isTipCanceled}
        unmountOnExit
        exit={false}
        appear
        timeout={250}
        classNames="tips__anim"
      >
        <div className="tips">
          {!isFromTipsBtn && (
            <Fragment>
              <div className="tips__subtitle">Thank you for your feedback!</div>
              <div className="tips__divider" />
            </Fragment>
          )}
          {/*
          <div
            className={classnames('tips__img', {
              tips__img_last: activeTipIndex === 3
            })}
          >
            <CSSTransition in={activeTipIndex === 0} {...tipsImgAnimProps}>
              <img src={tipsImages[activeTipIndex]} />
            </CSSTransition>
            <CSSTransition in={activeTipIndex === 1} {...tipsImgAnimProps}>
              <img src={tipsImages[activeTipIndex]} />
            </CSSTransition>
            <CSSTransition in={activeTipIndex === 2} {...tipsImgAnimProps}>
              <img src={tipsImages[activeTipIndex]} />
            </CSSTransition>
            <CSSTransition in={activeTipIndex === 3} {...tipsImgAnimProps}>
              <img src={tipsImages[activeTipIndex]} />
            </CSSTransition>
          </div>
*/}
          <div className="tips__title">
            Tip your Nerd and{' '}
            <span className="tips__title_strong">get same amount back</span> to
            your Nerdify balance!{' '}
          </div>
          <div className="tips__items">
            {tipsItems.map((item, key) => {
              const index = key + 1;
              const isActiveIndex = activeTipIndex === index;
              const itemTipPrice = Math.ceil((price * tipsItems[key]) / 100);

              return (
                <div
                  key={`tips__item-${key}`}
                  className={classnames('tips__item', {
                    tips__item_active: isActiveIndex,
                  })}
                  onClick={() => setActiveTip(index, key)}
                >
                  <div>${itemTipPrice}</div>
                </div>
              );
            })}
          </div>

          {!activeTipIndex ? (
            <div className="tip__balance_info">
              We will fully compensate you the tip amount
            </div>
          ) : (
            <div className="tip__balance_info">
              + ${tipPrice} to your balance
            </div>
          )}

          <PgtwExternalForm
            details={`Tips for order ${number} (tips_${orderId}_${tipPriceCent})`}
            userId={userId}
            orderId={orderId}
            amount={tipPrice}
            disabled={activeTipIndex === 0}
            paymentAccountStripe={paymentAccountStripe}
            buttonText="Tip my Nerd"
            className="modal__button"
          />

          <button
            className="tips__close"
            type="button"
            onClick={onClickTipCancel}
          >
            Maybe next time
          </button>
        </div>
      </CSSTransition>
    </Modal>
  );
};
